// @flow

import React from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from '../../styles/color'
import type { Node } from 'react'

const ApplicationContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    padding: 0px 20px 0px 20px;
    @media (max-width: 412px) {
      padding: 0px;
    }
`
/* istanbul ignore next */
const Container: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: auto;
    padding: 20px;
    background: ${color.white};
    box-shadow: 1px 2px 4px -1px rgba(0,0,0,0.50);
    @media (max-width: 412px) {
      padding: 20px 0px 30px 0px
    }
`

// add for testing purpose
ApplicationContainer.displayName = 'ApplicationContainer'
Container.displayName = 'Container'

type BoxContainerProps = {
  children: Node
}
export const BoxContainer = (props: BoxContainerProps) => {
  return (
    <ApplicationContainer>
      <Container>
        {props.children}
      </Container>
    </ApplicationContainer>
  )
}

export default BoxContainer
