// @flow

import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import { split } from 'lodash'
import { messages } from '../../i18n/messages'
import type { RouterHistory, Match } from 'react-router-dom'
import TopNavigation from './TopNavigation'
import type { ApolloClient } from '@apollo/client'

type TopNavigationAndHeaderProps = {
  history: RouterHistory,
  name: string,
  match: Match,
  client: ApolloClient<any>
} & InjectIntlProvidedProps

const desktopNavigation = {
  application: [
    { id: '1', label: messages.applicationSettings, route: '/all-applications' }
  ],
  accountApplication: (accountId: string) => [
    { id: '1', label: messages.applicationSettings, route: `/all-accounts/view/${accountId}/all-applications` }
  ],
  accountAppIntegrations: (accountId: string) => [
    { id: '1', label: messages.masterAppIntegrations, route: `/all-accounts/view/${accountId}/app-integrations` }
  ],
  home: [
    { id: '1', label: messages.allAccounts, route: '/home' }
  ],
  accounts: [
    { id: '1', label: messages.allAccounts, route: '/all-accounts' }
  ],
  smsSettings: (accountId: string) => [
    { id: '1', label: messages.smsSettings, route: `/all-accounts/view/${accountId}/sms-settings` }
  ],
  masterAppIntegrations: [
    { id: '1', label: messages.allhrisIntegrations, route: '/app-integrations' }
  ],
  createAccount: [
    { id: '1', label: messages.configureAccount, route: '/all-accounts/create' }
  ],
  reportsApplication: (accountId: string) => [
    { id: '1', label: messages.allReports, route: `/all-accounts/view/${accountId}/all-reports` }
  ]
}
const mobileNavigation = {
  application: (applicationId: string) => [
    { id: '1', label: messages.mobileSettings, route: `/all-applications/application/${applicationId}/mobile/security` },
    { id: '2', label: messages.customization, route: `/all-applications/application/${applicationId}/customization/login` },
    { id: '3', label: messages.hrisIntegrations, route: `/all-applications/application/${applicationId}/app-integrations/dashboard` }
  ],
  account: [
    { id: '2', label: messages.UltiPro, route: '/account-settings/view/:accountId/sms-settings' },
    { id: '3', label: messages.registrationOptions, route: '/account-settings/account/test2' },
    { id: '4', label: messages.UltiPro, route: '/account-settings/account/test3' }
  ],
  accounts: [
    { id: 1, label: messages.allAccounts, route: '/all-accounts' }
  ],
  masterAppIntegrations: [
    { id: '1', label: messages.masterAppIntegrations, route: '/app-integrations' }
  ],
  createAccount: [
    { id: 1, label: messages.configureAccount, route: '/all-accounts/create' }
  ],
  smsSettings: (accountId: string) => [
    { id: '1', label: messages.smsSettings, route: '/all-accounts' }
  ]
}

const NavAndHeader = (props: TopNavigationAndHeaderProps) => {
  const location = useLocation()
  const { name } = props
  const currentPathname = location.pathname

  const pathnameSplit = split(currentPathname, '/')
  const lastPathname = pathnameSplit[pathnameSplit.length - 2]
  const basePath = pathnameSplit[1]

  return (
      <Routes>
        <Route path='/all-applications/application/:id/*' element={<TopNavigation desktopNavigation={desktopNavigation.application} mobileNavigation={mobileNavigation.application(props.match.params.id || '')} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route exact path='/all-applications' element={<TopNavigation desktopNavigation={desktopNavigation.application} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route exact path='/home' element={<TopNavigation isHighLighted desktopNavigation={desktopNavigation.accounts} mobileNavigation={mobileNavigation.accounts} customerCare name={name} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route exact path='/all-accounts' element={<TopNavigation desktopNavigation={desktopNavigation.accounts} mobileNavigation={mobileNavigation.accounts} customerCare name={name} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route exact path='/all-accounts/create' element={<TopNavigation desktopNavigation={desktopNavigation.createAccount} mobileNavigation={mobileNavigation.createAccount} customerCare name={name} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route exact path='/all-accounts/view/:accountId/edit' element={<TopNavigation desktopNavigation={desktopNavigation.createAccount} mobileNavigation={mobileNavigation.createAccount} customerCare name={name} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route exact path='/all-accounts/view/:accountId/sms-settings' element={<TopNavigation isHighLighted desktopNavigation={desktopNavigation.smsSettings(props.match.params.accountId || '')} mobileNavigation={mobileNavigation.smsSettings(props.match.params.accountId || '')} firstPathname={basePath} lastPathname={lastPathname} customTitle='SMS' />} />
        <Route exact path='/all-accounts/view/:accountId/all-applications' element={<TopNavigation desktopNavigation={desktopNavigation.accountApplication(props.match.params.accountId || '')} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route exact path='/all-accounts/view/:accountId/all-reports' element={<TopNavigation desktopNavigation={desktopNavigation.reportsApplication(props.match.params.accountId || '')} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route path='/all-accounts/view/:accountId/account-settings/account/*' element={<TopNavigation desktopNavigation={desktopNavigation.accountApplication(props.match.params.accountId || '')} mobileNavigation={mobileNavigation.account} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route path='/account-settings/account/*' element={<TopNavigation desktopNavigation={desktopNavigation.application} mobileNavigation={mobileNavigation.account} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route path='/all-accounts/view/:accountId/all-applications/application/:id' element={<TopNavigation desktopNavigation={desktopNavigation.accountApplication(props.match.params.accountId || '')} mobileNavigation={mobileNavigation.application(props.match.params.id || '')} firstPathname={basePath} lastPathname={lastPathname} />} />
        <Route exact path='/app-integrations' element={<TopNavigation desktopNavigation={desktopNavigation.masterAppIntegrations} mobileNavigation={mobileNavigation.masterAppIntegrations} customerCare name={name} firstPathname={basePath} lastPathname={lastPathname} />} />
      </Routes>
  )
}
export default withRouter(injectIntl(NavAndHeader))
export { NavAndHeader as unConnectedNavAndHeader }
