// @flow
/* eslint-disable quote-props */
/* eslint-disable quotes */
/* eslint-disable object-shorthand */

import axios from 'axios'
import { isNil, join, trim, replace } from 'lodash'

const safe = (string: string) => {
  if (isNil(string)) return '_none_'
  if (string === 'TRUE' || string === 'FALSE' || string === 'true' || string === 'false' || string === true || string === false) return string
  return '_' + replace(string, / /g, '_') + '_'
}

export const sendToBitrise = async (platform: string, branchName: string, versionNumber: string, buildParams: Object): Promise<Request> => {
  const isAndroid = platform === 'ANDROID'
  // Bitrise
  const bitriseAppId = isAndroid ? '116c9dc87ca5c4d5' : 'c350f2b03e63fa78'
  const bitriseApiKey = isAndroid ? 'lcuwR92GBy3BNNyKJLD-Zw' : 'pJnbq4VanSX6Qj3HbBBzRQ'
  const url = 'https://app.bitrise.io/app/' + bitriseAppId + '/build/start.json'
  // const request = new Request(url, null, false, {})

  // platform specific
  const identity = isAndroid ? '' : trim(buildParams.identity)
  const releaseProfile = isAndroid ? '' : buildParams.provision
  const onesignal = isAndroid ? '' : buildParams.onesignal
  const itunesUser = isAndroid ? '' : buildParams.appleId
  const devTeam = isAndroid ? '' : buildParams.devTeam
  const devTeamName = isAndroid ? '' : trim(identity.substring(identity.indexOf(':') + 1))
  const bundleId = isAndroid ? buildParams.androidBundleId : buildParams.iosBundleId

  // build specific
  const accountId = buildParams.accountId
  const accountColor = buildParams.accountColor
  const applicationId = buildParams.applicationId
  const appName = buildParams.appName
  const appNameURL = replace(appName, /[ !@#$%^&*]/g, '_')
  const environment = buildParams.environment
  const clientSecret = buildParams.clientSecret
  const clientId = buildParams.clientId
  const grantCode = buildParams.grantCode
  const shortCode = buildParams.accountShortCode
  const theme = buildParams.theme
  const supportEmailAddress = buildParams.supportEmailAddress
  const subDomain = buildParams.subDomain

  const configArgs = join(['--environment', environment, '--platform', platform,
    '--account', accountId, '--shortCode', safe(shortCode), '--appName', safe(appName),
    '--uriScheme', subDomain, '--subDomain', subDomain, '--appVersion', versionNumber, '--theme', theme,
    '--applicationId', applicationId, '--clientId', clientId,
    '--clientSecret', clientSecret, '--grantCode', grantCode, '--bundleId', bundleId,
    '--accountColor', safe(accountColor), '--customerCareEmail', supportEmailAddress,
    '--crashReporterEnabled', 'true', '--blockJailBroken', 'true'], ' ')

  const payload = {
    "hook_info": {
      "type": "bitrise",
      "api_token": bitriseApiKey
    },
    "build_params": {
      "branch": branchName,
      "workflow_id": "primary",
      "environments": [
        {
          "mapped_to": "DEV_TEAM",
          "value": devTeam,
          "is_expand": true
        },
        {
          "mapped_to": "APP_NAME_URL",
          "value": appNameURL,
          "is_expand": true
        },
        {
          "mapped_to": "APP_NAME",
          "value": appName,
          "is_expand": true
        },
        {
          "mapped_to": "BUNDLE_ID",
          "value": bundleId,
          "is_expand": true
        },
        {
          "mapped_to": "IDENTITY",
          "value": identity,
          "is_expand": true
        },
        {
          "mapped_to": "RELEASE_PROFILE",
          "value": releaseProfile,
          "is_expand": true
        },
        {
          "mapped_to": "ONESIGNAL",
          "value": onesignal,
          "is_expand": true
        },
        {
          "mapped_to": "APP_VERSION",
          "value": versionNumber,
          "is_expand": true
        },
        {
          "mapped_to": "CONFIG_ARGS",
          "value": configArgs,
          "is_expand": true
        },
        {
          "mapped_to": "ITUNES_USER",
          "value": itunesUser,
          "is_expand": true
        },
        {
          "mapped_to": "DEV_TEAM_NAME",
          "value": devTeamName,
          "is_expand": true
        }
      ]
    }
  }
  console.log('PAYLOAD:', payload)
  try {
    const result = await axios({
      method: 'post',
      url: url,
      data: { payload }
    })
    console.log(result)
    return result.data.status
  } catch (error) {
    return error
  }
}
