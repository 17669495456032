// @flow
// noinspection ES6ShorthandObjectProperty
/* eslint-disable object-shorthand */

import React, { Component } from 'react'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'

export const GET_MASTER_APP_INTEGRATIONS_DATA = gql`
query getMasterAppIntegrationsData($limit: Int!, $offset: Int!, $sortField: String!, $sortDirection: Int!, $search: String!) {
  getMasterAppIntegrations(limit: $limit, offset: $offset, sortField: $sortField, sortDirection: $sortDirection, search: $search) {
        data {
          _id
          appName
          iosUriScheme
          appleAppStoreLink
          androidUriScheme
          googlePlayStoreLink
          webUrl
          logoUrl
          hasEnabledAppIntegrations
        }
        count
    }
}
`
type GetMasterAppIntegrationsProps = {
    children: Function,
    limit: number,
    offset: number,
    sortField: string,
    sortDirection: number,
    search: string
  }

export class GetMasterAppIntegrationsData extends Component<GetMasterAppIntegrationsProps, {}> {
  render () {
    const { limit, offset, search, sortField, sortDirection } = this.props
    return (
      <Query
        query={GET_MASTER_APP_INTEGRATIONS_DATA}
        variables={{ limit: limit, offset: offset, search: search, sortField: sortField, sortDirection: sortDirection }}
        notifyOnNetworkStatusChange
        displayName='IntegrationsData'
        fetchPolicy='network-only'
      >
        {({ loading, error, data, refetch, networkStatus, client }) => {
          if (!(data)) return null
          return this.props.children(data.getMasterAppIntegrations, loading, client)
        }}
      </Query>
    )
  }
}
