// @flow

import React from 'react'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import styled from 'styled-components'
import Icon from 'michelangelo/dist/Components/Icon'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'

type LinkProps = {
  onClick: Function,
  link: string
}

const Container: ReactComponentStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  cursor: pointer;
`
Container.displayName = 'Container'
const IconContainer: ReactComponentStyled = styled.div`
  margin-right: 15px;
`
function Link ({ onClick, link }: LinkProps) {
  return (
    <Container onClick={onClick}>
      <IconContainer>
        <Icon iconName='link' size='x' color={color.info} />
      </IconContainer>
      <Text text={link} ellipsis fontColor={color.info} textSize='p' />
    </Container>
  )
}

export default Link
