// @flow
import React, { Component } from 'react'
import { reportNavLinkArray } from '../Components/SideBars/NavigationLinks'
import Menu from '../Components/SideBars/Menu'
import type { Match } from 'react-router-dom'
import withRouter from '../utils/withRouter'

type HomeSideBarProps = {
  isToggleSideBarOpen: boolean,
  match: Match
}

class ReportSideBar extends Component<HomeSideBarProps, {}> {
  render () {
    const { isToggleSideBarOpen } = this.props
    const accountId = this.props.match.params.accountId || ''
    const navLinks = reportNavLinkArray(accountId)
    return <Menu navLinks={navLinks} isopen={isToggleSideBarOpen} />
  }
}
export { ReportSideBar as UnwrappedReportSideBar }
export default withRouter(ReportSideBar)
