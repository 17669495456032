// @flow

import React from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from '../styles/color'

// Define what props.theme will look like
const kind = {
  default: color.default,
  info: color.info,
  warning: color.warning,
  danger: color.danger,
  success: color.success,
  disabled: color.disabled,
  logout: color.logout,
  white: color.white
}

type ButtonProps = {
  children?: string,
  onClick?: Function,
  kind: 'default' | 'info' | 'warning' | 'danger' | 'success' | 'disabled' | 'logout' | 'white',
  disabled: boolean
}

/* istanbul ignore next */
const DefaultButton: ReactComponentStyled<{}> = styled.button`
    border: 2px solid ${(props: ButtonProps) => kind[props.kind]};
    color: ${(props: ButtonProps) => kind[props.kind]};
    background-color: transparent;
    width: auto;
    min-width: 138px;
    word-wrap: break-word;
    font-family: Raleway;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: ${(props: ButtonProps) => props.disabled ? 'not-allowed' : 'pointer'};
    &:focus {
      outline: none;
    }
`
/* istanbul ignore next */
// $FlowFixMe
const LogoutButton = styled(DefaultButton)`
    border: 0;
    background: ${color.logout};
    color: ${color.white};
    border-radius: 32px;
`
// For testing purpose added this
DefaultButton.displayName = 'DefaultButton'
LogoutButton.displayName = 'LogoutButton'

const Button = (props: ButtonProps) => {
  const { kind, children, disabled, onClick } = props
  if (kind === 'logout') {
    return (
        <LogoutButton
            onClick={onClick}
            kind={kind}
            disabled={disabled}
        >
          {children}
        </LogoutButton>
    )
  } else {
    return (
        <DefaultButton
            onClick={onClick}
            kind={kind}
            disabled={disabled}
        >
          {children}
        </DefaultButton>
    )
  }
}
export default Button
