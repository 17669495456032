// @flow

import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import MenuItem from './MenuItem'

type LabelType = {
  id: string,
  defaultMessage: string
}
type SubLinkType = {
  label: LabelType,
  to: string,
  icon: string
}
type NavLinktype = {
  label: LabelType,
  to: string,
  icon: string,
  subLinks: Array<SubLinkType>
}
type MenuProps = {
  navLinks: Array<NavLinktype>,
  isopen: boolean
}
type ToggledMenu = {
  id: string,
  isSubMenuOpened: boolean
}
const MENU_TOGGLED_STATE: string = 'MENU_TOGGLED_STATE'

const Menu = (props: MenuProps) => {
  const toggledMenusString = window.localStorage.getItem(MENU_TOGGLED_STATE)
  const { isopen, navLinks } = props
  const [toggledMenus, setToggledMenus] = useState<Array<ToggledMenu>>(toggledMenusString ? JSON.parse(toggledMenusString) : [])

  const isSubMenuOpenedFunc = (id: string) => {
    const menu = toggledMenus.find(menu => menu.id === id)
    return menu ? menu.isSubMenuOpened : true
  }

  const getNavigationLinks = () => {
    const formatedNavigation = []
    for (let i = 0; i < navLinks.length; i++) {
      const isParent = !isEmpty(navLinks[i].subLinks)
      const isSubMenuOpened = isParent && isSubMenuOpenedFunc(navLinks[i].label.id)
      formatedNavigation.push({ to: navLinks[i].to, icon: navLinks[i].icon, label: navLinks[i].label, isParent, isSubLink: false, isSubMenuOpened })
      if (isSubMenuOpened) {
        const subLinks = navLinks[i].subLinks
        for (let j = 0; j < subLinks.length; j++) {
          formatedNavigation.push({ to: subLinks[j].to, icon: subLinks[j].icon, label: subLinks[j].label, isParent: false, isSubLink: true, isSubMenuOpened: false })
        }
      }
    }
    return formatedNavigation
  }

  const hasSubMenus = () => {
    return !isEmpty(navLinks.find(link => link.subLinks && link.subLinks.length > 0))
  }

  const toggle = (id: string, isSubMenuOpened: boolean) => {
    setToggledMenus((prevState) => {
      const toggledMenus = [...prevState]
      const newToggledMenus = []
      let found: boolean = false
      for (let i = 0; i < toggledMenus.length; i++) {
        newToggledMenus[i] = toggledMenus[i]
        if (newToggledMenus[i].id === id) {
          newToggledMenus[i].isSubMenuOpened = isSubMenuOpened
          found = true
        }
      }
      if (!found) {
        newToggledMenus.push({ id, isSubMenuOpened })
      }
      window.localStorage.setItem(MENU_TOGGLED_STATE, JSON.stringify(newToggledMenus))
      return newToggledMenus
    })
  }

  const navigation = getNavigationLinks()
  const menuContainsSubMenus = hasSubMenus()
  return navigation.map<any>((menu) => {
    return (
        <MenuItem
            key={menu.label.id}
            to={menu.to}
            id={menu.label.id}
            isopen={isopen}
            icon={menu.icon}
            isParent={menu.isParent}
            isSubLink={menu.isSubLink}
            isSubMenuOpened={menu.isSubMenuOpened}
            toggle={(id, isSubMenuOpened) => toggle(id, isSubMenuOpened)}
            parentCanBeActive={!menuContainsSubMenus}
        />
    )
  })
}
Menu.displayName = 'Menu'
export default Menu
