// @flow
/* eslint-disable object-shorthand */

import React from 'react'
import Column from 'michelangelo/dist/Components/Column'
import Row from 'michelangelo/dist/Components/Row'
import Notification from 'michelangelo/dist/Components/Notification'
import color from './styles/color'
import Box from './Components/ReportBox'
import styled from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { ReactComponentStyled } from 'styled-components'
import withRouter from './utils/withRouter'
import { injectIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import type { Match } from 'react-router-dom'
import { messages } from './i18n/messages'
import { REG_USER_REPORT, CFP_REG_USER_REPORT } from './queries/report'
import { useParams } from 'react-router-dom'

const ReportContainer: ReactComponentStyled<{}> = styled.div`
    width: 364px;
    height: 364px;
    @media(max-width: 375px) {
      width: 300px;
      height: 300px;
    }
    @media(max-width: 320px) {
      width: 270px;
      height: 270px;
    }
`
type AllReportsProps = {
  match: Match
} & InjectIntlProvidedProps

const AllReports = ({ intl: { formatMessage } }: AllReportsProps) => {
  const params = useParams()
  const accountId = params.accountId || ''
  const [generateUserReport, { data: regReport }] = useLazyQuery(REG_USER_REPORT,
    {
      variables: { accountId: accountId },
      skip: !accountId,
      fetchPolicy: 'network-only'
    }
  )
  const [generateCFPUserReport, { data: cfpRegReport }] = useLazyQuery(CFP_REG_USER_REPORT,
    {
      variables: { accountId: accountId },
      skip: !accountId,
      fetchPolicy: 'network-only'
    })
  const regUserReport = () => {
    let notification
    const title = formatMessage(messages.generateUserReport)
    if (regReport) {
      notification = <Notification show message={title} autoClose={3} type='success' />
    }
    return (
      <Column>
        <ReportContainer>
          <Box
            label='Click here to generate Registered User Report'
            cursor='pointer'
            color={color.white}
            onClick={() => generateUserReport()}
          />
        </ReportContainer>
        {notification}
      </Column>
    )
  }

  const cfpUserReport = () => {
    let notification
    const title = formatMessage(messages.generateCfpUserReport)
    if (cfpRegReport) {
      notification = <Notification show message={title} autoClose={3} type='success' />
    }
    return (
      <Column>
        <ReportContainer>
          <Box
            label='Click here to generate App Profile Registered User Report'
            cursor='pointer'
            color={color.white}
            onClick={() => generateCFPUserReport()}
          />
        </ReportContainer>
        {notification}
      </Column>
    )
  }

  return (
    <Row classNames={['is-multiline', 'is-marginless', 'is-mobile']}>
      {regUserReport()}
      {cfpUserReport()}
    </Row>
  )
}

export default injectIntl(withRouter(AllReports))
export { AllReports as UnwrappedAllReports }
