// @flow

import React, { useEffect } from 'react'
import { Tab, Tabs as TabLayout, TabList, TabPanel } from 'react-tabs'
import { useNavigate, useLocation } from 'react-router-dom'
import type { ReactComponentStyled } from 'styled-components'
import styled from 'styled-components'
import color from '../styles/color'
import Icon from 'michelangelo/dist/Components/Icon'
import { split } from 'lodash'

const TabItem: ReactComponentStyled<{}> = styled(Tab)`
  display: inline-block;
  position: relative;
  padding: 16px 28px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  margin-right: 16px;
  text-align: center;
   &* {
      margin-right: 1px;
   }
   :focus {
     background: ${color.white};
     outline: none;
   }
   border: none;
   background: ${(props) => props.selected ? `${color.white}` : `${color.tabDefault}`};
   color: ${(props) => props.selected ? `${color.info}` : `${color.lightTextDefault}`};
   @media (max-width: 600px) {
    padding: 16px 16px;
  }
  @media (max-width: 375px) {
    padding: 16px 8px;
  }
`
const TabBar: ReactComponentStyled<{}> = styled(TabList)`
  user-select: none;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
  min-width: 310px;
  white-space: nowrap;
  overflow: auto;
`
const TabParent: ReactComponentStyled<{}> = styled(TabLayout)`
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  min-width: 310px;
`
const TabContainer: ReactComponentStyled<{}> = styled(TabPanel)`
  display: ${(props) => props.selected ? 'block' : 'none'};
  background: ${color.white};
  padding: 8px;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
  background-color: ${color.white};
  @media (max-width: 600px) {
    padding: 2px;
  }
`
// ability to override icon color if different from font
const IconContainer: ReactComponentStyled<{}> = styled.span`
  padding-left: 10px;
  svg {
    color: ${(props) => props.color} !important;
  }
`
TabParent.displayName = 'TabParent'
type TabsProps = {
  tabsArray: Array,
  defaultIndex?: number
}

function RouterTabs ({ tabsArray, defaultIndex }: TabsProps) {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const firstIndex = defaultIndex || 0
    navigate(tabsArray[firstIndex].path)
    // eslint-disable-next-line
  }, [])

  const getContents = () => {
    const tabTitles = []
    const tabContents = []
    let currentIndex = 0
    let idx = 0

    tabsArray && tabsArray.forEach(({ label, component, path, icon, color }, index) => {
      idx = index
      const historyPath = split(location.pathname, '/')
      const componentPath = split(path, '/')
      currentIndex = historyPath.pop() === componentPath.pop() ? index : currentIndex

      let getIcon = null
      if (icon) {
        getIcon = <IconContainer color={color}><Icon iconName={icon} size='1x' color={color.color} /></IconContainer>
      }

      tabTitles.push(<TabItem selected={index === currentIndex ? 1 : 0} key={index}>{label}{getIcon}</TabItem>)
      tabContents.push(<TabContainer selected={index === currentIndex ? 1 : 0} key={index}>{component}</TabContainer>)
    })

    return { ...({ currentIndex }), idx, tabTitles, tabContents }
  }

  const onSelectChange = (tabIndex: number) => {
    navigate(tabsArray[tabIndex].path, { replace: true })
  }

  const { currentIndex, tabTitles, tabContents } = getContents()
  return (
      <TabParent selectedIndex={currentIndex} onSelect={onSelectChange}>
        <TabBar>
          {tabTitles}
        </TabBar>
        <div>
          {tabContents}
        </div>
      </TabParent>
  )
}

export default RouterTabs
