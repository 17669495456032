// @flow

import React from 'react'
import styled from 'styled-components'
import type { ReactComponentStyled } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from './i18n/messages'
import color from 'michelangelo/dist/Components/styles/color'
// import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'

const ParentContainer: ReactComponentStyled<{}> = styled.div`
  width: 100%;
  // height: calc(100vh - 110px);
  top: calc(100vh - 110px);
  height: ${(props) => props.customHeight || 'calc(100vh - 110px)'};
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ChildContainer = styled.div`
  flex-direction: column;
  display: inline-block;
  width: auto;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`
const TextContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`
const IconContainer: ReactComponentStyled<{}> = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`

// add for testing purpose
ParentContainer.displayName = 'ParentContainer'
TextContainer.displayName = 'TextContainer'

type LoadingScreenProps = {
    customHeight?: string
} & InjectIntlProvidedProps

function LoadingScreen (props: LoadingScreenProps) {
  const {
    intl: { formatMessage },
    customHeight
  } = props
  return (
        <ParentContainer customHeight={customHeight}>
            <ChildContainer>
                <IconContainer>
                    {/* <Icon position='relative' color={color.grey400} spin={true} iconName='spinner' size={32} /> */}
                </IconContainer>
                <TextContainer>
                    <Text text={`${formatMessage(messages.loading)}...`} textSize='h5' fontColor={color.grey400} textTransform='capitalize' align='center' />
                </TextContainer>
            </ChildContainer>
        </ParentContainer>
  )
}

export default injectIntl(LoadingScreen)
