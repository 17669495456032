// @flow

import React from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'

const HeaderContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px 0px 20px 0px;
    text-align: center;
`

export const Test2 = () => {
  return (
    <HeaderContainer>
      <Text align='center' textSize='h2' fontColor={color.default} text='Test Page Two' />
    </HeaderContainer>
  )
}

export default Test2
