// @flow

import React from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import Column from 'michelangelo/dist/Components/Column'
import { InputLabel } from 'michelangelo/dist/Components/RadioButtonGroup'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'

const RadioButtonContainer: ReactComponentStyled<{}> = styled.div`
  cursor: pointer;
  background: ${color.white};
  background-color: ${color.white};
  border: 1px solid ${color.lightestGrey};
  box-sizing: border-box;
  border-radius: 8px;
  width: 170px;
  height: 100%;
  padding-top: 9px;
  padding-bottom: 15px;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
`
RadioButtonContainer.displayName = 'RadioButtonContainer'

const TextContainer: ReactComponentStyled<{}> = styled.div`
    padding-bottom: 7px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${color.lightestGrey};
    width: 100%;
`
TextContainer.displayName = 'TextContainer'

const InputContainer: ReactComponentStyled<{}> = styled.div`
  padding-top: 17px;
  padding-bottom: 13px;
  padding-left: 14px;
`
InputContainer.displayName = 'InputContainer'

type RadioButtonOption = {
  value: string,
  label: string,
  src: string
}

type ExploreRadioButtonProps = {
  option: RadioButtonOption,
  selectedOption: string,
  onChange: (value: string) => void
}
function ExploreRadioButton ({ option, selectedOption, onChange }: ExploreRadioButtonProps) {
  const isSelected = selectedOption === option.value
  return (
    <Column>
      <RadioButtonContainer selected={isSelected} onClick={() => onChange(option.value)}>
        <TextContainer>
          <Text text={option.label} fontSize='p' fontColor={color.black} align='center' />
        </TextContainer>
        <img src={option.src} alt={option.label} />
        <InputContainer>
          <InputLabel option={option} selectedOption={selectedOption} />
        </InputContainer>
      </RadioButtonContainer>
    </Column>
  )
}

export default ExploreRadioButton
