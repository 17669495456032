// @flow
/* eslint-disable object-shorthand */

import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
import { withApollo } from '@apollo/client/react/hoc'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { Node } from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { StyledComponentType } from 'styled-components'
import TopNavigationAndHeader from '../Header/TopNavigationAndHeader'
import FirstTopHeader from '../FirstTopHeader'
import color from '../../styles/color'
import Profile from './Profile'
import ProfileIcon from './ProfileIcon'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Icon from 'michelangelo/dist/Components/Icon'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import { messages } from '../../i18n/messages'
import { injectIntl } from 'react-intl'
import SidebarRoutes from './SideBarRoutes'
import { AppContext } from '../../contexts/app'

type SideBarProps = {
  children: Node,
  user: any
} & InjectIntlProvidedProps

/* istanbul ignore next */
const SideNavBodyContainer: StyledComponentType<{}> = styled.div`
  background: ${color.blueBody};
  height: 100vh;
  overflow: auto;

  -webkit-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
  -moz-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
    -o-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
    transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000); /* ease-in-out */

    -webkit-transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000);
    -o-transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000);
    transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000); /* ease-in-out */
    @media (max-width: 780px) {
    width: 100%;
    left: 0;
    margin-left: 0px;
  }
`
/* istanbul ignore next */
const SideBarContainer: StyledComponentType<{}> = styled.div`
  width: ${(props) => props.isopen ? '250px' : '60px'};
`
/* istanbul ignore next */
const SideNavBar: StyledComponentType<{}> = styled.div`
  height: 100%;
  background: ${color.sideNav};
  position: absolute;
  overflow: auto;
  top: 0;

  -webkit-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
  -moz-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
  -o-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
  transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000); /* ease-in-out */

  -webkit-transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000);
  -o-transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000);
  transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000); /* ease-in-out */
  @media (max-width: 780px) {
    left: 0;
    width: 250px;
    display: ${(props) => props.isopen ? 'block' : 'none'};
    z-index: 10;
    top: 0;
  }
`
/* istanbul ignore next */
const IconContainer: StyledComponentType<{}> = styled.div`
  display: block;
  text-align: ${(props) => props.isopen ? 'right' : 'center'};
  padding: ${(props) => props.isopen ? '8px 16px 0px 0px' : '8px 0px 16px 0px'};
  cursor: pointer;
  font-size: ${(props) => props.isopen ? '24px' : '32px'};
  @media (max-width: 780px) {
    display: none;
  }
`
/* istanbul ignore next */
const IconMobileContainer: StyledComponentType<{}> = styled.div`
  display: none;
  @media (max-width: 780px) {
    cursor: pointer;
    display: block;
    padding: 8px 24px 0px 0px;
    font-size: ${(props) => props.isopen ? '24px' : '32px'};
    svg {
      color: ${(props) => props.isopen ? `${color.white}` : `${color.default}`};
      z-index: 9;
    }
  }
`
const ChildContainer: StyledComponentType<{}> = styled.div`
  margin: 24px;
  @media (max-width: 411px) {
    margin: 10px;
  }
  @media (max-width: 320px) {
    margin: 5px;
  }
`
/* istanbul ignore next */
const ListContainer: StyledComponentType<{}> = styled.div`
    display: block;
    margin-top: ${(props) => props.isopen ? '134px' : '32px'};
`
/* istanbul ignore next */
const LogoutList = styled.div`
    display: none;
    @media (max-width: 780px) {
      display: flex;
      cursor: pointer;
      height: 40px;
      background: ${color.logout};
      width: ${(props) => props.isopen ? '250px' : '60px'};
      text-align: ${(props) => props.isopen ? 'left' : 'center'};
    }
`
/* istanbul ignore next */
const LogoutText: StyledComponentType<{}> = styled.div`
    display: none;
    @media (max-width: 780px) {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
`

const Nav = styled(NavLink)`
  display: inherit;
  width: 100%;
`

const ProfileIonContainer = styled.div`
  position: absolute;
  width: 60px;
  bottom: 5%;
  @media (max-height: 375px) {
    display: none;
  }
  @media (max-height: 450px) {
    bottom: 12%;
  }
  @media (max-height: 640px) {
    bottom: 10%;
  }
  @media (max-height: 890px) {
    bottom: 7%;
  }
`

// add for testing purpose
SideNavBodyContainer.displayName = 'SideNavBodyContainer'
SideBarContainer.displayName = 'SideBarContainer'
SideNavBar.displayName = 'SideNavBar'
IconContainer.displayName = 'IconContainer'
IconMobileContainer.displayName = 'IconMobileContainer'
ChildContainer.displayName = 'ChildContainer'
ListContainer.displayName = 'ListContainer'
LogoutList.displayName = 'LogoutList'
LogoutText.displayName = 'LogoutText'
Nav.displayName = 'Nav'
ProfileIonContainer.displayName = 'ProfileIonContainer'

const SideBar = (props: SideBarProps) => {
  const { isToggled, toggleSidebar, user } = useContext(AppContext)
  const name = user.user.name
  const { intl: { formatMessage }, children } = props

  const getStyles = () => {
    let barIcon
    if (isToggled) {
      barIcon = 'chevronLeft'
    } else {
      barIcon = 'bars'
    }
    return { barIcon }
  }

  const toggleSideBar = (e: Event) => {
    e.preventDefault()
    toggleSidebar()
  }
  const { barIcon } = getStyles()
  const profileIcon = isToggled ? null : <ProfileIonContainer><ProfileIcon name={name} /></ProfileIonContainer>
  return (
      <Row classNames={['is-marginless']}>
        <Column classNames={['is-narrow', 'is-paddingless']}>
          <SideBarContainer isopen={isToggled}>
            <SideNavBar isopen={isToggled}>
              <Row classNames={['is-mobile', 'is-marginless']}>
                <Column classNames={['is-1', 'is-offset-11', 'is-paddingless']}>
                  <IconMobileContainer isopen={isToggled} onClick={toggleSideBar}><Icon isopen={isToggled} color={color.white} iconName='chevronLeft' /></IconMobileContainer>
                </Column>
              </Row>
              <Row classNames={['is-centered', 'is-marginless']}>
                <Column classNames={['is-full', 'is-paddingless']}>
                  <IconContainer isopen={isToggled} onClick={toggleSideBar}><Icon color={color.white} iconName={barIcon} /></IconContainer>
                </Column>
              </Row>
              <Profile isToggledOpen={isToggled} name={name} />
              <ListContainer isopen={isToggled}>
                <LogoutList isopen={isToggled}>
                  <Nav to='/'>
                    <LogoutText>
                      <Text align='center' textSize='p' fontColor={color.white} text={formatMessage(messages.logout)} />
                    </LogoutText>
                  </Nav>
                </LogoutList>
                <SidebarRoutes />
              </ListContainer>
              {profileIcon}
            </SideNavBar>
          </SideBarContainer>
        </Column>
        <Column classNames={['is-paddingless']}>
          <SideNavBodyContainer isopen={isToggled}>
            <Column classNames={['is-full', 'is-paddingless']}>
              <FirstTopHeader handleToggleSideBarOpen={toggleSideBar} isopen={isToggled} />
            </Column>
            <Column classNames={['is-full', 'is-paddingless']}>
              <TopNavigationAndHeader name={name} />
            </Column>
            <ChildContainer>
              <Row classNames={['is-marginless']}>
                {children}
              </Row>
            </ChildContainer>
          </SideNavBodyContainer>
        </Column>
      </Row>
  )
}
export default withApollo<SideBarProps>(withRouter(injectIntl(SideBar)))
export { SideBar as defaultSideBar }
