// @flow

import React, { useContext, useEffect, useState } from 'react'
import color from '../../styles/color'
import styled from 'styled-components'
// $FlowFixMe
import type { StyledComponentType } from 'styled-components'
import { NavLink, useParams } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
import type { Match } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import HeaderName from './HeaderName'
import { isEmpty } from 'lodash'
import { GET_APPLICATION_BY_ID } from '../../queries/application'
import { GET_ACCOUNT_BY_ID } from '../../queries/account'
import { useQuery } from '@apollo/client'
import { AppContext } from '../../contexts/app'
import useScreenSize from '../../utils/useScreenSize'
type LabelType = {
  id: string,
  defaultMessage: string
}
type NavigationLinks = {
  id: string,
  label: LabelType,
  route: string
}

type TopNavigationProps = {
    mobileNavigation?: Array<NavigationLinks>,
    desktopNavigation: Array<NavigationLinks>,
    lastPathname: string,
    firstPathname: string,
    name: string,
    match: Match,
    customerCare?: boolean,
    isHighLighted?: boolean
} & InjectIntlProvidedProps

/* istanbul ignore next */
const NavigationHeaderContainer: StyledComponentType<{}> = styled.div`
    padding: 0.7vh 0px 12px 1.2vh;
    background-color: ${color.white};
    border-top: 1px solid ${color.grayBorder};
    border-bottom: 1px solid ${color.grayBorder};
    height: 160px;
    position: relative;
`
const NavigationMobileHeaderContainer: StyledComponentType<{}> = styled.div`
    width: 125px;
    height: 60px;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    list-style: none;
    padding: 5px;
    display: table-cell;
    padding: 50px 20px 0px 20px;
    width: auto;
    display: block;

    top: 0;
    width: 100%;
    height: 120px;
    @media (max-width: 414px) {
      height: 55px;
    }
    background: ${color.white};
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.50);
    padding: 0px 0px 0px 0px;
    margin-bottom: 50px;

    white-space: nowrap; /* [1] */
    overflow-x: auto; /* [2] */
    -webkit-overflow-scrolling: touch; /* [3] */
    -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */

    &::-webkit-scrollbar {
      display: none;
    }

`

const NavigationContainer: StyledComponentType<{}> = styled.div`
    display: flex;
    width: auto;
    margin: 0px 16px;
    height: 40px;
    bottom: 0;
    position: absolute;
    @media (max-width: 780px) {
      background-color: rgba(196,196,196,0.21);
      overflow: auto;
      white-space: nowrap;
      position: inherit;
      margin: 0px;
    }
`
// $FlowFixMe
const List = styled(NavLink)`
    padding-right: 50px;
    cursor: pointer;
    height: 40px;
    width: auto;
    @media (max-width: 780px) {
      display: inline-block;
      color: white;
      text-align: center;
      padding: 14px;
      text-decoration: none;
    }
`
/* istanbul ignore next */
const Underline: StyledComponentType<{}> = styled.div`
    width: 110%;
    height: 4px;
    background: ${color.info};
    margin-left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 20px;
    display: ${(props) => props.isHighlighted ? 'block' : 'none'};
`
/* istanbul ignore next */
const TextNav: StyledComponentType<{}> = styled.div`
    height: 19px;
    font-size: 16px;
    font-family: Raleway;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0;
    color: ${(props) => props.isHighlighted ? color.info : color.default};
    @media(max-width: 780px) {
      text-transform: capitalize;
      opacity: ${(props) => props.isHighlighted ? '1' : '.5'};
    }
`

// add for testing purpose
NavigationHeaderContainer.displayName = 'NavigationHeaderContainer'
NavigationContainer.displayName = 'NavigationContainer'
List.displayName = 'List'
Underline.displayName = 'Underline'
TextNav.displayName = 'TextNav'
NavigationMobileHeaderContainer.displayName = 'NavigationMobileHeaderContainer'

const TopNavigation = (props: TopNavigationProps) => {
  const { user } = useContext(AppContext)
  const { width } = useScreenSize()
  const params = useParams()
  const { isHighLighted, customerCare, customTitle, mobileNavigation, desktopNavigation, firstPathname, lastPathname, name, intl: { formatMessage } } = props
  const [isMobile, setIsMobile] = useState(width <= 780)
  const [applicationTitle, setApplicationTitle] = useState('')
  const [accountTitle, setAccountTitle] = useState(customerCare ? 'APPrise Mobile' : '')
  let accountId

  if (isEmpty(params.accountId)) {
    accountId = user.account._id
  } else {
    accountId = params.accountId
  }
  const { loading: loadingApp, data: application } = useQuery(GET_APPLICATION_BY_ID, { variables: { _id: params.id }, skip: !params.id, fetchPolicy: 'cache-and-network' })
  const { loading: loadingAccount, data: account } = useQuery(GET_ACCOUNT_BY_ID, { variables: { account: accountId }, skip: !accountId, fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    setIsMobile(width <= 780)
  }, [width])
  useEffect(() => {
    if (application && application.getApplicationById && application.getApplicationById.title) {
      setApplicationTitle(application.getApplicationById.title)
    }
  }, [application])
  useEffect(() => {
    if (account && account.getAccountById && account.getAccountById.name) {
      setAccountTitle(account.getAccountById.name)
    }
  }, [account])

  const getList = (id: string, toLink: string, label: LabelType, highLighted: boolean) => {
    return (
        <List
            key={id}
            to={toLink}
            className='top-nav--item'
        >
          <TextNav isHighlighted={!!(isHighLighted || highLighted)}>
            {formatMessage({ id: label.id })}
          </TextNav>
          <Underline isHighlighted={!!(isHighLighted || highLighted)} />
        </List>
    )
  }

  const getMobileNavigation = () => {
    if (isEmpty(mobileNavigation)) {
      return null
    }
    return (
        <NavigationMobileHeaderContainer>
          <HeaderName applicationTitle={customTitle || applicationTitle} accountTitle={accountTitle} name={name} />
          {mobileNavigation.map(({ id, label, route }) => {
            const paths = route.split('/')
            const path = paths[paths.length - 2]
            return getList(id, route, label, lastPathname === path)
          })}
        </NavigationMobileHeaderContainer>
    )
  }
  const getDesktopNavigation = () => {
    return (
        <NavigationHeaderContainer>
          <HeaderName applicationTitle={applicationTitle} accountTitle={accountTitle} name={name} />
          <NavigationContainer>
            {desktopNavigation.map(({ id, route, label }) => {
              const firstRouteName = route.split('/')[1]
              return getList(id, route, label, firstPathname === firstRouteName)
            })}
          </NavigationContainer>
        </NavigationHeaderContainer>
    )
  }
  if (loadingApp && loadingAccount) return null
  const navigation = isMobile ? getMobileNavigation() : getDesktopNavigation()
  return (
      <div>
        {navigation}
      </div>
  )
}
export default withRouter(injectIntl(TopNavigation))
export { TopNavigation as unConnectedTopNavigation }
