// @flow
/* eslint-disable object-shorthand */

import React, { Component } from 'react'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Button from 'michelangelo/dist/Components/Buttons/Button'
import FixedLabelInput from 'michelangelo/dist/Components/FixedLabelInput'
import PreviewBox from 'michelangelo/dist/Components/PreviewBox'
import Dropdown from 'michelangelo/dist/Components/Dropdown'
import Steps from 'michelangelo/dist/Components/Steps'
import Icon from 'michelangelo/dist/Components/Icon'
import theme from 'michelangelo/dist/Components/styles/theme'
import color from 'michelangelo/dist/Components/styles/color'
import { camelCase, cloneDeep, replace } from 'lodash'
import { Mutation } from '@apollo/client/react/components'
import { GetAsyncApplicationByID, UpdateApplicationToCache } from '../../queries/application'
import { ApolloClient, gql } from '@apollo/client'
import type { InjectIntlProvidedProps } from 'react-intl'
import { messages } from '../../i18n/messages'
import { defineMessages, injectIntl } from 'react-intl'
import withRouter from '../../utils/withRouter'

const Container: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-rows: max-content auto 50px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    min-height: 500px;
    align-items: center;
    justify-items: center;
    min-width: 352px;
    padding: 12px;

    grid-template-areas: 'dropDown steps'
                         'input preview'
                         'buttons buttons';

    @media screen and (max-width: 600px){
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-gap: 20px;
        grid-template-areas:  'dropDown'
                              'steps'
                              'input'
                              'preview'
                              'buttons';
    }
`
const InputsWrapper: ReactComponentStyled<{}> = styled.div`
      display: grid;
      width: 100%;
      grid-template-rows: auto auto;
      justify-items: center;
      grid-gap: 16px;
`

const ButtonsContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-self: center;
    grid-gap: 16px;

    grid-area: buttons;

    @media screen and (min-width: 600px){
                grid-column: 1/3;
                grid-gap: 24px;
    }

`
const DropDownContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-rows: 40px auto;
    grid-template-columns: 1fr;
    width: 60%;
    justify-items: start;
    justify-self: start;

    grid-area: dropDown;

    @media screen and (max-width: 600px){
      justify-items: stretch;
      justify-self: center;
      align-items: center;
      width: 320px;
    }
`
const CustomizeInputContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-rows: 40px 60px;
    grid-template-columns: 1fr;
    width: 60%;
    justify-self: start;

    grid-area: input;

    @media screen and (max-width: 600px){
      align-items: center;
      justify-self: center;
      width: 320px;
    }
`
const PreviewContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    width: inherit;
    align-self: start;
    justify-items: center;

    grid-area: preview;

    @media screen and (max-width: 600px){
       max-width: 400px;
    }
`
const StepsContainer: ReactComponentStyled<{}> = styled.div`
      grid-area: steps;
      max-width: 550px;

      @media screen and (max-width: 600px){
       max-width: 400px;
    }
`
const Center: ReactComponentStyled<{}> = styled.div`
    justify-self: center;
`

// add for testing purpose
Container.displayName = 'Container'
InputsWrapper.displayName = 'InputsWrapper'
ButtonsContainer.displayName = 'ButtonsContainer'
DropDownContainer.displayName = 'DropDownContainer'
CustomizeInputContainer.displayName = 'CustomizeInputContainer'
PreviewContainer.displayName = 'PreviewContainer'
StepsContainer.displayName = 'StepsContainer'
Center.displayName = 'Center'

type RegistrationProps = {
  application: Object,
  client: ApolloClient,
  params: Object
} & InjectIntlProvidedProps

type RegistrationState = {
  selectedAuthType: string,
  stepNames: Array<string>,
  currentPreviewIndex: number,
  currentStepName: string,
  csv: Object,
  openRegistration: Object,
  placeholder: Object
}

export const RegistrationMessages = defineMessages({
  labelPrefix: {
    id: 'customRegistration.labelPrefix',
    defaultMessage: 'Enter'
  },
  AccountID: {
    id: 'customRegistration.AccountID',
    defaultMessage: 'Account ID'
  },
  Email: {
    id: 'customRegistration.Email',
    defaultMessage: 'Email'
  },
  Name: {
    id: 'customRegistration.Name',
    defaultMessage: 'Name'
  },
  FirstName: {
    id: 'customRegistration.FirstName',
    defaultMessage: 'First Name'
  },
  LastName: {
    id: 'customRegistration.LastName',
    defaultMessage: 'Last Name'
  },
  Password: {
    id: 'customRegistration.Password',
    defaultMessage: 'Password'
  },
  openRegistration: {
    id: 'customRegistration.openRegistration',
    defaultMessage: 'Open Registration'
  },
  csv: {
    id: 'customRegistration.csv',
    defaultMessage: 'CSV'
  }
})

const SAVE_REGISTRATION_FIELDS = gql`
  mutation setCustomTexts($application:String!, $registerRegCodeAccountPlaceholder:String, $registerRegCodeEmailPlaceholder:String, $registerOpenRegEmailPlaceholder:String ) {
    setCustomTexts(application: $application, registerRegCodeAccountPlaceholder: $registerRegCodeAccountPlaceholder, registerRegCodeEmailPlaceholder: $registerRegCodeEmailPlaceholder, registerOpenRegEmailPlaceholder: $registerOpenRegEmailPlaceholder ) {
     _id
      customTexts {
        loginOpenRegPlaceholder
        loginRegCodePlaceholder
        registerRegCodeAccountPlaceholder
        registerRegCodeTooltip
        registerRegCodeEmailPlaceholder
        registerOpenRegEmailPlaceholder
        btnSingleSignOn
        btnOpenReg
        btnNotEmployee
        btnCreateOrLogin
        }
      }
  }
`

const CSV = 'CSV'
const OPEN_REGISTRATION = 'Open Registration'

let labelPrefix = ''

class RegistrationComponent extends Component<RegistrationProps, RegistrationState> {
  constructor (props: RegistrationProps) {
    super(props)

    const { selectedAuthType, stepNames, csvInputObject, openRegistrationInputObject, csvInputPlaceholder, openRegistrationInputPlaceholder } = this.getInitialValues()

    this.state = {
      selectedAuthType: selectedAuthType,
      stepNames: stepNames,
      currentPreviewIndex: 0,
      currentStepName: '',
      csv: csvInputObject,
      openRegistration: openRegistrationInputObject,
      placeholder: {
        csv: csvInputPlaceholder,
        openRegistration: openRegistrationInputPlaceholder
      }
    }
  }

  getInitialValues = () => {
    const { intl: { formatMessage }, application: { registrationCodeEnabled, customTexts } } = this.props
    labelPrefix = formatMessage(RegistrationMessages.labelPrefix) + ' '

    const registerOpenRegEmailPlaceholder = customTexts ? customTexts.registerOpenRegEmailPlaceholder : ''
    const registerRegCodeAccountPlaceholder = customTexts ? customTexts.registerRegCodeAccountPlaceholder : ''
    const registerRegCodeEmailPlaceholder = customTexts ? customTexts.registerRegCodeEmailPlaceholder : ''
    const registrationCodeAccountInput = this.noPrefix(registerRegCodeAccountPlaceholder)
    const registrationCodeEmailInput = this.noPrefix(registerRegCodeEmailPlaceholder)
    const openRegistrationEmailInput = this.noPrefix(registerOpenRegEmailPlaceholder)

    const openRegistrationStepNames = [formatMessage(RegistrationMessages.Email), formatMessage(RegistrationMessages.Name), formatMessage(RegistrationMessages.Password)]
    const csvStepNames = [formatMessage(RegistrationMessages.AccountID), ...openRegistrationStepNames]

    const openRegistrationInputObject = {
      Email: '',
      Name: {
        FirstName: formatMessage(RegistrationMessages.FirstName),
        LastName: formatMessage(RegistrationMessages.LastName)
      },
      Password: formatMessage(RegistrationMessages.Password)
    }

    const csvInputObject = {
      AccountID: '',
      ...openRegistrationInputObject
    }

    const openRegistrationInputPlaceholder = {
      ...openRegistrationInputObject,
      Email: openRegistrationEmailInput || formatMessage(RegistrationMessages.Email)
    }

    const csvInputPlaceholder = {
      ...openRegistrationInputObject,
      AccountID: registrationCodeAccountInput || formatMessage(RegistrationMessages.AccountID),
      Email: registrationCodeEmailInput || formatMessage(RegistrationMessages.Email)
    }

    const selectedAuthType = registrationCodeEnabled ? CSV : OPEN_REGISTRATION
    const stepNames = registrationCodeEnabled ? csvStepNames : openRegistrationStepNames

    return { selectedAuthType, stepNames, csvInputObject, openRegistrationInputObject, csvInputPlaceholder, openRegistrationInputPlaceholder }
  }

  noPrefix = (value: string) => (replace(value, labelPrefix, ''))

  handleInputChange = (value: string) => {
    const { selectedAuthType, [camelCase(selectedAuthType)]: inputValues, currentPreviewIndex } = this.state
    const currentStepName = Object.keys(inputValues)[currentPreviewIndex]

    this.setState(prevState => ({
      [camelCase(selectedAuthType)]: {
        ...prevState[camelCase(selectedAuthType)],
        [currentStepName]: value
      }
    }))
  }

  getInput = () => {
    const { intl: { formatMessage } } = this.props
    const { selectedAuthType, [camelCase(selectedAuthType)]: inputValues, currentPreviewIndex, placeholder: { [camelCase(selectedAuthType)]: placeholderValues } } = this.state
    const currentStepName = Object.keys(inputValues)[currentPreviewIndex]
    const inputValue = inputValues[currentStepName]
    const placeholderValue = placeholderValues[currentStepName]

    const inputField = <FixedLabelInput fixedLabel placeholder={placeholderValue} label={labelPrefix} value={inputValue} onChange={this.handleInputChange} />
    let customizeInput
    if (['Name', 'Password'].includes(currentStepName)) {
      customizeInput = (
        <React.Fragment>
          <Center><Icon iconName='warning' color={theme.danger} /></Center>
          <Text text={formatMessage(messages.fieldsNotCustomizable)} fontColor={theme.danger} textSize='p' align='center' />
        </React.Fragment>
      )
    } else {
      customizeInput = (
        <React.Fragment>
          <Text text={formatMessage(messages.customizeText)} fontColor={theme.info} textSize='p' />
          {inputField}
        </React.Fragment>
      )
    }

    return { customizeInput }
  }

  getPreviewBoxObject = () => {
    const { selectedAuthType, [camelCase(selectedAuthType)]: inputValues, placeholder: { [camelCase(selectedAuthType)]: placeholderValues } } = this.state

    const accountIdPreview = <FixedLabelInput value='' label={labelPrefix + (inputValues.AccountID || placeholderValues.AccountID)} />
    const emailPreview = <FixedLabelInput value='' label={labelPrefix + (inputValues.Email || placeholderValues.Email)} />
    const passwordPreview = <FixedLabelInput value='' label={inputValues.Password} />
    const namePreview = (
      <InputsWrapper>
        <FixedLabelInput value='' label={inputValues.Name.FirstName} />
        <FixedLabelInput value='' label={inputValues.Name.LastName} />
      </InputsWrapper>
    )
    const previewObject = { ...(Object.prototype.hasOwnProperty.call(inputValues, 'AccountID') && { accountIdPreview }), emailPreview, namePreview, passwordPreview }

    return { previewObject }
  }

  handleOnPreviewBoxChange = (currentPreviewIndex: number) => {
    this.setState({ currentPreviewIndex })
  }

  handleOnDropDownChange = (selectedValue: string) => {
    const { intl: { formatMessage } } = this.props

    const csv = formatMessage(RegistrationMessages.csv)

    const openRegistrationStepNames = [formatMessage(RegistrationMessages.Email), formatMessage(RegistrationMessages.Name), formatMessage(RegistrationMessages.Password)]
    const csvStepNames = [formatMessage(RegistrationMessages.AccountID), ...openRegistrationStepNames]

    const stepNames = selectedValue === csv ? csvStepNames : openRegistrationStepNames
    const selectedAuthType = selectedValue === csv ? CSV : OPEN_REGISTRATION
    this.setState({ selectedAuthType: selectedAuthType, currentPreviewIndex: 0, stepNames })
  }

  onMutationCompleted = async () => {
    const { csv, openRegistration } = this.state
    const { customTexts: { registerOpenRegEmailPlaceholder, registerRegCodeAccountPlaceholder, registerRegCodeEmailPlaceholder } } = await GetAsyncApplicationByID(this.props.client, this.props.params.id || '')

    const registrationCodeAccountInput = this.noPrefix(registerRegCodeAccountPlaceholder)
    const registrationCodeEmailInput = this.noPrefix(registerRegCodeEmailPlaceholder)
    const openRegistrationEmailInput = this.noPrefix(registerOpenRegEmailPlaceholder)

    this.setState({
      placeholder: {
        csv: {
          ...csv,
          ...(registerRegCodeAccountPlaceholder ? { AccountID: registrationCodeAccountInput } : {}),
          ...(registerRegCodeEmailPlaceholder ? { Email: registrationCodeEmailInput } : {})

        },
        openRegistration: {
          ...openRegistration,
          ...(registerOpenRegEmailPlaceholder ? { Email: openRegistrationEmailInput } : {})
        }
      }
    })

    this.resetInputValues()
  }

  resetInputValues = () => {
    const { csv, openRegistration } = this.state
    this.setState({
      csv: {
        ...csv,
        AccountID: '',
        Email: ''
      },
      openRegistration: {
        ...openRegistration,
        Email: ''
      }
    })
  }

  handleOnCancelClick = () => {
    this.resetInputValues()
  }

  SaveButton = () => {
    const { intl: { formatMessage }, application } = this.props
    const { csv, openRegistration } = this.state

    const registrationCodeAccountLabel = labelPrefix + csv.AccountID
    const registrationCodeEmailLabel = labelPrefix + csv.Email
    const openRegistrationEmailLabel = labelPrefix + openRegistration.Email

    const mutationVariables = {
      application: application._id,
      ...(csv.AccountID && { registerRegCodeAccountPlaceholder: registrationCodeAccountLabel }),
      ...(csv.Email && { registerRegCodeEmailPlaceholder: registrationCodeEmailLabel }),
      ...(openRegistration.Email && { registerOpenRegEmailPlaceholder: openRegistrationEmailLabel })
    }

    const areInputsChanged = csv.AccountID || csv.Email || openRegistration.Email

    return (
      <Mutation
        mutation={SAVE_REGISTRATION_FIELDS}
        variables={mutationVariables}
        notifyOnNetworkStatusChange
        onCompleted={() => this.onMutationCompleted()}
        update={(cache, result) => {
          const data = cloneDeep({ ...result.data.setCustomInfo, ...result.data.setCustomTexts })
          if (mutationVariables.registerRegCodeAccountPlaceholder) data.customTexts.registerRegCodeAccountPlaceholder = mutationVariables.registerRegCodeAccountPlaceholder
          if (mutationVariables.registerRegCodeEmailPlaceholder) data.customTexts.registerRegCodeEmailPlaceholder = mutationVariables.registerRegCodeEmailPlaceholder
          if (mutationVariables.customInfo) data.customInfo = { ...data.customInfo, ...mutationVariables.customInfo }
          UpdateApplicationToCache(cache, data)
        }}
      >
        {(setCustomTexts, { data, loading }) => {
          return <Button fill title={formatMessage(messages.saveChanges)} disabled={loading || !areInputsChanged} onClick={() => setCustomTexts(mutationVariables)} />
        }}
      </Mutation>
    )
  }

  render () {
    const { intl: { formatMessage }, application: { openRegistrationEnabled, registrationCodeEnabled } } = this.props
    const { stepNames, currentPreviewIndex } = this.state

    const { previewObject } = this.getPreviewBoxObject()
    const { customizeInput } = this.getInput()

    const csv = formatMessage(RegistrationMessages.csv)
    const openRegistration = formatMessage(RegistrationMessages.openRegistration)
    const dropDownList = [...(registrationCodeEnabled ? [csv] : []), ...(openRegistrationEnabled ? [openRegistration] : [])]

    return (
      <Container>

        <DropDownContainer>
          <Text text={formatMessage(messages.selectRegistrationType)} fontColor={color.textDefault} textSize='p' />
          <Dropdown list={dropDownList} onListItemClick={this.handleOnDropDownChange} />
        </DropDownContainer>

        <CustomizeInputContainer>
          {customizeInput}
        </CustomizeInputContainer>

        <StepsContainer>
          <Steps stepNames={stepNames} currentIndex={currentPreviewIndex} />
        </StepsContainer>

        <PreviewContainer>
          <PreviewBox onChange={this.handleOnPreviewBoxChange}>
            {previewObject}
          </PreviewBox>
        </PreviewContainer>

        <ButtonsContainer>
          <Button outline title={formatMessage(messages.cancel)} onClick={this.handleOnCancelClick} />
          {this.SaveButton()}
        </ButtonsContainer>

      </Container>
    )
  }
}

export default injectIntl(withRouter(RegistrationComponent))
export { RegistrationComponent as UnwrappedRegistrationComponent }
