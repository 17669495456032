// @flow
/* eslint-disable object-shorthand */

import React, { useEffect, useState } from 'react'
import Button from './Components/Button'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import styled from 'styled-components'
import withRouter from './utils/withRouter'
import ButtonTwo from 'michelangelo/dist/Components/Buttons/Button'
import Row from 'michelangelo/dist/Components/Row'
import Table from './Components/Table'
import SearchBar from 'michelangelo/dist/Components/SearchBar'
import Notification from 'michelangelo/dist/Components/Notification'
import Column from 'michelangelo/dist/Components/Column'
import theme from 'michelangelo/dist/Components/styles/theme'
import { messages } from './i18n/messages'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { GET_MASTER_APP_INTEGRATIONS_DATA } from './queries/getMasterAppIntegrationsData'
import Paginator from 'michelangelo/dist/Components/Paginator'
import { ConfirmModal } from './Components'
import color from 'michelangelo/dist/Components/styles/color'
import appIntegrationMessages from './Application/AppIntegrations/AppIntegrationMessages'
import { useNavigate } from 'react-router-dom'

type MasterAppIntegrationPageProps = {} & InjectIntlProvidedProps

export const DELETE_MASTER_APP_INTEGRATION = gql`
  mutation deleteMasterAppIntegration($masterAppIntegrationId:String!,$forceDelete:Boolean) {
    deleteMasterAppIntegration(masterAppIntegrationId:$masterAppIntegrationId,forceDelete:$forceDelete) {
      status
      hasEnabledAppIntegrations
    }
  }
`

const Container: ReactComponentStyled<{}> = styled.div`
  width: 100%;
`
const TableContainer: ReactComponentStyled<{}> = styled.div`
  width: 100%;
`
const ButtonContainer: ReactComponentStyled<{}> = styled.div`
  float: right;
`
const SearchAndButtonContainer: ReactComponentStyled<{}> = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
`
const WhiteContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
`

const InlineDiv: ReactComponentStyled<{}> = styled.div`
  display: inline-block;
`
const Center: ReactComponentStyled<{}> = styled.div`
  text-align: center;
`

const ActionsContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  align-items:center;
  gap: .5rem;
`

// add for testing purpose
Container.displayName = 'Container'
ButtonContainer.displayName = 'ButtonContainer'
SearchAndButtonContainer.displayName = 'SearchAndButtonContainer'
WhiteContainer.displayName = 'WhiteContainer'
InlineDiv.displayName = 'InlineDiv'
Center.displayName = 'Center'
Table.displayName = 'Table'

let tableData = {
  columns: [],
  isSortable: [],
  sorted: [],
  dataAlignment: [],
  rows: []
}

const MasterAppIntegrationsPage = (props: MasterAppIntegrationPageProps) => {
  const { intl: { formatMessage } } = props
  const navigate = useNavigate()
  const [sortField, setSortField] = useState('appName')
  const [sortDirection, setSortDirection] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [skip, setSkip] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [appToBeDeleted, setAppToBeDeleted] = useState(null)

  const [getMasterApp, { loading, data }] = useLazyQuery(GET_MASTER_APP_INTEGRATIONS_DATA, {
    variables: { limit, offset: skip, sortField, sortDirection, search },
    fetchPolicy: 'cache-and-network'
  })
  const [deleteApp, { loading: deletion, error }] = useMutation(DELETE_MASTER_APP_INTEGRATION)

  useEffect(() => {
    if (data && data.getMasterAppIntegrations && data.getMasterAppIntegrations.data) {
      setCount(Math.ceil(data.getMasterAppIntegrations.count / 10))
    }
  }, [data])

  useEffect(() => {
    getMasterApp()
  }, [getMasterApp, limit, skip, sortField, sortDirection, search])

  useEffect(() => {
    if (!deletion) {
      if (!error) {
        handleDeleteCompleted()
        getMasterApp()
      } else if (error) {
        handleDeleteError(error)
      }
    }
    // eslint-disable-next-line
  }, [deletion, error])

  const goToCreateMasterAppIntegration = () => {
    navigate('/app-integrations/create')
  }
  const debounce = (func, timeout = 300) => {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => { func.apply(this, args) }, timeout)
    }
  }

  const setPage = (pageNumber: number) => {
    setPageNumber(pageNumber)
    setSkip((pageNumber - 1) * 10)
  }

  const handleOnSearchChange = debounce((searchValue) => {
    setSearch(searchValue)
    setSortField('appName')
    setLimit(10)
    setSkip(0)
    setPage(1)
  })

  const handleOnHeaderClick = (columnName: string) => {
    let sortField = ''
    let sortedId = 0
    let sortDirection = null
    switch (columnName) {
      case tableData.columns[0]:
        sortedId = 0
        sortField = 'appName'
        break
      default:
        sortedId = 0
        sortField = 'appName'
        break
    }
    if (tableData.sorted[sortedId] === 0) {
      sortDirection = -1
    } else {
      sortDirection = tableData.sorted[sortedId] * -1
    }
    tableData.sorted.fill(0)
    tableData.sorted[sortedId] = sortDirection

    setSortField(sortField)
    setSortDirection(sortDirection)
    setSearch(search)
    setLimit(10)
    setSkip(0)
    setPageNumber(1)
  }

  const onActionClick = (id) => {
    navigate(`/app-integrations/${id}/edit`)
  }

  const onDeleteClick = () => {
    if (isDeleting) {
      return
    }
    setIsDeleting(true)
    deleteApp({ variables: { masterAppIntegrationId: appToBeDeleted.id, forceDelete: true } })
  }

  const handleDeleteCompleted = () => {
    setShowDeleteModal(false)
    setAppToBeDeleted(null)
    setIsDeleting(false)
    setErrorMessage(null)
  }

  const handleDeleteError = (error) => {
    setShowDeleteModal(false)
    setAppToBeDeleted(null)
    setIsDeleting(false)
    setErrorMessage(error.message)
  }

  const handleAppDelete = (app) => {
    setErrorMessage(null)
    setAppToBeDeleted(app)
    setShowDeleteModal(true)
  }

  const cancelModal = () => {
    if (isDeleting) {
      return
    }
    setShowDeleteModal(false)
  }

  const getLoadingNotification = (loading: boolean) => {
    const loadingTitle = formatMessage(messages.gettingAccountTableData)
    if (loading) {
      return <Notification show message={loadingTitle} autoClose={0} type='loading' />
    }
    return null
  }

  const getErrorNotification = (errorMessage: boolean) => {
    if (errorMessage) {
      return <Notification show message={errorMessage} autoClose={3} type='error' />
    }
    return null
  }

  if (loading && !data) return null
  const errorNotification = getErrorNotification(errorMessage)
  const loadingNotification = getLoadingNotification(loading)
  let rows = []
  if (data && data.getMasterAppIntegrations && data.getMasterAppIntegrations.data) {
    const masterAppIntegrationsList = data.getMasterAppIntegrations.data
    rows = masterAppIntegrationsList.map(({ _id, appName, logoUrl, hasEnabledAppIntegrations }) => {
      return {
        _id,
        logoUrl,
        [formatMessage(messages.masterAppIntegration)]: appName,
        [formatMessage(messages.actions)]: (
            <ActionsContainer>
              <ButtonTwo key={_id + '_edit'} padding='7px' size='xs' outline icon='pen' onClick={(e) => onActionClick(_id)} />
              <ButtonTwo key={_id + '_delete'} padding='7px' size='xs' outline icon={deletion ? 'loading' : 'trash'} style='error' onClick={() => handleAppDelete({ id: _id, hasEnabledAppIntegrations })} />
            </ActionsContainer>
        )
      }
    })
    tableData.rows = rows
    tableData.hasLogoUrl = true
  }
  // If condition so table data is defined only once on initial load
  if (tableData.columns.length === 0) {
    tableData = {
      columns: [formatMessage(messages.masterAppIntegration), formatMessage(messages.actions)],
      dataAlignment: ['left', 'left'],
      isSortable: [true, false],
      sorted: [1, 0],
      rows: [],
      columnRatio: [1, 0]
    }
  }

  let modalText = appIntegrationMessages.modalBody

  if (appToBeDeleted && appToBeDeleted.hasEnabledAppIntegrations) {
    modalText = appIntegrationMessages.modalBodyEnabledApp
  }
  const table = <Table onHeaderClick={handleOnHeaderClick} tableData={tableData} columnRatio={[1, 0]} />
  const body = <TableContainer>
    {table}
    {loadingNotification}
  </TableContainer>
  const paginator = (
      <Center>
        <InlineDiv>
          <Paginator
              previousLabel={formatMessage(messages.prev)}
              nextLabel={formatMessage(messages.next)} breakLabel='...'
              pageCount={count}
              pageRangeDisplayed={5}
              initialPage={1}
              selected={pageNumber}
              onPageChange={(page) => setPage(page)}
              theme={theme.info}
          />
        </InlineDiv>
      </Center>
  )
  return (
      <>
        <Container>
          <Row classNames={['is-marginless', 'is-centered']}>
            <SearchAndButtonContainer>
              <Column classNames={['is-4', 'is-paddingless']}>
                <SearchBar
                    placeholder={formatMessage(messages.search)}
                    onChange={handleOnSearchChange}
                    serverSideOnly
                />
              </Column>
              <Column classNames={['is-8', 'is-paddingless']}>
                <ButtonContainer>
                  <Button disabled={false} kind='logout' type='button' onClick={(e) => goToCreateMasterAppIntegration(e)}>{formatMessage(messages.createMasterAppIntegration)}</Button>
                </ButtonContainer>
              </Column>
            </SearchAndButtonContainer>
          </Row>
          <WhiteContainer>
            {body}
            <Row classNames={['is-marginless', 'is-centered']}>
              <Column classNames={['is-8']}>
                {paginator}
              </Column>
            </Row>
          </WhiteContainer>
        </Container>

        {errorNotification}

        {showDeleteModal &&
        <ConfirmModal
            cancelAction={() => cancelModal()}
            buttonAction={() => onDeleteClick()}
            icon='warning'
            buttonText={isDeleting ? formatMessage(appIntegrationMessages.isDeleting) : formatMessage(appIntegrationMessages.yesDelete)}
            headerText={formatMessage(appIntegrationMessages.areYouSure)}
            cancelText={formatMessage(messages.cancel)}
            modalText={formatMessage(modalText)}
            modalTheme={color.warning}
            modalFontColor='warning'
        />}
      </>
  )
}

export default withRouter(injectIntl(MasterAppIntegrationsPage))
export { MasterAppIntegrationsPage as unConnectedMasterAppIntegrationsPage }
