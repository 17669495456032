// @flow

import { useState, useEffect } from 'react'

const useScreenSize = () => {
  const getWindowWidth = () => {
    const windowWidth = window.screen.availWidth
    const width = window.innerWidth
    if (width < windowWidth) {
      return width
    }
    return windowWidth
  }
  const [width, setWidth] = useState(getWindowWidth())
  const updateWindowDimensions = () => {
    setWidth(getWindowWidth())
  }
  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
    // eslint-disable-next-line
  }, [])
  return { width }
}

export default useScreenSize
