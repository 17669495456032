// @flow

import React from 'react'
import color from '../../styles/color'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import ProfileIcon from './ProfileIcon'

type ProfileProps = {
    isToggledOpen: boolean,
    name: string
}

/* istanbul ignore next */
const AboutMeContainer: ReactComponentStyled<{}> = styled.div`
    position: absolute;
    margin-top: 24px;
    display: ${(props) => props.isopen ? 'block' : 'none'};
`
const ProfileNameContainer: ReactComponentStyled<{}> = styled.div`
    margin-top: 50px;
    width: 250px;
    height: 19px;
    text-align: center;
`
/* istanbul ignore next */
const ProfileName: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 19px;
    color: ${color.white};
    font-size: 16px;
    font-family: Raleway;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0;
`
const HorizontalLine: ReactComponentStyled<{}> = styled.hr`
  height: 1px;
  color: #687480;
  background-color: #687480;
  margin-left: 10px;
  margin-right: 10px;
`
AboutMeContainer.displayName = 'AboutMeContainer'
ProfileIcon.displayName = 'ProfileIcon'
ProfileNameContainer.displayName = 'ProfileNameContainer'
ProfileName.displayName = 'ProfileName'

const Profile = (props: ProfileProps) => {
  const { isToggledOpen, name } = props

  return (
        <AboutMeContainer isopen={isToggledOpen}>
            <ProfileIcon isopen={isToggledOpen} name={name} />
            <ProfileNameContainer>
                <ProfileName>{name}</ProfileName>
            </ProfileNameContainer>
            <HorizontalLine />
        </AboutMeContainer>
  )
}
export default Profile
