// @flow

import * as React from 'react'
import styled from 'styled-components'

import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Icon from 'michelangelo/dist/Components/Icon'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import color from 'michelangelo/dist/Components/styles/color'
import theme from 'michelangelo/dist/Components/styles/theme'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import { Modal } from './'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import useScreenSize from '../../utils/useScreenSize'

/* istanbul ignore next */
const TextBox: ReactComponentStyled<{}> = styled.div`
    color: ${color.default};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 32px;
    width: 530px;
    text-align: center;
    padding: 0 0 30px 20px;
    @media (max-width: 600px) {
      width: 330px;
    }
    @media (max-width: 360px) {
      width: 280px;
    }
`
const ButtonSpacer: ReactComponentStyled<{}> = styled.span`
    padding-right: 20px;
`
const TextContainer: ReactComponentStyled<{}> = styled.div`
    margin-top: 20px;
`

TextBox.displayName = 'TextBox'
ButtonSpacer.displayName = 'ButtonSpacer'
TextContainer.displayName = 'TextContainer'

type ModalProps = {
  modalTheme: string,
  modalFontColor: 'default' | 'info' | 'warning' | 'danger' | 'success',
  modalText: string,
  icon: string,
  headerText: string,
  buttonAction: () => void,
  cancelAction?: () => void,
  buttonText: string,
  cancelText?: string,
  body?: any
}

const ConfirmModal = (props: ModalProps) => {
  const { width } = useScreenSize()
  const { body, modalText, icon, modalTheme, modalFontColor, headerText, cancelText, buttonText, buttonAction, cancelAction } = props
  const header = <React.Fragment>
    <Icon iconName={icon} size='2x' color={modalTheme} />
    <TextContainer>
      <Text align='center' textSize='h4' fontColor={color[modalFontColor]} text={headerText} />
    </TextContainer>
  </React.Fragment>
  const modalBody = (
      <React.Fragment>
        <TextBox>{modalText}</TextBox>
        {body}
      </React.Fragment>
  )
  const classNames = width <= 360 ? undefined : ['is-mobile']
  const buttons = <Row classNames={classNames}>
    <Column>
      <RoundedButton onClick={() => cancelAction()} width='150px' theme={theme.danger} textTheme={theme.danger} backgroundTheme='#F9F9FB'>{cancelText}</RoundedButton>
    </Column>
    <Column>
      <RoundedButton onClick={() => buttonAction()} width='150px' theme={theme.success} fill>{buttonText}</RoundedButton>
    </Column>
  </Row>
  return (
      <Modal
          header={header}
          body={modalBody}
          footer={buttons}
      />
  )
}
export default ConfirmModal
