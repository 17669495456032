// @flow
/* eslint-disable object-shorthand */

import React, { Component } from 'react'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'

export const GET_ALL_ACCOUNTS_DATA = gql`
query getAllAccountsTableData($limit: Int!, $offset: Int!, $sortField: String!, $sortDirection: Int!, $search: String!) {
    getAllAccountsTableData(limit: $limit, offset: $offset, sortField: $sortField, sortDirection: $sortDirection, search: $search) {
        accountTableDataMessage
        {
            _id
            name
            clientSuccessEmail
            totalUsers
            totalMembers
            contentLastUploaded
        }
        count
    }
}
`
type GetAllAccountsProps = {
    children: Function,
    limit: number,
    offset: number,
    sortField: string,
    sortDirection: number,
    search: string
  }

export class GetAllAccountData extends Component<GetAllAccountsProps, {}> {
  render () {
    const { limit, offset, sortField, sortDirection, search } = this.props
    return (
      <Query
        query={GET_ALL_ACCOUNTS_DATA}
        variables={{ limit: limit, offset: offset, sortField: sortField, sortDirection: sortDirection, search: search }}
        notifyOnNetworkStatusChange
        displayName='AccountData'
      >
        {({ loading, error, data, refetch, networkStatus, client }) => {
          if (!(data)) return null
          return this.props.children(data.getAllAccountsTableData, loading, client)
        }}
      </Query>
    )
  }
}
