/* eslint-disable no-unused-vars */
// @flow
// react-app-polyfill/ie11, and then intl imports must be the first lines in src/app.js
import 'react-app-polyfill/ie11'
import 'intl-pluralrules'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/ar'
import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider, useQuery } from '@apollo/client'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'

import translations from './i18n/locales'
import AppNavigation from './AppNavigation'
import { GET_AUTHENTICATED_USER } from './queries/getAuthenticatedUser'
import NetworkErrorNotification from './NetworkErrorNotification'
import { AppContext } from './contexts/app'
import { useSetupClient } from './apollo/client'
import LoadingScreen from './LoadingScreen'

const AuthenticatedUser = (props: any) => {
  const [isToggled, setIsToggled] = useState(true)
  const [account, setAccount] = useState(null)
  const { loading, data } = useQuery(GET_AUTHENTICATED_USER, {
    variables: {},
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    if (!loading && data && data.getAuthenticatedUser && data.getAuthenticatedUser.user && data.getAuthenticatedUser.account) {
      setAccount(data.getAuthenticatedUser)
    }
  }, [loading, data])
  const toggleSideBar = () => {
    setIsToggled(!isToggled)
  }
  if (loading || !account) {
    return <LoadingScreen />
  }
  return <AppContext.Provider value={{
    user: account,
    isToggled,
    toggleSidebar: () => toggleSideBar()
  }}>
    <BrowserRouter>
      <AppNavigation />
    </BrowserRouter>
  </AppContext.Provider>
}
const App = () => {
  const { client, networkError: network, subdomain, accessToken } = useSetupClient()
  const [networkError, setNetworkError] = useState(network)

  const locale = navigator.language || 'en'

  const messages = translations[locale]
  if (!client && !accessToken && subdomain === 'subdomain') {
    return <IntlProvider locale={locale} key={locale} defaultLocale="en" messages={messages}>
      <LoadingScreen />
    </IntlProvider>
  }

  return <IntlProvider locale={locale} key={locale} defaultLocale="en" messages={messages}>
    <ApolloProvider client={client}>
        <div>
          <NetworkErrorNotification onClose={() => setNetworkError(false)} show={networkError}/>
          <AuthenticatedUser />
        </div>
    </ApolloProvider>
  </IntlProvider>
}
const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App/>)
