/* eslint-disable no-template-curly-in-string */
// @flow

import { messages } from '../../i18n/messages'
import { isEmpty } from 'lodash'

const home = { to: '/home', label: messages.home, icon: 'home', subLinks: [] }

const appApplications = (goBack, accountId) => {
  const route = isEmpty(accountId) ? '/all-applications' : `/all-accounts/view/${accountId}/all-applications`
  return { to: route, label: messages.allApplications, icon: goBack ? 'longArrowLeft' : 'mobile', subLinks: [] }
}
const masterAppIntegrations = (goBack) => {
  const route = '/app-integrations'
  return { to: route, label: messages.masterAppIntegrations, icon: goBack ? 'longArrowLeft' : 'mobile', subLinks: [] }
}
const accountReports = (goBack, accountId) => {
  const route = isEmpty(accountId) ? '/account-settings/account' : `/all-accounts/view/${accountId}/all-reports`
  return { to: route, label: messages.allReports, icon: goBack ? 'longArrowLeft' : 'download', subLinks: [] }
}

const allAccounts = (goBack) => {
  return { to: '/all-accounts', label: messages.allAccounts, icon: goBack ? 'longArrowLeft' : 'list', subLinks: [] }
}

const smsSettings = (goBack, accountId, currentAccount) => {
  return { to: `/all-accounts/view/${accountId || currentAccount}/sms-settings`, label: messages.smsSettings, icon: goBack ? 'longArrowLeft' : 'list', subLinks: [] }
}

const configureAccount = (create: boolean, accountId: string, edit?: boolean) => { return { to: '#', label: messages.configureAccount, icon: 'cog', subLinks: accountSubLinks(create, accountId, edit) } }

const configureMasterAppIntegration = (create: boolean, masterAppIntegrationsId: string, edit?: boolean) => { return { to: '#', label: messages.configureMasterAppIntegration, icon: '', subLinks: appMasterIntegrationSubLinks(create, masterAppIntegrationsId, edit) } }

const accountSubLinks = (create: boolean, accountId: string, edit?: boolean) => {
  const baseRoute = isEmpty(accountId) ? '/account-settings/account' : `/all-accounts/view/${accountId}/account-settings/account`

  if (create) {
    let to
    if (accountId) {
      to = `/all-accounts/view/${accountId}/edit`
    } else {
      to = edit ? `/all-accounts/view/${accountId}/edit` : '/all-accounts/create'
    }
    if (!accountId) {
      return [{ to, label: messages.createAccount, icon: '' }]
    } else {
      return [{ to, label: messages.createAccount, icon: '' }, { to: `/all-accounts/view/${accountId}/sms-settings`, label: messages.smsSettings }]
    }
  } else {
    return [
      { to: `${baseRoute}/test2`, label: { id: 'Test2', defaultMessage: 'Test2' }, icon: '' },
      { to: `${baseRoute}/test3`, label: { id: 'Test3', defaultMessage: 'Test3' }, icon: '' }
    ]
  }
}

const appMasterIntegrationSubLinks = (create: boolean, masterAppIntegrationId: string, edit?: boolean) => {
  if (create) {
    const to = edit ? `/app-integrations/${masterAppIntegrationId}/edit` : 'app-integrations/create'
    return [{ to, label: edit ? messages.editAppIntegration : messages.createMasterAppIntegration, icon: '' }]
  }
}

const accountNavlinkArray = (accountId: string, currentAccount) => [
  accountReports(true, accountId),
  appApplications(true, accountId),
  configureAccount(false, accountId),
  smsSettings(false, accountId, currentAccount)
]

const allAccountsNavLink = () => [
  home,
  allAccounts(false),
  masterAppIntegrations(false)
]

const applicationSubLinks = (id: string, accountId: string) => {
  const baseRoute = isEmpty(accountId) ? `/all-applications/application/${id}` : `/all-accounts/view/${accountId}/all-applications/application/${id}`
  return [
    { to: `${baseRoute}/mobile`, label: messages.mobileSettings, icon: '' },
    { to: `${baseRoute}/customization`, label: messages.customization, icon: '' }
  ]
}
const appBuildSubLinks = (id: string, accountId) => {
  const baseRoute = isEmpty(accountId) ? `/all-applications/application/${id}` : `/all-accounts/view/${accountId}/all-applications/application/${id}`

  return [
    { to: `${baseRoute}/AppBuild/ApplicationDetails`, label: messages.appDetails, icon: '' },
    { to: `${baseRoute}/build/NewAppBuild`, label: messages.newAppBuild, icon: '' }
    // { to: '#', label: messages.registrationOptions, icon: '' } // TODO ${baseRoute}/registration-options
  ]
}
const applicationNavlinkArray = (id: string, accountId: string) => [
  appApplications(true, accountId),
  { to: '#', label: messages.applicationSettings, icon: 'mobile', subLinks: applicationSubLinks(id, accountId) },
  { to: '#', label: messages.appBuild, icon: 'pen', subLinks: appBuildSubLinks(id, accountId) }
]

const homeNavlinkArray = (accountId: string) => {
  const to = isEmpty(accountId) ? '/account-settings/account/test2' : `/all-accounts/view/${accountId}/edit`
  return [
  // TODO bring back when home is completed
  // home,
    { to: '/home', label: messages.home, icon: 'home', subLinks: [] },
    { to: '/all-accounts', label: messages.allAccounts, icon: 'home', subLinks: [] },
    { to, label: messages.configureAccount, icon: 'cog', subLinks: [] },
    appApplications(false, accountId),
    masterAppIntegrations(false)
  ]
}

const reportNavLinkArray = (accountId: string, id: string) => {
  const to = isEmpty(accountId) ? '/all-accounts' : `/all-accounts/view/${accountId}/edit`
  return [
    { to, label: messages.configureAccount, icon: 'cog', subLinks: [] },
    accountReports(false, accountId)
  ]
}

const createAccountNavLink = (accountId: string, edit?: boolean) => {
  const links = [
    allAccounts(true),
    accountReports(false, accountId),
    configureAccount(true, accountId, edit)
  ]
  if (edit) {
    links.push(appApplications(false, accountId))
  }

  return links
}

const createSmsSettingsNavLink = (accountId: string, edit?: boolean) => {
  const links = [
    allAccounts(true),
    accountReports(false, accountId),
    configureAccount(true, accountId, edit),
    appApplications(false, accountId)
  ]

  return links
}

const createMasterAppIntegrationsNavLink = (masterAppIntegrationId: string, edit?: boolean) => {
  const links = [
    masterAppIntegrations(true),
    configureMasterAppIntegration(true, masterAppIntegrationId, edit)
  ]

  return links
}
export { createSmsSettingsNavLink, accountNavlinkArray, applicationNavlinkArray, homeNavlinkArray, allAccountsNavLink, createAccountNavLink, reportNavLinkArray, createMasterAppIntegrationsNavLink }
