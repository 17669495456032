// @flow

import React, { useContext } from 'react'
import { allAccountsNavLink } from './NavigationLinks'
import Menu from './Menu'
import { AppContext } from '../../contexts/app'

type AllAccountsSideBarProps = {
  isToggleSideBarOpen: boolean
}

const AllAccountsSideBar = (props: AllAccountsSideBarProps) => {
  const { isToggled } = useContext(AppContext)
  const navLinks = allAccountsNavLink()
  return <Menu navLinks={navLinks} isopen={isToggled} />
}
export default AllAccountsSideBar
