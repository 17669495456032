import { useLocation, useNavigate, useParams } from 'react-router-dom'

function withRouter (Component) {
  function ComponentWithRouterProp (props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Component
            {...props}
            location={location}
            params={params}
            navigate={navigate}
            match={{ params }}
            history={{
              push: navigate,
              location
            }}
        />
    )
  }

  return ComponentWithRouterProp
}

export default withRouter
