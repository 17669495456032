// @flow

import React from 'react'
import color from '../../styles/color'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import { isEmpty } from 'lodash'

type ProfileIconProps = {
    name: string,
    isopen?: boolean
}

const ProfileIconContainer: ReactComponentStyled<{}> = styled.div`
    display: -webkit-inline-box;
    width: ${(props) => props.isopen ? '60px' : '40px'};
    height: ${(props) => props.isopen ? '60px' : '40px'};
    background: ${color.berryGradient};
    border-radius: 100%;
    position: absolute;
    left: 0;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    float: left;
`
const ProfileTextContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    color: ${color.white};
    font-size: ${(props) => props.isopen ? '24px' : '14px'};
    font-family: Raleway;
    font-weight: ${(props) => props.isopen ? '600' : '200'};
    text-transform: uppercase;
    letter-spacing: 0;
    position: absolute;
    text-align: center;
    top: ${(props) => props.isopen ? '15px' : '12px'};
`
ProfileIconContainer.displayName = 'ProfileIconContainer'
ProfileTextContainer.displayName = 'ProfileTextContainer'

const ProfileIcon = (props: ProfileIconProps) => {
  const { isopen, name } = props

  const getInitials = () => {
    if (isEmpty(name)) {
      return ''
    }
    const nameParts: [string] = name.trim(' ').split(' ')
    if (nameParts.length === 1) {
      return nameParts[0].substring(0, 1)
    }
    return nameParts[0].substring(0, 1) + nameParts[nameParts.length - 1].substring(0, 1)
  }

  const initials = getInitials()

  return (
      <ProfileIconContainer isopen={isopen}>
        <ProfileTextContainer isopen={isopen}>{initials}</ProfileTextContainer>
      </ProfileIconContainer>
  )
}
export default ProfileIcon
