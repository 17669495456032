import { useEffect, useState } from 'react'
import { InMemoryCache } from '@apollo/client/cache'
import { persistCache } from 'apollo3-cache-persist'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ApolloClient, ApolloLink } from '@apollo/client'
import { analyticsMiddleware } from '../utils/analytics'
import { createUploadLink } from 'apollo-upload-client'
import { alfredUrl, publicUrl, serverUrl, riffraffUrl } from '../utils/config'
import qs from 'querystring'
import ApolloCache from '../queries/apolloCache'
import { Riffraff } from '@indicium/leonardo'

const goToAlfred = (subdomain) => {
  const alfred = `${alfredUrl}?subdomain=${subdomain}&callbackUrl=${publicUrl}/`
  window.location.href = alfred
}

const getAccessToken = async () => {
  const logger = {
    info: console.info,
    error: console.error,
    log: console.log
  }
  const riffraff = new Riffraff(riffraffUrl, logger)
  try {
    const result = await riffraff.getAccessWithSecureCookie(false)
    if (!result.accessToken) {
      console.error('riffraff.getAccessWithSecureCookie succeeded but came back with no token')
    } else {
      // we got the accessToken so save it to the local cache
      window.localStorage.setItem('accessToken', result.accessToken)
      return result.accessToken
    }
  } catch (err) {
    console.error('riffraff.getAccessWithSecureCookie threw an error')
    console.error(err)
    goToAlfred()
  }
}

export const useSetupClient = () => {
  const [client, setClient] = useState(null)
  const [networkError, setNetworkError] = useState(false)
  const [subdomain, setSubdomain] = useState('subdomain')
  const [accessToken, setAccessToken] = useState(null)
  // eslint-disable-next-line
  const getTokenAndSubdomain = () => {
    const query = window.location.search
    const queryParams = qs.parse(query.replace(/^.*\?/, ''))
    let subdomain
    const accessToken = window.localStorage.getItem('accessToken') || process.env.REACT_APP_ACCESS_TOKEN

    if (queryParams.subdomain) {
      subdomain = queryParams.subdomain.toString()
      window.localStorage.setItem('subdomain', subdomain)
    } else {
      subdomain = window.localStorage.getItem('subdomain')
    }
    return { subdomain, accessToken }
  }
  useEffect(() => {
    // setupClient definition
    const setUpClient = async () => {
      const { subdomain, accessToken: token } = getTokenAndSubdomain()
      let accessToken = token
      if (!token) {
        accessToken = await getAccessToken()
      }
      // const subdomain = 'rtregcode.beta'
      // const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50TGlrZXNFbmFibGVkIjp0cnVlLCJ1c2VyIjp7Il9pZCI6IjViZWFmODI2NzJjYWQ5MDBkYzg1M2Y2YyIsInJvbGUiOiJhZG1pbiIsIm1lbWJlcnNoaXBzIjpbIjViZWFmODI2NzJjYWQ5MDBkYzg1M2Y3MSJdLCJuYW1lIjoiU2FtIEJyb3duIiwiZW1haWwiOiJydHJlZ2NvZGVAYmV0YS50ZXN0IiwicHJvdmlkZXIiOiJsb2NhbCIsIm1mYSI6eyJjb2RlIjoiMDQ0MzA5IiwiZXhwaXJlQXQiOiIyMDIxLTAxLTIwVDE2OjU3OjMzLjkxOFoifSwiYXBpVXNlciI6eyJhcHBsaWNhdGlvbiI6IjViZWFmODI2NzJjYWQ5MDBkYzg1M2Y2OSJ9LCJhY2NvdW50R3JvdXAiOiI2MTY4NDRhN2ViYTdlNjJkYzQ2NjgyMjgifSwiYWNjb3VudCI6IjViZWFmODI2NzJjYWQ5MDBkYzg1M2Y2ZSIsImFkbWluVHlwZSI6IkFDQ09VTlRfQURNSU4iLCJyZWdpc3RyYXRpb25Db2RlIjoicnRyZWdjb2RlQGJldGEudGVzdCIsInJlYWRXcml0ZUFjY2VzcyI6eyJDQUxFTkRBUiI6W10sIkRJUkVDVE9SWSI6W10sIkdST1VQIjpbXX0sImdyb3Vwc0FuYWx5dGljc0VuYWJsZWQiOmZhbHNlLCJyZWdpb24iOiJOQUUiLCJpYXQiOjE2OTQ1MTc2MzksImV4cCI6MTY5NDUzMjAzOX0.y7iaccjPVzmb_fblgEhPr67pAFx6jsnOiG8j_DMsWdI'

      const cache = new InMemoryCache()
      const defaults = {
        subdomain: subdomain || 'subdomain',
        accessToken: accessToken || 'accessToken',
        isToggleSideBarOpen: true
      }
      await persistCache({
        cache,
        storage: window.localStorage
      })
      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : ''
          }
        }
      })

      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ extensions }) => {
            if (extensions.code === 'UNAUTHENTICATED') {
              goToAlfred(subdomain)
            }
            return true
          })
        } else if (networkError) {
          setNetworkError(true)
        }
      })
      const client = new ApolloClient({
        cache,
        link: authLink.concat(ApolloLink.from([
          analyticsMiddleware, errorLink, createUploadLink({ uri: serverUrl })
        ]))
      })
      const apolloCache = new ApolloCache(cache, defaults)
      apolloCache.writeData(defaults)
      setClient(client)
      setSubdomain(subdomain)
      setAccessToken(accessToken)
    }

    // setUpClient call
    setUpClient()
    // eslint-disable-next-line
  }, [])
  return { client, networkError, subdomain, accessToken }
}
