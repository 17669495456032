// @flow

import React, { useContext } from 'react'
import { createAccountNavLink } from './NavigationLinks'
import Menu from './Menu'
import type { Match } from 'react-router-dom'
import { AppContext } from '../../contexts/app'
import { useParams } from 'react-router-dom'
type CreateAccountSideBarProps = {
  isToggleSideBarOpen: boolean,
  match: Match,
  edit?: boolean
}

const CreateAccountSideBar = (props: CreateAccountSideBarProps) => {
  const { isToggled } = useContext(AppContext)
  const { edit } = props
  const { accountId } = useParams()
  const navLinks = createAccountNavLink(accountId || '', edit)
  return <Menu navLinks={navLinks} isopen={isToggled} />
}
export default CreateAccountSideBar
