// @flow
import React from 'react'
import { Navigate } from 'react-router-dom'
import type { ContextRouter } from 'react-router-dom'
type AdminRouteProps = {
  admin: boolean,
  render?: (props: ContextRouter) => React$Node,
  path?: string,
  exact?: boolean,
  children: any
}

const AdminRoute = (props: AdminRouteProps) => {
  const { admin, children } = props
  if (!admin) {
    return <Navigate to='/permission-denied-403' />
  }

  return children
}

export default AdminRoute
