// @flow
import React, { Component } from 'react'
import styled from 'styled-components'

const CheckboxWrapper: ReactComponentStyled<{}> = styled.div`
  display: flex;
  gap: .3rem;
`

type CheckboxProps = {
  label: string,
  isChecked: boolean,
  handleCheckboxChange: Function,
  testID?: string
}
type CheckboxStates = {
  isChecked: boolean
}
class Checkbox extends Component<CheckboxProps, CheckboxStates> {
  constructor (props: CheckboxProps) {
    super(props)
    this.state = {
      isChecked: this.props.isChecked
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps: CheckBoxProps) {
    if (this.props.isChecked !== nextProps.isChecked && nextProps.isChecked !== this.state.isChecked) {
      this.setState({ isChecked: nextProps.isChecked })
    }
  }

  toggleCheckboxChange = () => {
    const { handleCheckboxChange, label } = this.props
    const isChecked = this.state.isChecked
    this.setState({ isChecked: !isChecked })
    handleCheckboxChange(label, !isChecked)
  }

  render () {
    const { label, testID } = this.props
    const { isChecked } = this.state

    return (
      <div className='checkbox'>
        <CheckboxWrapper>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <input data-cy={testID || 'Checkbox'} type='checkbox' value={label} checked={isChecked} onChange={this.toggleCheckboxChange} />
          {label}
        </CheckboxWrapper>
      </div>
    )
  }
}

export default Checkbox
