// @flow

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ApolloClient, useMutation, useQuery } from '@apollo/client'
import { injectIntl, defineMessages } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { Match } from 'react-router-dom'
import { GET_APPLICATION_BY_ID, SET_BOTTOMNAVIGATIONOPTION } from '../../queries/application'
import { messages } from '../../i18n/messages'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import Column from 'michelangelo/dist/Components/Column'
import Row from 'michelangelo/dist/Components/Row'
import { ConfirmModal } from '../../Components'
import { alfredUrl } from '../../utils/config'
import Notification from 'michelangelo/dist/Components/Notification'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import { InputLabel } from 'michelangelo/dist/Components/RadioButtonGroup'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import { useParams } from 'react-router-dom'

type BottomMenuProps = {
  match: Match,
  client: ApolloClient<any>
} & InjectIntlProvidedProps

type RadioButtonOption = {
  value: string,
  label: string
}

const HeaderContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    padding: 63px 25px 48px 25px;
    text-align: center;
`
HeaderContainer.displayName = 'HeaderContainer'

const MenuContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
`
MenuContainer.displayName = 'MenuContainer'

const TextContainer: ReactComponentStyled<{}> = styled.div`
    padding-bottom: 16px;
    @media (max-width: 414px) {
      padding-bottom: 0;
    }
`
TextContainer.displayName = 'TextContainer'

const InputContainer: ReactComponentStyled<{}> = styled.div`
    padding-bottom: 0px;
    @media (max-width: 414px) {
      padding-bottom: 0;
    }
`
InputContainer.displayName = 'InputContainer'

const BottomNavigationInlineBlock: ReactComponentStyled<{}> = styled.div`
    display: inline-block;
    width: 100%;
    max-width: 900px;
    min-width: 300px;
    min-height: 160px;
    padding-left: 10px;
    padding-right: 10px;
`
BottomNavigationInlineBlock.displayName = 'BottomNavigationInlineBlock'

const ButtonContainer: ReactComponentStyled<{}> = styled.div`
    display: flex;
    justify-self: center;
    flex-direction: space-between;
    justify-content: center;
    align-items: center;
    height: 250px;
`
ButtonContainer.displayName = 'ButtonContainer'

const RadioButtonContainer: ReactComponentStyled<{}> = styled.div`
  cursor: pointer;
  background: ${color.white};
  background-color: ${(props) => props.selected ? color.info : color.white};
  border: 1px solid ${color.lightestGrey};
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 24px;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    padding: 24px;
  }
  @media (max-width: 414px) {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px 5% 18px 5%;
    align-items: center;
  }
`
RadioButtonContainer.displayName = 'RadioButtonContainer'

export const BottomNavigationMessages = defineMessages({
  eventsDescription: {
    id: 'bottomMenu.eventsDescription',
    defaultMessage: 'By selecting this option, Calendar will appear on the Navigation Bar and Directory will appear under the More Menu'
  },
  directoryDescription: {
    id: 'bottomMenu.directoryDescription',
    defaultMessage: 'By selecting this option, Directory will appear on the Navigation Bar and Calendar will appear under the More Menu'
  },
  areYouSure: {
    id: 'bottomMenu.areYouSure',
    defaultMessage: 'Are you sure?'
  },
  yesIDo: {
    id: 'bottomMenu.yesIDo',
    defaultMessage: 'Yes, I Do'
  },
  success: {
    id: 'bottomMenu.success',
    defaultMessage: 'Success, Saved!'
  },
  loading: {
    id: 'bottomMenu.loading',
    defaultMessage: 'Saving ...'
  },
  error: {
    id: 'bottomMenu.error',
    defaultMessage: 'Something went wrong. Please try again.'
  },
  modalBody: {
    id: 'bottomMenu.modalBody',
    defaultMessage: 'Do you want the {formattedSelected} to be on the Navigation Bar and {formattedOther} under More?'
  },
  calendar: {
    id: 'bottomMenu.calendar',
    defaultMessage: 'Calendar'
  },
  directory: {
    id: 'bottomMenu.directory',
    defaultMessage: 'Directory'
  }
})

function BottomMenu ({ intl: { formatMessage } }: BottomMenuProps) {
  const params = useParams()
  const id = params.id || ''
  const { data: application } = useQuery(GET_APPLICATION_BY_ID, { variables: { _id: id }, skip: !id, fetchPolicy: 'cache-and-network' })
  const [notification, setNotification] = useState(undefined)
  const [selectedOption, setSelectedOption] = useState('')
  const [initialOption, setInitialOption] = useState(undefined)
  const [showModal, setShowModal] = useState(false)
  const [retries, setRetries] = useState(0)

  const radioButtonList = [
    {
      value: 'EVENTS',
      label: formatMessage(BottomNavigationMessages.eventsDescription)
    },
    {
      value: 'DIRECTORY',
      label: formatMessage(BottomNavigationMessages.directoryDescription)
    }
  ]

  const onError = () => {
    if (selectedOption) {
      revertBottomNavigationSelection()
      const buttonText = formatMessage(retries < 3 ? messages.retry : messages.contact)
      const onClick = retries < 3 ? onRetry(retries) : onContact
      const type = retries < 3 ? 'warning' : 'error'
      setNotification({ type, autoClose: 5, message: formatMessage(BottomNavigationMessages.error), show: true, onClose: onNotificationClose, buttonText, onClick })
    }
  }

  const onCompleted = () => {
    if (selectedOption) {
      setNotification({ type: 'success', autoClose: 5, message: formatMessage(BottomNavigationMessages.success), show: true, onClose: onNotificationClose })
    }
  }

  const getLoadingNotification = (loading: boolean) => {
    if (loading) {
      const loadingTitle = formatMessage(BottomNavigationMessages.loading)
      return <Notification show message={loadingTitle} autoClose={0} type='loading' />
    }
  }

  const onNotificationClose = () => setNotification(undefined)

  const [setBottomNavigationOption, { loading }] = useMutation(SET_BOTTOMNAVIGATIONOPTION, { onCompleted, onError })

  const setApplicationData = () => {
    if (!application || !application.getApplicationById) {
      return
    }
    const bottomNavigationOption = application && application.getApplicationById.bottomNavigationOption ? application.getApplicationById.bottomNavigationOption : ''
    setSelectedOption(bottomNavigationOption)
    setInitialOption(bottomNavigationOption)
  }
  const useMountEffect = (fun) => useEffect(fun, [])
  useMountEffect(setApplicationData)

  const onChange = (option: string) => {
    setSelectedOption(option)
  }

  const formattedName = (name: string) => {
    if (name === 'EVENTS') return formatMessage(BottomNavigationMessages.calendar)
    return formatMessage(BottomNavigationMessages.directory)
  }

  const getModal = () => {
    if (!showModal || !selectedOption) {
      return null
    }
    const buttonText = BottomNavigationMessages.yesIDo
    const formattedSelected = formattedName(selectedOption)
    const formattedOther = formattedName(initialOption || '')
    return (
      <ConfirmModal
        cancelAction={cancel}
        buttonAction={handleFormSubmit}
        icon='warning'
        buttonText={formatMessage(buttonText)}
        headerText={formatMessage(BottomNavigationMessages.areYouSure)}
        cancelText={formatMessage(messages.cancel)}
        modalText={formatMessage(BottomNavigationMessages.modalBody, { formattedSelected, formattedOther })}
        modalTheme={color.warning}
        modalFontColor='warning'
      />
    )
  }

  const handleFormSubmit = () => {
    const bottomNavigationOption = selectedOption
    setBottomNavigationOption({
      variables: { application: id, bottomNavigationOption },
      optimisticResponse: { _id: id, bottomNavigationOption }
    })
    setShowModal(false)
    setInitialOption(selectedOption)
  }

  const onRetry = (retries: number) => {
    if (selectedOption) {
      handleFormSubmit()
      setRetries(retries + 1)
    }
  }

  const revertBottomNavigationSelection = () => {
    setSelectedOption(initialOption)
  }

  const cancel = () => {
    revertBottomNavigationSelection()
    setShowModal(false)
  }

  const getRadioButton = (option: RadioButtonOption) => {
    const isSelected = selectedOption === option.value
    return (
      <Column key={option.value}>
        <RadioButtonContainer selected={isSelected} onClick={() => onChange(option.value)}>
          <TextContainer>
            <Text text={option.label} textSize='p' fontColor={isSelected ? color.white : color.lightBlack} align='center' />
          </TextContainer>
          <InputContainer>
            <InputLabel option={option} selectedOption={selectedOption} />
          </InputContainer>
        </RadioButtonContainer>
      </Column>
    )
  }
  const onContact = () => window.open(`${alfredUrl}/customer-care`)

  const loadingNotification = getLoadingNotification(loading)
  const notificationComponent = notification ? <Notification {...notification} /> : null
  const confirmModal = getModal()

  return (
    <div>
      {
        loadingNotification
      }
      {
        notificationComponent
      }
      {
        confirmModal
      }
      <HeaderContainer>
        <Text text={formatMessage(messages.bottomNavigationOption)} textSize='h2' align='center' fontColor={color.black} />
      </HeaderContainer>
      <MenuContainer>
        <BottomNavigationInlineBlock>
          <Row>
            {radioButtonList.map((option: RadioButtonOption) => getRadioButton(option))}
          </Row>
        </BottomNavigationInlineBlock>
      </MenuContainer>
      <Row classNames={['is-centered']}>
        <ButtonContainer>
          <Column>
            <RoundedButton onClick={() => cancel()} width='118px' theme={color.lightGrey} textTheme={color.lightGrey} disabled={false}>{formatMessage(messages.cancel)}</RoundedButton>
          </Column>
          <Column>
            <RoundedButton fill onClick={() => selectedOption !== initialOption ? setShowModal(true) : null} width='118px' theme={color.info} backgroundTheme={color.info} textTheme={color.white} disabled={false}>{formatMessage(messages.save)}</RoundedButton>
          </Column>
        </ButtonContainer>
      </Row>
    </div>
  )
}

export default injectIntl(BottomMenu)
export { BottomMenu as UnwrappedBottomMenu }
