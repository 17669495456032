import { defineMessages } from 'react-intl'

// export for tests
export const HRISMessages = defineMessages({
  enabled: {
    id: 'hrisDashboard.enabled',
    defaultMessage: 'enabled'
  },
  disabled: {
    id: 'hrisDashboard.disabled',
    defaultMessage: 'disabled'
  },
  enable: {
    id: 'hrisDashboard.enable',
    defaultMessage: 'enable'
  },
  disable: {
    id: 'hrisDashboard.disable',
    defaultMessage: 'disable'
  },
  hrisDashboardDescription: {
    id: 'hrisDashboard.description',
    defaultMessage: 'Toggle below to enable/disable the Applications a user can access. The App button(s) selected will appear under More tab in your mobile application.'
  },
  yesDisable: {
    id: 'hrisDashboard.yesDisable',
    defaultMessage: 'Yes, disable'
  },
  yesEnable: {
    id: 'hrisDashboard.yesEnable',
    defaultMessage: 'Yes, enable'
  },
  yesUpdate: {
    id: 'hrisDashboard.yesUpdate',
    defaultMessage: 'Yes, update'
  },
  areYouSure: {
    id: 'hrisDashboard.areYouSure',
    defaultMessage: 'Are you sure?'
  },
  modalBody: {
    id: 'hrisDashboard.modalBody',
    defaultMessage: 'Do you want to save the changes?'
  },
  enabling: {
    id: 'hrisDashboard.enabling',
    defaultMessage: 'Enabling'
  },
  disabling: {
    id: 'hrisDashboard.disabling',
    defaultMessage: 'Disabling'
  },
  loading: {
    id: 'hrisDashboard.loading',
    defaultMessage: 'Saving...'
  },
  success: {
    id: 'hrisDashboard.success',
    defaultMessage: 'Saved Successfully!'
  },
  error: {
    id: 'hrisDashboard.error',
    defaultMessage: 'Something went wrong. Please try again.'
  },
  active: {
    id: 'hrisDashboard.active',
    defaultMessage: 'Active'
  },
  notActive: {
    id: 'hrisDashboard.notActive',
    defaultMessage: 'Not Active'
  },
  webLink: {
    id: 'hrisDashboard.webLink',
    defaultMessage: 'Web link'
  },
  checkWeb: {
    id: 'hrisDashboard.checkWeb',
    defaultMessage: 'Please check that your urls work by clicking the links below.'
  },
  invalidUrlSyntax: {
    id: 'hrisDashboard.invalidUrlSyntax',
    defaultMessage: 'Incorrect web url syntax! Please use http:// or https://'
  }
})

export default HRISMessages
