// @flow
/* eslint-disable */
import React, { Component } from 'react'
import withRouter from '../../utils/withRouter'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { RouterHistory, Match } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ApolloClient } from '@apollo/client'
import Tabs from '../../Components/RouterTabs'
import { GetCachedApplicationByID } from '../../queries/application'
import LoginComponent from './LoginComponent'
import RegistrationComponent from './RegistrationComponent'
import ButtonsComponent from './ButtonsComponent'
import ExplorerCustomization from './ExplorerCustomization'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

type CustomizationProps = {
  match: Match,
  history: RouterHistory,
  client: ApolloClient
} & InjectIntlProvidedProps

class Customization extends Component<CustomizationProps, {}> {
  getBody () {
    const { intl: { formatMessage }, match: { params } } = this.props
    const id = this.props.match.params.id || ''
    const accountId = this.props.match.params.accountId || ''
    const application = GetCachedApplicationByID(this.props.client, id)
    const basePath = isEmpty(accountId) ? '/all-applications/application' : `/all-accounts/view/${accountId}/all-applications/application`

    /* istanbul ignore next */
    const tabListArray = [
      {
        label: formatMessage(messages.explorerCustomization),
        path: `${basePath}/${id}/customization/explorer`,
        component: <ExplorerCustomization  {...this.props} client={this.props.client} />
      },
      {
        label: formatMessage(messages.loginCustomization),
        path: `${basePath}/${id}/customization/login`,
        component: <LoginComponent {...this.props} application={application} client={this.props.client} />
      },
      {
        label: formatMessage(messages.registerCustomization),
        path: `${basePath}/${id}/customization/registration`,
        component: <RegistrationComponent {...this.props} application={application} client={this.props.client} />
      },
      {
        label: formatMessage(messages.buttonsCustomization),
        path: `${basePath}/${id}/customization/buttons`,
        component: <ButtonsComponent {...this.props} application={application} client={this.props.client} />
      }
    ]
    return { tabListArray }
  }

  render () {
    const { tabListArray } = this.getBody()

    return <Tabs tabsArray={tabListArray} />
  }
}

export default injectIntl(withRouter(Customization))
export { Customization as UnwrappedCustomization }
