// @flow

import React from 'react'
import type { Match } from 'react-router-dom'
import { applicationNavlinkArray } from '../Components/SideBars/NavigationLinks'
import Menu from '../Components/SideBars/Menu'
import withRouter from '../utils/withRouter'

type ApplicationSideBarProps = {
  isToggleSideBarOpen: boolean,
  match: Match
}

export const ApplicationSideBar = (props: ApplicationSideBarProps) => {
  const { isToggleSideBarOpen } = props
  const navLinks = applicationNavlinkArray(props.match.params.id || '', props.match.params.accountId || '')
  return <Menu navLinks={navLinks} isopen={isToggleSideBarOpen} />
}
export default withRouter(ApplicationSideBar)
