// @flow
import { ApolloLink } from '@apollo/client'
import { isFunction } from 'lodash'
// TODO: import { trackEvent } from the new analytics project

type EventType = {
  type: string,
  properties?: Object
}

const analyticsEventMapper =
{
  setHRISIntegrations: function (operation: Object) {
    const event: EventType = {
      type: 'Enable or Disable a hris system',
      properties: operation.variables
    }
    return event
  }
}

export const generateAnalyticsEvent = (operation: Object) => {
  if (isFunction(analyticsEventMapper[operation.operationName])) {
    const event = analyticsEventMapper[operation.operationName](operation)
    return event
    // TODO: trackEvent(event) once analytics project is completed
  }
}
export const analyticsMiddleware = new ApolloLink((operation, forward) => {
  // TODO: We may want to pass in the client/cache for this methog
  // so that we will have access to cached data as an object like
  // account information, access token, etc...
  generateAnalyticsEvent(operation)
  return forward(operation)
})
