// @flow

import React, { Component } from 'react'
import withRouter from '../../utils/withRouter'
import type { InjectIntlProvidedProps } from 'react-intl'
import styled from 'styled-components'
import { BoxContainer, AlertModal } from '../../Components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import theme from 'michelangelo/dist/Components/styles/theme'
import color from 'michelangelo/dist/Components/styles/color'
import Input from 'michelangelo/dist/Components/Input'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import RequirementSteps from 'michelangelo/dist/Components/RequirementSteps'
import Notification from 'michelangelo/dist/Components/Notification'
import GroupTitle from './GroupTitle'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import { isEmpty } from 'lodash'
import { GetAccountById, UpdateCache } from '../../queries/account'
import type { MutationFunction, OperationVariables } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Mutation } from '@apollo/client/react/components'
import { ApolloClient, gql } from '@apollo/client'

export const CREATE_ACCOUNT = gql`
  mutation createAccount($administratorName:String!,$administratorEmail:String!,$administratorPassword:String!,$accountName:String!, $clientSuccessEmail:String!) {
    createAccount(administratorName:$administratorName,administratorEmail:$administratorEmail,administratorPassword:$administratorPassword,accountName:$accountName,clientSuccessEmail:$clientSuccessEmail) {
      _id
      createAccountMessage {
        status
        administratorName
        administratorEmail
        administratorPassword
        accountName
        clientSuccessEmail
        accountExists
        userExists
      }
    }

  }
`
const UPDATE_ACCOUNT = gql`
  mutation updateAccount($accountId:String!, $accountName:String!, $clientSuccessEmail:String!) {
    updateAccount(accountId:$accountId,accountName:$accountName,clientSuccessEmail:$clientSuccessEmail) {
      _id
      updateAccountMessage {
        status
        accountName
        clientSuccessEmail
        accountExists
      }
    }

  }
`
const Validation = {
  UNSET: 'UNSET',
  VALID: 'VALID',
  INVALID: 'INVALID'
}
type ValidationState = 'UNSET' | 'VALID' | 'INVALID'

type CreateAccountProps = {
  params: Object,
  navigate: Object,
  client: ApolloClient
} & InjectIntlProvidedProps

type Field = {
  value: string,
  isValid: ValidationState,
  message: string
}

type CreateAccountState = {
  editing: boolean,
  accountId: string,
  administratorEmail: Field,
  administratorName: Field,
  administratorPassword: Field,
  accountName: Field,
  clientSuccessEmail: Field
}

const Title = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  font-size: 30px;

  color: #1A2732;

  display: inline-block;
  margin-top: 66px;
`

const Container = styled.div`
  width: 100%;
  text-align: center;
`
const CenterContainer = styled.div`
  display: inline-block;
`

// add for testing purpose
Title.displayName = 'Title'
Container.displayName = 'Container'
CenterContainer.displayName = 'CenterContainer'

class CreateAccount extends Component<CreateAccountProps, CreateAccountState> {
  /* istanbul ignore next */
  constructor (props: CreateAccountProps) {
    super(props)
    let accountId = ''
    let editing = false
    if (props.params.accountId) {
      editing = true
      accountId = props.params.accountId
    }
    this.state = {
      accountId,
      editing,
      administratorName: { value: '', isValid: Validation.UNSET, message: '' },
      administratorEmail: { value: '', isValid: Validation.UNSET, message: '' },
      administratorPassword: { value: '', isValid: Validation.UNSET, message: '' },
      accountName: { value: '', isValid: Validation.UNSET, message: '' },
      clientSuccessEmail: { value: '', isValid: Validation.UNSET, message: '' }
    }
  }

  /* istanbul ignore next */
  async componentDidMount () {
    const { client } = this.props
    const { editing, accountId } = this.state
    if (!editing) {
      return
    }
    const account = await GetAccountById(client, accountId)
    this.setState((prevState: CreateAccountState) => {
      prevState.accountName.value = account.name
      prevState.clientSuccessEmail.value = account.customerCareEmail
      return prevState
    })
  }

  onChange (target: string, value: string) {
    this.setState((prevState: CreateAccountState) => {
      prevState[target].value = value
      prevState[target].isValid = Validation.UNSET
      prevState[target].message = ''
      return prevState
    })
  }

  onPasswordBlur (value: string) {
    this.setState((prevState: CreateAccountState) => {
      prevState.administratorPassword.value = value
      return prevState
    })
  }

  onPasswordChange (value: string) {
    this.setState((prevState: CreateAccountState) => {
      if (prevState.administratorPassword.isValid === Validation.INVALID) {
        prevState.administratorPassword.value = value
        return prevState
      }
    })
  }

  goToAllAccounts () {
    this.props.navigate('/all-accounts')
  }

  createMutationVariables (state: CreateAccountState) {
    const { administratorEmail, clientSuccessEmail, accountName, administratorName, administratorPassword, editing, accountId } = state
    if (editing) {
      return {
        variables: {
          accountId,
          clientSuccessEmail: clientSuccessEmail.value,
          accountName: accountName.value
        }
      }
    }
    return {
      variables: {
        administratorEmail: administratorEmail.value,
        clientSuccessEmail: clientSuccessEmail.value,
        accountName: accountName.value,
        administratorName: administratorName.value,
        administratorPassword: administratorPassword.value
      }
    }
  }

  accountValidation (state: CreateAccountState): CreateAccountState {
    const { intl: { formatMessage } } = this.props
    const { clientSuccessEmail, accountName } = state
    if (isEmpty(clientSuccessEmail.value)) {
      state.clientSuccessEmail.isValid = Validation.INVALID
      state.clientSuccessEmail.message = formatMessage(messages.emptyField)
    } else if (!this.isEmail(clientSuccessEmail.value)) {
      state.clientSuccessEmail.isValid = Validation.INVALID
      state.clientSuccessEmail.message = formatMessage(messages.emailField)
    } else {
      state.clientSuccessEmail.isValid = Validation.VALID
    }
    if (isEmpty(accountName.value)) {
      state.accountName.isValid = Validation.INVALID
      state.accountName.message = formatMessage(messages.emptyField)
    } else if (accountName.value.length < 2) {
      state.accountName.isValid = Validation.INVALID
      state.accountName.message = formatMessage(messages.accountMinTwoChars)
    } else {
      state.accountName.isValid = Validation.VALID
    }
    return state
  }

  administratorValidation (state: CreateAccountState): CreateAccountState {
    const { administratorEmail, administratorName, administratorPassword } = state
    const { intl: { formatMessage } } = this.props
    if (isEmpty(administratorEmail.value)) {
      state.administratorEmail.isValid = Validation.INVALID
      state.administratorEmail.message = formatMessage(messages.emptyField)
    } else if (!this.isEmail(administratorEmail.value)) {
      state.administratorEmail.isValid = Validation.INVALID
      state.administratorEmail.message = formatMessage(messages.emailField)
    } else {
      state.administratorEmail.isValid = Validation.VALID
    }
    if (isEmpty(administratorName.value)) {
      state.administratorName.isValid = Validation.INVALID
      state.administratorName.message = formatMessage(messages.emptyField)
    } else {
      state.administratorName.isValid = Validation.VALID
    }
    if (administratorPassword.isValid === Validation.UNSET) {
      state.administratorPassword.isValid = Validation.INVALID
    }
    return state
  }

  save (saveAccount: MutationFunction<any, OperationVariables>) {
    this.setState((prevState: CreateAccountState, props: CreateAccountProps) => {
      const { editing } = prevState

      prevState = this.accountValidation(prevState)
      if (!editing) {
        prevState = this.administratorValidation(prevState)
      }
      if (this.isValid(prevState)) {
        const variables = this.createMutationVariables(prevState)
        saveAccount(variables)
      }
      return prevState
    })
  }

  isValid (state?: CreateAccountState): boolean {
    const { administratorEmail, administratorName, administratorPassword, accountName, clientSuccessEmail, editing } = state || this.state
    if (editing) {
      return accountName.isValid !== Validation.INVALID && clientSuccessEmail.isValid !== Validation.INVALID
    }
    return administratorEmail.isValid !== Validation.INVALID && administratorName.isValid !== Validation.INVALID && administratorPassword.isValid !== Validation.INVALID && accountName.isValid !== Validation.INVALID && clientSuccessEmail.isValid !== Validation.INVALID
  }

  isEmail (email: string): boolean {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return re.test(email)
  }

  getPasswordValidationMessages () {
    const { intl: { formatMessage } } = this.props
    return {
      alphaCharacter: formatMessage(messages.alphaCharacter),
      eightChars: formatMessage(messages.eightChars),
      upperCase: formatMessage(messages.upperCase),
      lowerCase: formatMessage(messages.lowerCase),
      number: formatMessage(messages.number),
      specialChar: formatMessage(messages.specialChar),
      numberCharacter: formatMessage(messages.numberCharacter),
      specialCharacter: formatMessage(messages.specialCharacter)
    }
  }

  onPasswordValidated (valid: boolean) {
    this.setState((prevState: CreateAccountState) => {
      prevState.administratorPassword.isValid = valid ? Validation.VALID : Validation.INVALID
      return prevState
    })
  }

  getInputTheme (validation: ValidationState) {
    if (validation === Validation.UNSET) {
      return theme.lightGrey
    } else if (validation === Validation.INVALID) {
      return theme.danger
    }
    return theme.success
  }

  getErrorText (text: string) {
    if (isEmpty(text)) {
      return null
    }
    return <Text textSize='p' fontColor={theme.danger} text={text} />
  }

  getInputField (name: string, serverError?: string) {
    const { intl: { formatMessage } } = this.props
    const error = isEmpty(serverError) ? this.getErrorText(this.state[name].message) : this.getErrorText(serverError)
    const isValid = isEmpty(serverError) ? this.state[name].isValid : Validation.INVALID
    const value = this.state[name].value
    return (
      <React.Fragment>
        <Input _lr-hide theme={this.getInputTheme(isValid)} type='text' value={value} onChange={(value) => this.onChange(name, value)} label={formatMessage(messages[name])} />
        {error}
      </React.Fragment>
    )
  }

  getAdministratorPasswordField (data: any) {
    const { administratorPassword } = this.state
    const passwordMessages = this.getPasswordValidationMessages()
    const { intl: { formatMessage } } = this.props
    const displayValidator = administratorPassword.isValid === Validation.INVALID || (data && data.administratorPassword)
    return (
      <React.Fragment>
        <Input _lr-hide theme={this.getInputTheme(administratorPassword.isValid)} type='password' value={administratorPassword.value} onBlur={(value) => this.onPasswordBlur(value)} onChange={(value) => this.onPasswordChange(value)} label={formatMessage(messages.administratorPassword)} />
        <RequirementSteps password={administratorPassword.value} displayValidator={displayValidator} messages={passwordMessages} onUpdate={(valid) => this.onPasswordValidated(valid)} />
      </React.Fragment>
    )
  }

  getAccountNameField (data: any) {
    const { intl: { formatMessage } } = this.props
    let error = ''
    if (data && data.accountName) {
      error = formatMessage(messages.validField)
    } else if (data && data.accountExists) {
      error = formatMessage(messages.accountNameTaken)
    }
    return this.getInputField('accountName', error)
  }

  getAdministratorEmailField (data: any) {
    const { intl: { formatMessage } } = this.props
    let error = ''
    if (data && data.administratorEmail) {
      error = formatMessage(messages.validField)
    } else if (data && data.userExists) {
      error = formatMessage(messages.emailTaken)
    }
    return this.getInputField('administratorEmail', error)
  }

  getAdministratorNameField (data: any) {
    const { intl: { formatMessage } } = this.props
    let error = ''
    if (data && data.administratorName) {
      error = formatMessage(messages.validField)
    }
    return this.getInputField('administratorName', error)
  }

  getClientSuccessEmailField (data: any) {
    const { intl: { formatMessage } } = this.props
    let error = ''
    if (data && data.clientSuccessEmail) {
      error = formatMessage(messages.validField)
    }
    return this.getInputField('clientSuccessEmail', error)
  }

  goToApplication (id: string) {
    this.props.navigate(`/all-accounts/view/${id}/all-applications`)
  }

  getModal (status: boolean, id: string) {
    if (!status) {
      return null
    }

    const { intl: { formatMessage } } = this.props
    const { editing } = this.state
    const modalText = formatMessage(editing ? messages.accountUpdateSuccess : messages.accountCreateSuccess)
    return (
      <AlertModal
        buttonAction={() => this.goToApplication(id)}
        icon='checkCircle'
        buttonText={formatMessage(messages.ok)}
        headerText={formatMessage(messages.accountCreateSuccessHeader)}
        modalText={modalText}
        modalTheme={color.success}
        modalFontColor='success'
      />
    )
  }

  getAdministratorRow (data: any) {
    const { editing } = this.state
    if (editing) {
      return null
    }
    const administratorName = this.getAdministratorNameField(data)
    const administratorEmail = this.getAdministratorEmailField(data)
    const administratorPassword = this.getAdministratorPasswordField(data)
    const { intl: { formatMessage } } = this.props

    return (
      <React.Fragment>
        <Row>
          <Column><GroupTitle number='1' title={formatMessage(messages.accountAdministratorDetails)} /></Column>
        </Row>
        <Row>
          <Column>{administratorName}</Column>
          <Column>{administratorEmail}</Column>
        </Row>
        <Row>
          <Column>{administratorPassword}</Column>
          <Column />
        </Row>
      </React.Fragment>
    )
  }

  getAccountRow (data: any) {
    const { editing } = this.state
    const accountName = this.getAccountNameField(data)
    const clientSuccessEmail = this.getClientSuccessEmailField(data)
    const { intl: { formatMessage } } = this.props

    return (
      <React.Fragment>
        <Row>
          <Column>
            <GroupTitle number={editing ? '1' : '2'} title={formatMessage(messages.accountDetails)} />
          </Column>
        </Row>
        <Row>
          <Column>{accountName}</Column>
          <Column>{clientSuccessEmail}</Column>
        </Row>
      </React.Fragment>
    )
  }

  getServerErrorMessage (error: any) {
    const { intl: { formatMessage } } = this.props
    if (!error || !error.graphQLErrors) {
      return null
    }
    let message
    if (error.graphQLErrors.length === 0) {
      message = formatMessage(messages.unexpectedError)
    } else if (error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
      message = error.graphQLErrors[0].extensions.exception.details
    } else {
      message = formatMessage(messages.unexpectedError)
    }
    return <Notification show message={message} autoClose={5} type='error' />
  }

  getLoadingNotification (loading: boolean) {
    const { intl: { formatMessage } } = this.props
    const { editing } = this.state
    const updatingTitle = formatMessage(editing ? messages.updatingAccount : messages.creatingAccount)
    if (loading) {
      return <Notification show message={updatingTitle} autoClose={0} type='loading' />
    }
    return null
  }

  getBody (saveAccount: MutationFunction<any, OperationVariables>, account: any, loading: boolean, error: any) {
    const isValid = this.isValid()
    const { intl: { formatMessage } } = this.props
    const { editing } = this.state
    let data = null
    let status = false
    let id = ''
    if (account) {
      data = editing ? account.updateAccountMessage : account.createAccountMessage
      status = editing ? account.updateAccountMessage.status : account.createAccountMessage.status
      id = account._id
    }
    const administratorRow = this.getAdministratorRow(data)
    const accountRow = this.getAccountRow(data)
    const modal = this.getModal(status, id)

    const title = formatMessage(editing ? messages.accountDetails : messages.createNewAccount)
    const save = formatMessage(editing ? messages.updateAccount : messages.saveAccount)
    const notification = this.getServerErrorMessage(error)
    const loadingNotification = this.getLoadingNotification(loading)

    return (
      <BoxContainer>
        {modal}
        {loadingNotification}
        {notification}
        <Container>
          <CenterContainer>
            <Row>
              <Column><Title>{title}</Title></Column>
            </Row>
            {administratorRow}
            {accountRow}
            <Row classNames={['is-mobile']}>
              <Column>
                <RoundedButton onClick={() => this.goToAllAccounts()} width='118px' theme={theme.danger} textTheme={theme.danger} disabled={loading}>{formatMessage(messages.cancel)}</RoundedButton>
              </Column>
              <Column>
                <RoundedButton onClick={() => this.save(saveAccount)} width='118px' theme={isValid ? theme.success : theme.lightGrey} fill disabled={!isValid || loading}>{save}</RoundedButton>
              </Column>
            </Row>
          </CenterContainer>
        </Container>
      </BoxContainer>
    )
  }

  getNewAccountMutation () {
    return (
      <Mutation mutation={CREATE_ACCOUNT}>
        {(createAccount, { loading, error, data, client }) => {
          return this.getBody(createAccount, (data) ? data.createAccount : null, loading, error)
        }}
      </Mutation>
    )
  }

  getEditAccountMutation () {
    return (
      <Mutation
        mutation={UPDATE_ACCOUNT}
        update={(cache) => UpdateCache(cache, this.state.accountId, this.state.accountName.value, this.state.clientSuccessEmail.value)}
      >
        {(updateAccount, { loading, error, data, client }) => {
          return this.getBody(updateAccount, (data) ? data.updateAccount : null, loading, error)
        }}
      </Mutation>
    )
  }

  render () {
    return this.state.editing ? this.getEditAccountMutation() : this.getNewAccountMutation()
  }
}
// const CreateAccount = (props: ) => {
//
//
// }
export default withApollo<CreateAccountProps>(withRouter(injectIntl(CreateAccount)))
export { CreateAccount as unConnectedCreateAccount }
