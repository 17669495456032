// @flow

import React from 'react'
import color from '../../styles/color'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import { isEmpty } from 'lodash'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

type HeaderNameProps = {
    name: string,
    accountTitle: string,
    applicationTitle: string
} & InjectIntlProvidedProps

const Container: ReactComponentStyled<{}> = styled.div`
  margin-top: 10px;
  @media (max-width: 414px) {
    display: none;
  }
`
const AccountNameContainer: ReactComponentStyled<{}> = styled.div`
  color: ${color.default};
  display: inline-block;
  font-size: 24px;
  font-family: Raleway;
  font-weight: 500;
  line-height: 29px;
  text-align: left;
  letter-spacing: 0;
  margin: 0px 0px 0px 16px;
  vertical-align: top;
`
const CustomerCareContainer: ReactComponentStyled<{}> = styled.div`
  display: inline-block;
  margin: 0px 0px 0px 16px;
  border-left: 1px solid #AEB5BC;
  padding-left: 30px;
  @media (max-width: 414px) {
    display: none;
  }
`
const Header: ReactComponentStyled<{}> = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #646F82;
`
const Name: ReactComponentStyled<{}> = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  font-size: 18px;
  color: #354053;
`

Container.displayName = 'Container'
AccountNameContainer.displayName = 'AccountNameContainer'
CustomerCareContainer.displayName = 'CustomerCareContainer'
Header.displayName = 'Header'
Name.displayName = 'Name'

// class HeaderName extends Component<HeaderNameProps, {}> {
//   getCustomerCareRepresentative () {
//     const { name, intl: { formatMessage } } = this.props
//     if (isEmpty(name)) {
//       return null
//     }
//     return (
//       <CustomerCareContainer>
//         <Header>{formatMessage(messages.customerCare)}</Header>
//         <Name>{name}</Name>
//       </CustomerCareContainer>
//     )
//   }
//
//   getAccountTitle () {
//     const { accountTitle, applicationTitle } = this.props
//     if (!isEmpty(applicationTitle)) {
//       return <Header>{accountTitle}</Header>
//     } else {
//       return null
//     }
//   }
//
//   getHeader () {
//     const { applicationTitle, accountTitle } = this.props
//     const title = this.getAccountTitle()
//     const name = isEmpty(applicationTitle) ? accountTitle : applicationTitle
//     return (
//       <AccountNameContainer>
//         {title}
//         {name}
//       </AccountNameContainer>
//     )
//   }
//
//   render () {
//     const customerCare = this.getCustomerCareRepresentative()
//     const header = this.getHeader()
//     return (
//       <Container>
//         {header}
//         {customerCare}
//       </Container>
//     )
//   }
// }
const HeaderName = (props: HeaderNameProps) => {
  const { applicationTitle, accountTitle, name, intl: { formatMessage } } = props
  const getAccountTitle = () => {
    if (!isEmpty(applicationTitle)) {
      return <Header>{accountTitle}</Header>
    } else {
      return null
    }
  }
  const getHeader = () => {
    const title = getAccountTitle()
    const name = isEmpty(applicationTitle) ? accountTitle : applicationTitle
    return (
        <AccountNameContainer>
          {title}
          {name}
        </AccountNameContainer>
    )
  }

  const getCustomerCareRepresentative = () => {
    if (isEmpty(name)) {
      return null
    }
    return (
        <CustomerCareContainer>
          <Header>{formatMessage(messages.customerCare)}</Header>
          <Name>{name}</Name>
        </CustomerCareContainer>
    )
  }

  const customerCare = getCustomerCareRepresentative()
  const header = getHeader()

  return (
      <Container>
        {header}
        {customerCare}
      </Container>
  )
}
export default injectIntl(HeaderName)
export { HeaderName as unConnectedHeaderName }
