// @flow

import React, { useContext } from 'react'
import { createMasterAppIntegrationsNavLink } from './NavigationLinks'
import Menu from './Menu'
import type { Match } from 'react-router-dom'
import { AppContext } from '../../contexts/app'
import { useParams } from 'react-router-dom'

type CreateAppIntegrationSideBarProps = {
  isToggleSideBarOpen: boolean,
  match: Match,
  edit?: boolean
}

const CreateMasterAppIntegrationSideBar = (props: CreateAppIntegrationSideBarProps) => {
  const { edit } = props
  const params = useParams()
  const { isToggled } = useContext(AppContext)
  const navLinks = createMasterAppIntegrationsNavLink(params.masterAppIntegrationId, edit)
  return <Menu navLinks={navLinks} isopen={isToggled} />
}
export default CreateMasterAppIntegrationSideBar
