// @flow

import React from 'react'
// import { Route } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
import type { RouterHistory, Match } from 'react-router-dom'
import HRISIntegrations from './HRISIntegrations'
import { isEmpty } from 'lodash'
import { ApolloClient } from '@apollo/client'
import Tabs from '../../Components/RouterTabs'

import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

Tabs.displayName = 'Tabs'
type HRISIntegrationsParentProps = {
  match: Match,
  history: RouterHistory,
  client: ApolloClient
} & InjectIntlProvidedProps

// class HRISIntegrationsParent extends Component<HRISIntegrationsParentProps, {}> {
//   getBody () {
//     const { intl: { formatMessage }, match: { params } } = this.props
//     const accountId = params.accountId || ''
//     const id = params.id || ''
//     const baseRoute = isEmpty(accountId) ? '/all-applications/application' : '/all-accounts/view/:accountId/all-applications/application'
//     const basePath = isEmpty(accountId) ? '/all-applications/application' : `/all-accounts/view/${accountId}/all-applications/application`
//
//     const tabListArray = [
//       {
//         label: formatMessage(messages.dashboard),
//         path: `${basePath}/${id}/app-integrations/dashboard`,
//         component: <Route path={`${baseRoute}/:id/app-integrations/dashboard`} render={(props) => <HRISIntegrations {...props} client={this.props.client} />} />
//       }]
//     return { tabListArray }
//   }
//
//   render () {
//     const { history } = this.props
//     const { tabListArray } = this.getBody()
//     return <Tabs tabsArray={tabListArray} history={history} />
//   }
// }
const HRISIntegrationsParent = (props: HRISIntegrationsParentProps) => {
  const { history, intl: { formatMessage }, match: { params }, client } = props
  const accountId = params.accountId || ''
  const id = params.id || ''
  // const baseRoute = isEmpty(accountId) ? '/all-applications/application' : '/all-accounts/view/:accountId/all-applications/application'
  const basePath = isEmpty(accountId) ? '/all-applications/application' : `/all-accounts/view/${accountId}/all-applications/application`

  const tabListArray = [
    {
      label: formatMessage(messages.dashboard),
      path: `${basePath}/${id}/app-integrations/dashboard`,
      component: <HRISIntegrations {...props} client={client} />
    }]
  return <Tabs tabsArray={tabListArray} history={history} />
}
export default injectIntl(withRouter(HRISIntegrationsParent))
export { HRISIntegrationsParent as UnwrappedHRISIntegrationsParent }
