import { defineMessages } from 'react-intl'

// export for tests
export const appIntegrationMessages = defineMessages({
  enabled: {
    id: 'appIntegration.delete',
    defaultMessage: 'enabled'
  },
  yesDelete: {
    id: 'appIntegration.yesEnable',
    defaultMessage: 'Yes, delete'
  },
  isDeleting: {
    id: 'appIntegration.isDeleting',
    defaultMessage: 'Removing...'
  },
  areYouSure: {
    id: 'appIntegration.areYouSure',
    defaultMessage: 'Are you sure?'
  },
  modalBody: {
    id: 'appIntegration.modalBody',
    defaultMessage: 'Do you want to delete this App?'
  },
  modalBodyEnabledApp: {
    id: 'appIntegration.modalBodyEnabledApp',
    defaultMessage: 'This app is enabled in some accounts. Are you sure you want to delete it?'
  }
})

export default appIntegrationMessages
