// @flow

import React, { useContext } from 'react'
import { accountNavlinkArray } from './NavigationLinks'
import Menu from './Menu'
import type { Match } from 'react-router-dom'
import { GET_AUTHENTICATED_USER } from '../../queries/getAuthenticatedUser'
import { useParams } from 'react-router-dom'
import { AppContext } from '../../contexts/app'
import { useQuery } from '@apollo/client'

type AccountSideBarProps = {
  isToggleSideBarOpen: boolean,
  match: Match
}

const AccountSideBar = (props: AccountSideBarProps) => {
  const { loading, data } = useQuery(GET_AUTHENTICATED_USER, {
    variables: {},
    fetchPolicy: 'network-only'
  })

  const { isToggled } = useContext(AppContext)
  const params = useParams()

  if (loading && !data) return
  const navLinks = accountNavlinkArray(params.accountId, data.getAuthenticatedUser.account._id)
  return <Menu navLinks={navLinks || []} isopen={isToggled} />
}
export default AccountSideBar
