// @flow

import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import AccountSideBar from './AccountSideBar'
import AllAccountsSideBar from './AllAccountsSideBar'
import HomeSideBar from './HomeSideBar'
import ReportSideBar from '../../Reports/ReportSideBar'
import ApplicationSideBar from '../../Application/ApplicationSideBar'
import CreateAccountSideBar from './CreateAccountSideBar'
import CreateMasterAppIntegrationSideBar from './CreateMasterAppIntegrationSideBar'
import SmsSettingsSidebar from './SmsSettingsSidebar'
import { AppContext } from '../../contexts/app'

// class SideBarRoutes extends Component<SideBarRoutesProps, {}> {
//   render () {
//     const isToggled = this.props.isToggled
//     /* istanbul ignore next */
//     return (
//       <div>
//         <Route path='/all-applications/application/:id' render={(props) => <ApplicationSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/permission-denied-403' render={(props) => <HomeSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/all-applications' render={(props) => <HomeSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/home' render={(props) => <AllAccountsSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/all-accounts' render={(props) => <AllAccountsSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/all-accounts/create' render={(props) => <CreateAccountSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/all-accounts/view/:accountId/edit' render={(props) => <CreateAccountSideBar {...props} isToggleSideBarOpen={isToggled} edit />} />
//         <Route exact path='/all-accounts/view/:accountId/sms-settings' render={(props) => <SmsSettingsSidebar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/all-accounts/view/:accountId/all-applications' render={(props) => <HomeSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/app-integrations' render={(props) => <HomeSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route exact path='/all-accounts/view/:accountId/all-reports' render={(props) => <ReportSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route path='/all-accounts/view/:accountId/all-applications/application/:id' render={(props) => <ApplicationSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route path='/app-integrations/create' render={(props) => <CreateMasterAppIntegrationSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route path='/app-integrations/:masterAppIntegrationId/edit' render={(props) => <CreateMasterAppIntegrationSideBar {...props} isToggleSideBarOpen={isToggled} edit />} />
//         <Route path='/all-accounts/view/:accountId/account-settings/account/' render={(props) => <AccountSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//         <Route path='/account-settings/account/' render={(props) => <AccountSideBar {...props} isToggleSideBarOpen={isToggled} />} />
//       </div>
//     )
//   }
// }
const SideBarRoutes = () => {
  const { isToggled } = useContext(AppContext)

  return (
        <Routes>
            <Route path='/all-applications/application/:id/*' element={<ApplicationSideBar isToggleSideBarOpen={isToggled} />} />
            <Route exact path='/permission-denied-403' element={<HomeSideBar isToggleSideBarOpen={isToggled} />} />
            <Route path='/all-applications' element={<HomeSideBar isToggleSideBarOpen={isToggled} />} />
            <Route exact path='/home' element={<AllAccountsSideBar isToggleSideBarOpen={isToggled} />} />
            <Route exact path='/all-accounts' element={<AllAccountsSideBar isToggleSideBarOpen={isToggled} />} />
            <Route exact path='/all-accounts/create' element={<CreateAccountSideBar isToggleSideBarOpen={isToggled} />} />
            <Route exact path='/all-accounts/view/:accountId/edit' element={<CreateAccountSideBar isToggleSideBarOpen={isToggled} edit />} />
            <Route exact path='/all-accounts/view/:accountId/sms-settings' element={<SmsSettingsSidebar isToggleSideBarOpen={isToggled} />} />
            <Route exact path='/all-accounts/view/:accountId/all-applications' element={<HomeSideBar isToggleSideBarOpen={isToggled} />} />
            <Route exact path='/app-integrations' element={<HomeSideBar isToggleSideBarOpen={isToggled} />} />
            <Route exact path='/all-accounts/view/:accountId/all-reports' element={<ReportSideBar isToggleSideBarOpen={isToggled} />} />
            <Route path='/all-accounts/view/:accountId/all-applications/application/:id/*' element={<ApplicationSideBar isToggleSideBarOpen={isToggled} />} />
            <Route path='/app-integrations/create' element={<CreateMasterAppIntegrationSideBar isToggleSideBarOpen={isToggled} />} />
            <Route path='/app-integrations/:masterAppIntegrationId/edit' element={<CreateMasterAppIntegrationSideBar isToggleSideBarOpen={isToggled} edit />} />
            <Route path='/all-accounts/view/:accountId/account-settings/account/*' element={<AccountSideBar isToggleSideBarOpen={isToggled} />} />
            <Route path='/account-settings/account/*' element={<AccountSideBar isToggleSideBarOpen={isToggled} />} />
        </Routes>
  )
}
export default SideBarRoutes
