// @flow

import React, { useEffect, useState, useContext } from 'react'
import { ApolloClient, useMutation, useQuery } from '@apollo/client'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import theme from 'michelangelo/dist/Components/styles/theme'
import type { Match, RouterHistory } from 'react-router-dom'
import {
  GET_APPLICATION_BY_ID,
  SET_EXPLORE_BANNER_OPTION,
  UpdateApplicationToCache
} from '../../../queries/application'
// import { GET_AUTHENTICATED_USER } from '../../../queries/getAuthenticatedUser'
import { messages } from '../../../i18n/messages'
import explorerMessages from './messages'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Notification from 'michelangelo/dist/Components/Notification'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import { alfredUrl } from '../../../utils/config'
import { isEmpty } from 'lodash'
import mostPopularLogo from './images/most_popular.png'
import carouselLogo from './images/carousel.png'
import noneLogo from './images/none.png'
import ExploreRadioButton from './ExploreRadioButton'
import useScreenSize from '../../../utils/useScreenSize'
import { AppContext } from '../../../contexts/app'

const ButtonContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  padding-top: 60px;
  padding-bottom: 80px;
`
ButtonContainer.displayName = 'ButtonContainer'

const HeaderContainer: ReactComponentStyled<{}> = styled.div`
    padding-top: 63px;
`
HeaderContainer.displayName = 'HeaderContainer'

const Center: ReactComponentStyled<{}> = styled.div`
  text-align: center;
`
Center.displayName = 'Center'
const InlineBlock: ReactComponentStyled<{}> = styled.div`
    display: inline-block;
    padding-top: 50px;
`
InlineBlock.displayName = 'InlineBlock'
type ExplorerCustomizationProps = {
  match: Match,
  client: ApolloClient<any>,
  history: RouterHistory
} & InjectIntlProvidedProps

type RadioButtonOption = {
  value: string,
  label: string,
  src: string
}

const options = {
  MOST_POPULAR: 'MOST_POPULAR',
  CAROUSEL: 'CAROUSEL',
  NONE: 'NONE'
}
function ExplorerCustomization ({ match: { params }, client, intl: { formatMessage }, history }: ExplorerCustomizationProps) {
  const { user } = useContext(AppContext)
  // const params = useParams()
  const onNotificationClose = () => setNotification({ ...notification, show: false })
  const onError = () => {
    const buttonText = retries < 3 ? undefined : formatMessage(messages.contact)
    const onClick = retries < 3 ? undefined : onContact
    const type = retries < 3 ? 'warning' : 'error'
    const title = formatMessage(retries < 3 ? explorerMessages.somethingWentWrong : explorerMessages.error)
    setRetries(retries + 1)
    setNotification({ type, autoClose: 5, message: title, show: true, onClose: onNotificationClose, buttonText, onClick })
  }
  const onCompleted = () => {
    setRetries(0)
    setNotification({ type: 'success', autoClose: 5, message: formatMessage(explorerMessages.saved), show: true, onClose: onNotificationClose })
  }
  const id = params.id || ''
  const { data: application } = useQuery(GET_APPLICATION_BY_ID, { variables: { _id: id }, skip: !id, fetchPolicy: 'cache-and-network' })
  // const [getAuthenticatedUser, { data: user, called: calledUser }] = useLazyQuery(GET_AUTHENTICATED_USER)
  const [exploreBannerOption, setExploreBannerOption] = useState(application && application.getApplicationById && !isEmpty(application.getApplicationById.exploreBannerOption) ? application.getApplicationById.exploreBannerOption : options.MOST_POPULAR)
  const [notification, setNotification] = useState({ type: 'success', autoClose: 5, message: '', show: false, onClose: onNotificationClose })
  const [retries, setRetries] = useState(0)
  const { width } = useScreenSize()
  const [saveExploreBannerOption, { loading }] = useMutation(SET_EXPLORE_BANNER_OPTION, {
    update: (cache) => {
      UpdateApplicationToCache(cache, { _id: id, exploreBannerOption })
    },
    onError,
    onCompleted
  })
  // useEffect(() => {
  //   if (!params.accountId) {
  //     getAuthenticatedUser()
  //   }
  //   // eslint-disable-next-line
  // }, [])

  const setApplicationData = () => {
    if (!application || !application.getApplicationById || !application.getApplicationById.exploreBannerOption || isEmpty(application.getApplicationById.exploreBannerOption)) {
      return
    }
    if (application.getApplicationById.exploreBannerOption !== exploreBannerOption) {
      setExploreBannerOption(application.getApplicationById.exploreBannerOption)
    }
  }
  // eslint-disable-next-line
  const useMountEffect = (fun) => useEffect(fun, [])
  useMountEffect(setApplicationData)

  const save = () => {
    const account = params.accountId || (user ? user.account._id : '')
    onNotificationClose()
    saveExploreBannerOption({
      variables: {
        account, application: id, exploreBannerOption
      }
    })
  }
  const onContact = () => {
    setRetries(0)
    window.open(`${alfredUrl}/customer-care`)
  }
  const radioButtonList = [
    {
      value: 'MOST_POPULAR',
      label: formatMessage(explorerMessages.mostPopular),
      src: mostPopularLogo
    },
    {
      value: 'CAROUSEL',
      label: formatMessage(explorerMessages.carousel),
      src: carouselLogo
    },
    {
      value: 'NONE',
      label: formatMessage(explorerMessages.none),
      src: noneLogo
    }
  ]
  const onChange = (option: string) => {
    setExploreBannerOption(option)
  }
  const getRadioButton = (option: RadioButtonOption) => {
    return <ExploreRadioButton selectedOption={exploreBannerOption} onChange={onChange} option={option} key={option.value} />
  }
  const radioButtonClassNames = (width < 800 && width > 614) ? ['is-mobile'] : []
  return (
    <div>
      <Notification show={loading} message={formatMessage(explorerMessages.saving)} autoClose={0} type='loading' />
      <Notification {...notification} />
      <Row classNames={['is-centered']}>
        <Column>
          <HeaderContainer>
            <Text text={formatMessage(explorerMessages.header)} textSize='h2' align='center' fontColor={color.lightBlack} />
          </HeaderContainer>
        </Column>
      </Row>
      <Row classNames={['is-centered']}>
        <Column>
          <Text fontColor={color.darkGray} align='center' textSize='p' text={formatMessage(explorerMessages.description)} />
        </Column>
      </Row>
      <Row classNames={['is-centered']}>
        <Center>
          <InlineBlock>
            <Row classNames={radioButtonClassNames}>
              {radioButtonList.map((option: RadioButtonOption) => getRadioButton(option))}
            </Row>
          </InlineBlock>
        </Center>
      </Row>
      <Row classNames={['is-centered']}>
        <ButtonContainer>
          <Column>
            <RoundedButton onClick={setApplicationData} width='118px' theme={theme.disabled} textTheme={theme.disabled} disabled={false}>{formatMessage(messages.cancel)}</RoundedButton>
          </Column>
          <Column>
            <RoundedButton onClick={save} width='118px' theme={theme.info} fill>{formatMessage(messages.save)}</RoundedButton>
          </Column>
        </ButtonContainer>
      </Row>
    </div>
  )
}

export default injectIntl(ExplorerCustomization)
export { ExplorerCustomization as UnwrappedExplorerCustomization }
