// @flow

import React from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import Column from 'michelangelo/dist/Components/Column'

type BoxProps = {
  color: string,
  cursor: string,
  onClick?: Function,
  label: {},
}

/* istanbul ignore next */
const BoxContainer: ReactComponentStyled<{}> = styled.div`
    width: 364px;
    height: 364px;
    background: ${props => props.color};
    cursor: ${props => props.cursor};
    position: relative;
    box-shadow: 1px 2px 4px -1px rgba(0,0,0,0.50);
    vertical-align: middle;
    display: table-cell;

    @media (max-width: 375px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 320px) {
      width: 270px;
      height: 270px;
    }
`
BoxContainer.displayName = 'BoxContainer'

const TextContainer: ReactComponentStyled<{}> = styled.div`
    margin-top: 25%;
    margin-bottom: 25%;
    top: 0;
`
TextContainer.displayName = 'TextContainer'

const Box = (props: BoxProps) => {
  const { color, cursor, label, onClick } = props

  return (
      <div>
        <BoxContainer onClick={onClick} color={color} cursor={cursor}>
          <Column>
            <TextContainer>
              <Column classNames={['is-8', 'is-offset-2', 'is-centered', 'is-paddingless']}>
                {label}
              </Column>
            </TextContainer>
          </Column>
        </BoxContainer>
      </div>
  )
}
export default Box
