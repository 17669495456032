// @flow

import React from 'react'
import { createSmsSettingsNavLink } from './NavigationLinks'
import Menu from './Menu'
import type { Match } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
// import {AppContext} from "../../contexts/app";

type SmsSettingsSideBarProps = {
  isToggleSideBarOpen: boolean,
  match: Match,
  edit?: boolean
}

const SmsSettingsSidebar = (props: SmsSettingsSideBarProps) => {
  const { match, edit, isToggleSideBarOpen } = props
  const navLinks = createSmsSettingsNavLink(match.params.accountId || '', edit)
  return <Menu navLinks={navLinks} isopen={isToggleSideBarOpen} />
}
export default withRouter(SmsSettingsSidebar)
