// @flow

import * as React from 'react'
import styled from 'styled-components'

import color from 'michelangelo/dist/Components/styles/color'
import theme from 'michelangelo/dist/Components/styles/theme'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'

const Container: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
    background: rgba(51,51,51,0.65);
`
/* istanbul ignore next */
const ParentContainer: ReactComponentStyled<{}> = styled.div`
    z-index: 9;
    vertical-align: middle;
    background-color: ${color.white};
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @media (max-width: 600px) {
      width: 350px;
    }
    @media (max-width: 360px) {
      width: 300px;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
`
const TopBox: ReactComponentStyled<{}> = styled.div`
    width: 550px;
    height: auto;
    padding: 20px 10px;
    @media (max-width: 600px) {
      width: 350px;
    }
    @media (max-width: 360px) {
      width: 300px;
    }
`

const BottomBox: ReactComponentStyled<{}> = styled.div`
    border-top: 1px solid #D6D6D6;
    background-color: #F9F9FB;
    // width: 550px;
    height: auto;
    padding: 20px 10px;
    @media (max-width: 600px) {
      width: 350px;
    }
    @media (max-width: 360px) {
      width: 300px;
    }
`
const FooterContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    display: inline-block;
`

Container.displayName = 'Container'
ParentContainer.displayName = 'ParentContainer'
TopBox.displayName = 'TopBox'
BottomBox.displayName = 'BottomBox'
FooterContainer.displayName = 'FooterContainer'

type ModalProps = {
  buttonAction?: () => void,
  cancelAction?: () => void,
  buttonText?: string,
  cancelText?: string,
  header?: Node,
  body?: Node,
  footer?: Node,
  disabled?: boolean
}

type ModalState = {
  active: boolean
}

class Modal extends React.Component<ModalProps, ModalState> {
  constructor (props: ModalProps) {
    super(props)
    this.state = {
      active: true
    }
  }

  cancel () {
    const { cancelAction } = this.props
    if (cancelAction) {
      cancelAction()
    }
  }

  action () {
    const { buttonAction } = this.props
    if (buttonAction) {
      buttonAction()
    }
  }

  getFooter () {
    const { footer, cancelText, buttonText } = this.props
    const disabled = this.props.disabled || false
    if (footer) {
      return footer
    }
    return (
      <Row classNames={['is-mobile']}>
        <Column>
          <RoundedButton onClick={() => this.cancel()} width='118px' theme={theme.danger} textTheme={theme.danger} backgroundTheme='#F9F9FB'>{cancelText}</RoundedButton>
        </Column>
        <Column>
          <RoundedButton onClick={() => this.action()} width='118px' theme={disabled ? theme.lightGrey : theme.success} disabled={disabled} fill>{buttonText}</RoundedButton>
        </Column>
      </Row>
    )
  }

  render () {
    const header = this.props.header ? this.props.header : null
    const body = this.props.body ? this.props.body : null
    const footer = this.getFooter()

    return (
      <Container>
        <div className={'modal-container ' + (this.state.active ? 'active' : '')}>
          <ParentContainer>
            <TopBox>
              {header}
            </TopBox>
            {body}
            <BottomBox>
              <FooterContainer>
                {footer}
              </FooterContainer>
            </BottomBox>
          </ParentContainer>
        </div>
      </Container>
    )
  }
}

export default Modal
