// @flow

import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import withRouter from '../utils/withRouter'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { RouterHistory, Match } from 'react-router-dom'
import HRISIntegrations from './HRISIntegrations'
import Customization from './Customization'
import MobileSettings from './MobileSettings'
import Details from './Details'
import NewBuild from './NewAppBuild'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { isEmpty } from 'lodash'
type ApplicationProps = {
  match?: Match,
  history?: RouterHistory,
  client?: ApolloClient
} & InjectIntlProvidedProps

const Application = (props: ApplicationProps) => {
  const client = useApolloClient()
  const params = useParams()
  const accountId = params.accountId || ''
  const baseRoute = isEmpty(accountId) ? '/all-applications/application' : '/all-accounts/view/:accountId/all-applications/application'

  /* istanbul ignore next */
  return (
      <>
        <Routes>
            <Route path={`${baseRoute}/:id/app-integrations`} element={<HRISIntegrations {...props} client={client} />} />
            <Route path={`${baseRoute}/:id/customization`} element={<Customization {...props} client={client} />} />
            <Route path={`${baseRoute}/:id/AppBuild`} element={<Details {...props} client={client} />} />
            <Route path={`${baseRoute}/:id/build`} element={<NewBuild {...props} client={client} />} />
            <Route path={`${baseRoute}/:id/Mobile`} element={<MobileSettings {...props} client={client} />} />
        </Routes>
      </>
  )
}

export default withRouter(Application)
export { Application as UnwrappedApplication }
