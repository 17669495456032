// @flow
/* eslint-disable object-shorthand */

import React, { useEffect, useState } from 'react'
import Button from './Components/Button'
import type { InjectIntlProvidedProps } from 'react-intl'
// $FlowFixMe
import type { StyledComponentType } from 'styled-components'
import withRouter from './utils/withRouter'
import ButtonTwo from 'michelangelo/dist/Components/Buttons/Button'
import Row from 'michelangelo/dist/Components/Row'
import Table from './Components/Table'
import SearchBar from 'michelangelo/dist/Components/SearchBar'
import Paginator from 'michelangelo/dist/Components/Paginator'
import Notification from 'michelangelo/dist/Components/Notification'
import Column from 'michelangelo/dist/Components/Column'
import theme from 'michelangelo/dist/Components/styles/theme'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { messages } from './i18n/messages'
import { GET_ALL_ACCOUNTS_DATA } from './queries/getAllAccountData'
import { useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

type AllAccountsProps = {
  history?: RouterHistory
} & InjectIntlProvidedProps

const Container: StyledComponentType<{}> = styled.div`
  width: 100%;
`
const TableContainer: StyledComponentType<{}> = styled.div`
  width: 100%;
`
const ButtonContainer: StyledComponentType<{}> = styled.div`
  float: right;
`
const SearchAndButtonContainer: StyledComponentType<{}> = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
`
const WhiteContainer: StyledComponentType<{}> = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
`

const InlineDiv: StyledComponentType<{}> = styled.div`
  display: inline-block;
`
const Center: StyledComponentType<{}> = styled.div`
  text-align: center;
`

// add for testing purpose
Container.displayName = 'Container'
ButtonContainer.displayName = 'ButtonContainer'
SearchAndButtonContainer.displayName = 'SearchAndButtonContainer'
WhiteContainer.displayName = 'WhiteContainer'
InlineDiv.displayName = 'InlineDiv'
Center.displayName = 'Center'
Table.displayName = 'Table'

let tableData = {
  columns: [],
  isSortable: [],
  sorted: [],
  dataAlignment: [],
  rows: []
}

const AllAccounts = ({ history, intl: { formatMessage } }: AllAccountsProps) => {
  const navigate = useNavigate()
  const [sortField, setSortField] = useState('accountName')
  const [sortDirection, setSortDirection] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [skip, setSkip] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [allData, setAllData] = useState([])
  // GET_ALL_ACCOUNTS_DATA
  const [getAllAccountsData, { loading, data: allAccountsData }] = useLazyQuery(GET_ALL_ACCOUNTS_DATA, {
    variables: { limit: limit, offset: skip, sortField: sortField, sortDirection: sortDirection, search: search },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    getAllAccountsData()
  }, [getAllAccountsData, search, sortField, sortDirection, limit, skip])

  useEffect(() => {
    if (allAccountsData && allAccountsData.getAllAccountsTableData && allAccountsData.getAllAccountsTableData.accountTableDataMessage) {
      const accounts = allAccountsData.getAllAccountsTableData.accountTableDataMessage
      setAllData(accounts)
      const countData = allAccountsData.getAllAccountsTableData.count
      if (search !== '' && count !== Math.ceil(accounts.length / 10) + skip / 10) {
        setCount(Math.ceil(accounts.length / 10) + skip / 10)
      } else if (count === 0 && search === '') {
        setCount(Math.ceil(countData / 10))
      }
    }
    // eslint-disable-next-line
  }, [allAccountsData])

  const loadingTitle = formatMessage(messages.gettingAccountTableData)

  const goToCreateAccount = (e: any) => {
    e.preventDefault()
    navigate('/all-accounts/create')
  }

  const handleOnSearchChange = (searchValue: string) => {
    if (searchValue.length > 1) {
      setSearch(searchValue)
      setSortField('accountName')
      setPageNumber(1)
      setLimit(0)
    }
    if (searchValue.length === 0) {
      setSearch('')
      setSortField('accountName')
      setPageNumber(1)
      setLimit(10)
      setCount(10)
    }
  }
  const handleOnHeaderClick = (columnName: string) => {
    let sortFieldValue = ''
    let sortedId = 0
    let sortDirectionValue = null
    switch (columnName) {
      case tableData.columns[0]:
        sortedId = 0
        sortFieldValue = 'accountName'
        break
      case tableData.columns[1]:
        sortedId = 1
        sortFieldValue = 'totalUsers'
        break
      case tableData.columns[2]:
        sortedId = 2
        sortFieldValue = 'totalMembers'
        break
      case tableData.columns[3]:
        sortedId = 3
        sortFieldValue = 'contentCreated'
        break
      default:
        sortedId = 0
        sortFieldValue = 'accountName'
        break
    }
    if (tableData.sorted[sortedId] === 0) {
      sortDirectionValue = -1
    } else {
      sortDirectionValue = tableData.sorted[sortedId] * -1
    }
    tableData.sorted.fill(0)
    tableData.sorted[sortedId] = sortDirectionValue
    setSearch('')
    setSortField(sortFieldValue)
    setSortDirection(sortDirectionValue)
    setLimit(10)
  }

  const setPage = (pageNumber: number) => {
    setPageNumber(pageNumber)
    setSkip((pageNumber - 1) * 10)
  }

  const onActionClick = (accountId: string) => {
    navigate(`/all-accounts/view/${accountId}/edit`)
  }

  const getLoadingNotification = (loading: boolean) => {
    if (loading) {
      return <Notification show message={loadingTitle} autoClose={0} type='loading' />
    }
    return null
  }

  // const getBody = (getAllAccountsTableData: any, loading: boolean) => {
  //   if (getAllAccountsTableData && getAllAccountsTableData.getAllAccountsTableData) {
  //     const accounts = getAllAccountsTableData.getAllAccountsTableData.accountTableDataMessage
  //     let rows = []
  //     rows = accounts.map(({ _id, name, clientSuccessEmail, totalUsers, totalMembers, contentLastUploaded }, index) => {
  //       return {
  //         _id: _id,
  //         [formatMessage(messages.accountName)]: name,
  //         [formatMessage(messages.clientSuccessEmail)]: clientSuccessEmail,
  //         [formatMessage(messages.totalUsers)]: totalUsers,
  //         [formatMessage(messages.totalMembers)]: totalMembers,
  //         [formatMessage(messages.contentUploaded)]: contentLastUploaded,
  //         [formatMessage(messages.actions)]: <InlineDiv><ButtonTwo key={_id} padding='7px' size='xs' outline icon='pen' onClick={(e) => onActionClick(_id)} /></InlineDiv>
  //       }
  //     })
  //     if (rows.length > 10) rows.length = 10
  //     tableData.rows = rows
  //     const table = <Table onHeaderClick={(name) => handleOnHeaderClick(name)} tableData={tableData} />
  //     return (
  //         <TableContainer>
  //           {table}
  //           {loadingNotification}
  //         </TableContainer>
  //     )
  //   } else return null
  // }
  const rows = allData.map(({ _id, name, clientSuccessEmail, totalUsers, totalMembers, contentLastUploaded }, index) => {
    return {
      _id: _id,
      [formatMessage(messages.accountName)]: name,
      [formatMessage(messages.clientSuccessEmail)]: clientSuccessEmail,
      [formatMessage(messages.totalUsers)]: totalUsers,
      [formatMessage(messages.totalMembers)]: totalMembers,
      [formatMessage(messages.contentUploaded)]: contentLastUploaded,
      [formatMessage(messages.actions)]: <InlineDiv><ButtonTwo testID='AccountEditButton' key={_id} padding='7px' size='xs' outline icon='pen' onClick={(e) => onActionClick(_id)} /></InlineDiv>
    }
  })
  if (rows.length > 10) rows.length = 10
  tableData.rows = rows
  // If condition so table data is defined only once on initial load
  if (tableData.columns.length === 0) {
    tableData = {
      columns: [formatMessage(messages.accountName), formatMessage(messages.totalUsers), formatMessage(messages.totalMembers), formatMessage(messages.contentUploaded), formatMessage(messages.clientSuccessEmail), formatMessage(messages.actions)],
      dataAlignment: ['left', 'left', 'left', 'left', 'left', 'left'],
      isSortable: [true, true, true, true, false, false],
      sorted: [1, 0, 0, 0, 0, 0],
      rows: []
    }
  }

  const loadingNotification = getLoadingNotification(loading)
  const paginator = (
      <Center>
        <InlineDiv>
          <Paginator
              previousLabel={formatMessage(messages.prev)}
              nextLabel={formatMessage(messages.next)} breakLabel='...'
              pageCount={count}
              pageRangeDisplayed={5}
              initialPage={1}
              selected={pageNumber}
              onPageChange={(page) => setPage(page)}
              theme={theme.info}
          />
        </InlineDiv>
      </Center>
  )
  return (
      <Container>
        <Row classNames={['is-marginless', 'is-centered']}>
          <SearchAndButtonContainer>
            <Column classNames={['is-4', 'is-paddingless']}>
              <SearchBar
                  testID='AccountsSearchInput'
                  placeholder={formatMessage(messages.search)}
                  onChange={(val) => handleOnSearchChange(val)}
              />
            </Column>
            <Column classNames={['is-8', 'is-paddingless']}>
              <ButtonContainer testID='ButtonContainer'>
                <Button disabled={false} kind='logout' type='button' onClick={(e) => goToCreateAccount(e)}>{formatMessage(messages.createNewAccount)}</Button>
              </ButtonContainer>
            </Column>
          </SearchAndButtonContainer>
        </Row>
        <WhiteContainer>
          <TableContainer>
            { tableData && <Table onHeaderClick={(name) => handleOnHeaderClick(name)} tableData={tableData} /> }
            {loadingNotification}
          </TableContainer>
          <Row classNames={['is-marginless', 'is-centered']}>
            <Column classNames={['is-8']}>
              {paginator}
            </Column>
          </Row>
        </WhiteContainer>
      </Container>
  )
}

export default withRouter(injectIntl(AllAccounts))
export { AllAccounts as unConnectedAllAccounts }
