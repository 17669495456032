// @flow

import React from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'

const ToggleContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
`
const Label: ReactComponentStyled<{}> = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  width: auto;
  height: 34px;
  vertical-align: middle;
`
/* istanbul ignore next */
const ToggleSwitch: ReactComponentStyled<{}> = styled.div`
  margin-left: 10px;
  background-color: ${(props) => props.toggleOn ? `${color.success}` : `${color.danger}`};
  color: ${color.darkestGray};
  opacity: ${(props) => props.opacity};
  position: relative;
  width: 60px;
  height: 34px;
  border-radius: 34px;
  transition: background-color 300ms ease-out;
  z-index: 1;
  cursor: pointer;
  &:before, &:after {
    content: "";
    top: 0;
    position: absolute;
    background-color: inherit;
    border-radius: 50%;
    z-index: 2;
  }
`
/* istanbul ignore next */
const ToggleButton: ReactComponentStyled<{}> = styled.div`
  position: absolute;
  width: 26px;
  height: 26px;

  background-color: ${color.white};
  border-radius: 50%;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  z-index: 3;
  border: 0.05px solid ${color.white};
  top: .25rem;

  &.toggleOff {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  &.toggleOn {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
`
const Input: ReactComponentStyled<{}> = styled.input`
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  width: 0;
  height: 0;
`
/* istanbul ignore next */
const TextContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  align-items: center;
`
type ToggleSwitchProps = {
  isToggled: boolean,
  onToggleChange: (isToggled: boolean) => void,
  label?: string,
  disabled?: boolean
}
// type ToggleSwitchStates = {
//   isToggled: boolean
// }

// For testing purpose added this
Label.displayName = 'Label'
Input.displayName = 'Input'
ToggleSwitch.displayName = 'ToggleSwitch'
ToggleButton.displayName = 'ToggleButton'

// class Toggle extends Component<ToggleSwitchProps, ToggleSwitchStates> {
//   constructor (props: ToggleSwitchProps) {
//     super(props)
//     this.state = {
//       isToggled: props.isToggled
//     }
//   }
//
//   handleOnClick = (e: SyntheticEvent<HTMLInputElement>) => {
//     e.preventDefault()
//     if (this.props.isToggled === true) {
//       this.setState({ isToggled: false })
//     } else {
//       this.setState({ isToggled: true })
//     }
//     const isToggledOpen = this.props.onToggleChange(this.props.isToggled)
//     return isToggledOpen
//   }
//
//   render () {
//     const { isToggled, disabled } = this.props
//     const { label } = this.props
//     const classNames = isToggled ? 'toggleOn' : 'toggleOff'
//     const opacity = disabled ? 0.58 : 1
//     return (
//       <ToggleContainer>
//         <TextContainer>
//           <Text text={label} fontColor={color.lightTextDefault} textSize='p' />
//         </TextContainer>
//         <Label>
//           <Input type='checkbox' toggleOn={isToggled} onClick={this.handleOnClick} disabled={disabled} />
//           <ToggleSwitch className='switch' toggleOn={isToggled} opacity={opacity}>
//             <ToggleButton className={classNames} />
//           </ToggleSwitch>
//         </Label>
//       </ToggleContainer>
//     )
//   }
// }
const Toggle = (props: ToggleSwitchProps) => {
  const { onToggleChange, label, isToggled, disabled } = props

  const handleOnClick = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault()
    onToggleChange(isToggled)
  }

  const classNames = isToggled ? 'toggleOn' : 'toggleOff'
  const opacity = disabled ? 0.58 : 1
  return (
      <ToggleContainer>
        <TextContainer>
          <Text text={label} fontColor={color.lightTextDefault} textSize='p' />
        </TextContainer>
        <Label>
          <Input type='checkbox' toggleOn={isToggled} onClick={handleOnClick} disabled={disabled} />
          <ToggleSwitch className='switch' toggleOn={isToggled} opacity={opacity}>
            <ToggleButton className={classNames} />
          </ToggleSwitch>
        </Label>
      </ToggleContainer>
  )
}
export default Toggle
