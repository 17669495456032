// @flow

import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import withRouter from '../utils/withRouter'
import { BoxContainer } from '.'
import styled from 'styled-components'
import Column from 'michelangelo/dist/Components/Column'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import { useQuery } from '@apollo/client'
import { GET_APPLICATION_BY_ID } from '../queries/application'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { Match } from 'react-router-dom'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
type CustomizationProps = {
  match: Match,
  routes: any,
  tabList: Array<Object>,
  pathName: string
} & InjectIntlProvidedProps

const NavContainer = styled(NavLink)`
    width: 125px;
    height: 60px;
    background-color: ${(props) => props.selected ? `${color.white}` : `${color.info}`};
    cursor: pointer;
    list-style: none;
    padding: 5px;
    display: table-cell;
    vertical-align: middle;
    @media (max-width: 780px) {
      display: none;
    }
`
const TabContainer: ReactComponentStyled<{}> = styled.div`
    padding: 5px;
    @media (max-width: 780px) {
      display: none;
    }
`

const Container: ReactComponentStyled<{}> = styled.div`
    padding: 50px 20px 0px 20px;
    width: auto;
    display: block;
    @media (max-width: 780px) {
      display: none;
    }
`
const List: ReactComponentStyled<{}> = styled.ul`
    display: table-row;
    margin-right: 10px;
`
List.displayName = 'List'
const AppNameContainer: ReactComponentStyled<{}> = styled.div`
    display: none;
    @media (max-width: 780px) {
      display: block;
      width: auto;
      margin: 0px 20px 10px 20px;
    }
`
const BoxParent: ReactComponentStyled<{}> = styled.div`
    margin-bottom: 50px;
`

const TabsManager = (props: CustomizationProps) => {
  const { tabList, routes, pathName } = props
  const { id } = useParams()

  const { loading, data } = useQuery(GET_APPLICATION_BY_ID, { variables: { _id: id }, skip: !id, fetchPolicy: 'cache-and-network' })
  if (tabList && !loading && data && data.getApplicationById) {
    const application = data.getApplicationById
    return (
        <Column classNames={['is-paddingless', 'is-full']}>
          <Column classNames={['is-paddingless']}>
            <Container>
              <Column classNames={['is-paddingless', 'is-vcentered']}>
                <List>
                  {tabList.map(({ id, label, path, route }) =>
                      <NavContainer key={id} to={route} selected={path === pathName}>
                        <TabContainer>
                          <Text align='center' textSize='p' fontColor={path === pathName ? color.info : color.white} text={label} />
                        </TabContainer>
                      </NavContainer>
                  )}
                </List>
              </Column>
            </Container>
          </Column>
          <Column classNames={['is-paddingless', 'is-full']}>
            <AppNameContainer><Text align='left' textSize='h3' fontColor={color.info} text={application.title} /></AppNameContainer>
            <BoxParent>
              <BoxContainer>
                {routes}
              </BoxContainer>
            </BoxParent>
          </Column>
        </Column>
    )
  } else {
    return null
  }
}
export default withRouter(TabsManager)
export { TabsManager as Tabs }
