// @flow

import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import type { ApolloClient } from '@apollo/client'

export const GET_AUTHENTICATED_USER = gql`
   query getAuthenticatedUser {
    getAuthenticatedUser {
      user {
        _id
        name
        email
        role
        provider
        memberships
        disabled
        verified
        changePasswordRequired
        locked
        adminCommentPushes
        failedLoginAttempts
      }
      account {
        _id
        name
        subDomain
        applications
        userAnalyticsEnabled
      }
    }
  }
`

type GetApplicationProps = {
    children: Function,
    toggleSideBar: (boolean) => boolean,
  }

export class GetAuthenticatedUserQuery extends Component<GetApplicationProps, {}> {
  render () {
    return (
      <Query
        query={GET_AUTHENTICATED_USER}
        notifyOnNetworkStatusChange
        displayName='Application'
      >
        {({ loading, error, data, refetch, networkStatus, client }) => {
          if (!(data && data.getAuthenticatedUser)) return null

          return (
            <div>
              {this.props.children(data.getAuthenticatedUser, client, this.props.toggleSideBar)}
            </div>
          )
        }}
      </Query>
    )
  }
}
export const GetAuthenticatedUser = async (client: ApolloClient) => {
  const { data } = await client.query({ query: GET_AUTHENTICATED_USER })
  return data.getAuthenticatedUser
}
