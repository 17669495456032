// @flow

import React, { useEffect, useState } from 'react'
import color from './styles/color'
import Box from './Components/ApplicationBox'
import styled from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import { Link, useLocation, useParams } from 'react-router-dom'
import withRouter from './utils/withRouter'
import Logo from './Components/ApplicationLogo'
import Column from 'michelangelo/dist/Components/Column'
import Row from 'michelangelo/dist/Components/Row'
import { GET_APPLICATION_BY_IDS } from './queries/application'
import { GET_ACCOUNT_BY_ID } from './queries/account'
import { isEmpty } from 'lodash'
import { messages } from './i18n/messages'
import { injectIntl } from 'react-intl'
import Create from './Application/Create'
import type { ApolloClient } from '@apollo/client'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import LoadingScreen from './LoadingScreen'

const ApplicationContainer: ReactComponentStyled<{}> = styled.div`
    width: 364px;
    height: 364px;
    @media (max-width: 375px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 320px) {
      width: 270px;
      height: 270px;
    }
`
ApplicationContainer.displayName = 'ApplicationContainer'
type AllApplicationProps = {
  applications: any,
  location: Object,
  params: Object,
  client: ApolloClient<any>
} & InjectIntlProvidedProps

const AllApplications = (props: AllApplicationProps) => {
  const client = useApolloClient()
  const { intl: { formatMessage }, create: propCreate, applications } = props
  const location = useLocation()
  const params = useParams()
  const [create, setCreate] = useState(false)
  let appsIds = applications || []
  if (!applications && location.state && location.state.applications) {
    appsIds = location.state.applications
  }
  const [ids, setIds] = useState(appsIds)
  const [apps, setApps] = useState([])
  let accountId
  if (params && params.accountId) {
    accountId = params.accountId
  }
  const { loading: loadingAccount, data: account } = useQuery(GET_ACCOUNT_BY_ID, {
    variables: { account: accountId },
    skip: !accountId,
    fetchPolicy: 'cache-and-network'
  })
  const [getApplications, { loading, data: appsData }] = useLazyQuery(GET_APPLICATION_BY_IDS, {
    variables: {
      ids
    },
    skip: !!ids.length,
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (account && account.getAccountById && account.getAccountById.applications) {
      setIds(account.getAccountById.applications)
    }
    // eslint-disable-next-line
  }, [loadingAccount, account])

  useEffect(() => {
    if (ids && ids.length) {
      getApplications()
    }
    // eslint-disable-next-line
  }, [ids])

  useEffect(() => {
    if (!loading && appsData && appsData.getApplicationByIds) {
      setApps(appsData.getApplicationByIds)
    }
    // eslint-disable-next-line
  }, [appsData, loading])

  if (loading) return <LoadingScreen customHeight={'100px'} />

  const getBody = (app: any) => {
    const pathName = isEmpty(accountId) ? `/all-applications/application/${app._id}/mobile/security` : `/all-accounts/view/${accountId}/all-applications/application/${app._id}/mobile/security`
    return (
        <Link to={pathName}>
          <Box label={<Logo applicationId={app._id} width={200} applicationName={app.title} />} cursor='pointer' width='364px' height='364px' color={color.white} editIcon />
        </Link>
    )
  }
  const getNewApplicationButton = () => {
    if (!propCreate) {
      return null
    }
    const text = formatMessage(messages.addNewApplication)
    return (
        <Column classNames={['app-box--item', 'is-narrow']}>
          <ApplicationContainer>
            <div>
              <Box
                  label={<Logo applicationId='' width={200} applicationName={text} icon='add' logoColor={color.info} />}
                  cursor='pointer'
                  width='364px'
                  height='364px'
                  color={color.white}
                  editIcon={false}
                  onClick={() => setCreate(true)}
              />
            </div>
          </ApplicationContainer>
        </Column>
    )
  }

  const generateApplications = (applications: Array<any>) => {
    if (!applications) {
      return null
    }
    const applicationBoxes: Array<any> = applications.map((application: string) =>
        <Column key={application._id} classNames={['app-box--item', 'is-narrow']}>
          <ApplicationContainer>
            {getBody(application)}
          </ApplicationContainer>
        </Column>
    )
    return applicationBoxes
  }

  const newApplication = getNewApplicationButton()
  return (
      <Row classNames={['is-multiline', 'is-marginless', 'is-mobile']}>
        {generateApplications(apps)}
        {newApplication}
        {create && <Create client={client} onCancel={() => setCreate(false)} />}
      </Row>
  )
}
export default injectIntl(withRouter(AllApplications))
export { AllApplications as UnwrappedAllApplications }
