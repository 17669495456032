// @flow
/*eslint-disable*/
import React, { useEffect } from 'react'
import { Routes, Route, useParams, useLocation } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
import type { RouterHistory, Match } from 'react-router-dom'
import styled from 'styled-components'
import MobileLock from './MobileLock'
import BottomMenu from './BottomMenu'
import { isEmpty, split } from 'lodash'
import { ApolloClient } from '@apollo/client'
import Tabs from '../../Components/RouterTabs'

import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

Tabs.displayName = 'Tabs'
type MobileSettingsProps = {
  match: Match,
  history: RouterHistory,
  client: ApolloClient
} & InjectIntlProvidedProps

const MobileSettings = (props: MobileSettingsProps) => {
  const { intl: { formatMessage }, client } = props
  const getBody = () => {
    const { intl: { formatMessage }, match: { params } } = props
    const accountId = params.accountId || ''
    const id = params.id || ''
    const basePath = isEmpty(accountId) ? '/all-applications/application' : `/all-accounts/view/${accountId}/all-applications/application`

    const tabListArray = [
      {
        label: formatMessage(messages.mobileLock),
        path: `${basePath}/${id}/mobile/security`,
        component: <MobileLock {...props} client={props.client} />
      }, {
        label: formatMessage(messages.bottomMenu),
        path: `${basePath}/${id}/mobile/bottom-menu`,
        component: <BottomMenu {...props} client={props.client} />
      }]
    return { tabListArray }
  }

  const { history } = props
  const { tabListArray } = getBody()
  return (<Tabs tabsArray={tabListArray} history={history} />)
}
export default injectIntl(withRouter(MobileSettings))
export { MobileSettings as UnwrappedMobileSettings }
