// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import Column from 'michelangelo/dist/Components/Column'
import Icon from 'michelangelo/dist/Components/Icon'
import color from 'michelangelo/dist/Components/styles/color'

type BoxProps = {
  color: string,
  cursor: string,
  onClick?: Function,
  label: {},
  editIcon: boolean
}

/* istanbul ignore next */
const BoxContainer: ReactComponentStyled<{}> = styled.div`
    width: 364px;
    height: 364px;
    background: ${props => props.color};
    cursor: ${props => props.cursor};
    position: relative;
    box-shadow: 1px 2px 4px -1px rgba(0,0,0,0.50);
    vertical-align: middle;
    display: table-cell;

    @media (max-width: 375px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 320px) {
      width: 270px;
      height: 270px;
    }
`
BoxContainer.displayName = 'BoxContainer'

const TextContainer: ReactComponentStyled<{}> = styled.div`
    margin-top: 25%;
    margin-bottom: 25%;
    top: 0;
`
TextContainer.displayName = 'TextContainer'

const IconCircle: ReactComponentStyled<{}> = styled.div`
    width: 56px;
    height: 56px;
    background: rgba(74,74,74,0.81);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.50);
    border-radius: 50%;
    bottom: 0;
    position: absolute;
    right: 0;
    margin: 10px 10px;
`
IconCircle.displayName = 'IconCircle'

const IconContainer: ReactComponentStyled<{}> = styled.div`
    font-size: 30px;
    bottom: 0;
    position: absolute;
    right: 0;
    padding: 15px 20px;
`
Icon.displayName = 'Icon'

class Box extends Component<BoxProps, {}> {
  constructor (props: BoxProps) {
    super(props)
    this.state = {
      color: props.color,
      cursor: props.cursor,
      label: props.label
    }
  }

  getEditIcon () {
    const { editIcon } = this.props
    if (!editIcon) {
      return null
    }
    return (
      <div>
        <IconCircle />
        <IconContainer>
          <Icon color={color.white} iconName='pen' />
        </IconContainer>
      </div>
    )
  }

  render () {
    const { color, cursor, label } = this.props
    const editIcon = this.getEditIcon()
    return (
      <div>
        <BoxContainer onClick={this.props.onClick} color={color} cursor={cursor}>
          <Column>
            <TextContainer>
              <Column classNames={['is-8', 'is-offset-2', ' is-centered', 'is-paddingless']}>
                {label}
              </Column>
            </TextContainer>
          </Column>
          {editIcon}
        </BoxContainer>
      </div>
    )
  }
}

export default Box
