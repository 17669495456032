// @flow
/* eslint-disable object-shorthand */

import { ApolloClient, gql } from '@apollo/client'

class ApolloCache {
  client: ApolloClient<any>
  defaultKeys: {
    subdomain: 'subdomain',
    accessToken: 'accessToken',
    isToggleSideBarOpen: true
  }

  constructor (client: ?ApolloClient<any>, defaultKeys: ?Object<any>) {
    if (client) {
      this.client = client
    }
    if (defaultKeys) {
      this.defaultKeys = defaultKeys
    }
  }

  getCacheKeysWithClient (keys: Object<any>) {
    let tempKeys = ''
    const keysArray = Object.keys(keys)
    if (keysArray.length === 1) {
      tempKeys = `${keysArray[0]} @client`
    } else {
      for (let i = 0; i < keysArray.length; i++) {
        tempKeys += `${keysArray[i]} @client \n`
      }
    }
    return tempKeys
  }

  generateCacheQueryFromKeys (keys?: Object<any>) {
    let data = ''
    if (keys) {
      data = this.getCacheKeysWithClient(keys)
    }
    return gql`query GetData {
    ${data}
  }`
  }

  writeData (data: {}) {
    const cacheQuery = this.generateCacheQueryFromKeys(this.defaultKeys)
    if (this.client) {
      this.client.writeQuery({
        query: cacheQuery,
        data: data
      })
    }
    // this.client.writeData({ data: data })
  }

  readData (data: string): any {
    const result = this.client.readQuery({
      query: gql`
    query GetData {
      ${data} @client
    }
  `
    })
    return result
  }
}
export default ApolloCache
