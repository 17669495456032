// @flow
import { gql } from '@apollo/client'

export const REG_USER_REPORT = gql`
  query generateRegUserReport($accountId: String!){
    generateRegUserReport(accountId: $accountId)
  }
`

export const CFP_REG_USER_REPORT = gql`
  query generateCfpUserReport($accountId: String!) {
    generateCfpUserReport(accountId: $accountId)
  }
`
