// @flow

import React, { useState } from 'react'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import theme from 'michelangelo/dist/Components/styles/theme'
import color from 'michelangelo/dist/Components/styles/color'
import Notification from 'michelangelo/dist/Components/Notification'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import RadioButtonGroup from 'michelangelo/dist/Components/RadioButtonGroup'
import Input from 'michelangelo/dist/Components/Input'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { sendToBitrise } from './BitriseHelper'
import { AlertModal } from '../../Components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import { isEmpty } from 'lodash'
import type { Match, RouterHistory } from 'react-router-dom'
import { GET_BUILD_PARAMS } from '../../queries/application'

import { withApollo } from '@apollo/client/react/hoc'
import { useParams } from 'react-router-dom'

const Container: ReactComponentStyled<{}> = styled.div`
  text-align: center;
  width: 100%;
`
const ButtonContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
`
Container.displayName = 'Container'
const Header: ReactComponentStyled<{}> = styled.div`
  margin-top: 16px;
  h1 {
    font-size: 30px;
  }
`
Header.displayName = 'Header'
const CenterContainer: ReactComponentStyled<{}> = styled.div`
  display: inline-block;
  padding-bottom: 40px;
`
CenterContainer.displayName = 'CenterContainer'

const VerticalSeparator: ReactComponentStyled<{}> = styled.div`
  padding-top: 10px;
`
VerticalSeparator.displayName = 'VerticalSeparator'

const TopBorder: ReactComponentStyled<{}> = styled.div`
  border-top: 1px solid #E0E4E8;
  padding-top: 40px;
`
TopBorder.displayName = 'TopBorder'

type NewAppBuildProps = {
  match: Match,
  history: RouterHistory
} & InjectIntlProvidedProps

const NewAppBuild = ({ intl: { formatMessage } }: NewAppBuildProps) => {
  const [versionNumber, setVersionNumber] = useState('')
  const [branchName, setBranchName] = useState('master')
  const [workflow, setWorkFlow] = useState('primary')
  const [buildPlatform, setBuildPlatform] = useState('BOTH')
  const [buildStatus, setBuildStatus] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sendingBuild, setSendingBuild] = useState(false)

  const { id } = useParams()
  const { data: buildParams } = useQuery(GET_BUILD_PARAMS, { variables: { _id: id }, fetchPolicy: 'cache-and-network' })

  const getVersionNumberInput = () => {
    const iconType = checkVersionNumber() ? 'checkCircle' : 'close'
    const colorType = checkVersionNumber() ? color.darkestGray : color.danger
    return (
      <React.Fragment>
        <Input _lr-hide type='text' theme={colorType} hasIcon iconName={iconType} value={versionNumber} onChange={(value) => setVersionNumber(value)} label={formatMessage(messages.versionNumber)} />
      </React.Fragment>
    )
  }

  const checkVersionNumber = () => {
    // valid version numbers follow the format Major.Minor.Patch
    // ie: 10.5.500
    const versionNumberArray = versionNumber.split('.')
    const major = versionNumberArray[0]
    const minor = versionNumberArray[1]
    const patch = versionNumberArray[2]
    if (versionNumberArray.length !== 3) return false
    if (major.length !== 2 || isNaN(major)) return false
    if (minor.length !== 1 || isNaN(minor)) return false
    if (patch.length !== 3 || isNaN(patch)) return false
    return true
  }
  const getBranchNameInput = () => {
    const iconType = isEmpty(branchName) ? 'close' : 'checkCircle'
    const colorType = isEmpty(branchName) ? color.danger : color.darkestGray
    return (
      <React.Fragment>
        <Input _lr-hide type='text' theme={colorType} hasIcon iconName={iconType} value={branchName} onChange={(value) => setBranchName(value)} label={formatMessage(messages.branchName)} />
      </React.Fragment>
    )
  }

  const getWorkflowInput = () => {
    const iconType = isEmpty(workflow) ? 'close' : 'checkCircle'
    const colorType = isEmpty(workflow) ? color.danger : color.darkestGray
    return (
      <React.Fragment>
        <Input _lr-hide type='text' theme={colorType} hasIcon iconName={iconType} value={workflow} onChange={(value) => setWorkFlow(value)} label='Workflow' />
      </React.Fragment>
    )
  }

  const getHeader = () => {
    const title = formatMessage(messages.newAppBuild)
    return (
      <Row>
        <Column>
          <Header>
            <Text text={title} textSize='h2' align='center' fontColor={color.lightTextDefault} />
          </Header>
        </Column>
      </Row>
    )
  }

  const enterValidVersionNumber = () => {
    if (checkVersionNumber()) return null
    return (
      <Text text='Please enter a valid version number' textSize='h5' fontColor={color.danger} />
    )
  }

  const readyToBuild = () => {
    const getBuildParams = buildParams ? buildParams.getBuildParams : null
    if (getBuildParams) {
      return (
        <Text text={formatMessage(messages.readyToBuild)} textSize='h4' fontColor={color.darkestGray} />
      )
    } else {
      return (
        <Text text='Build Parameters have not yet been configured for this application' textSize='h4' fontColor={color.danger} />
      )
    }
  }
  const getRadioButtonGroup = () => {
    const options = [
      { value: 'IOS', label: 'IOS' },
      { value: 'ANDROID', label: 'ANDROID' },
      { value: 'BOTH', label: 'BOTH' }
    ]
    return (
      <RadioButtonGroup
        selectedOption={buildPlatform}
        options={options} onChange={(value) => setBuildPlatform(value)}
      />
    )
  }

  const getBuildPlatformFields = () => {
    const buildPlatformButtons = getRadioButtonGroup(buildPlatform)
    return (
      <React.Fragment>
        <Row>
          <Column>
            <Text text={formatMessage(messages.platform)} textSize='p' fontColor={color.darkestGray} />
          </Column>
        </Row>
        <Row>
          <Column>
            {buildPlatformButtons}
          </Column>
        </Row>
      </React.Fragment>
    )
  }

  const onSubmit = async () => {
    const params = buildParams.getBuildParams
    setSendingBuild(true)
    if (buildPlatform === 'BOTH') {
      const android = await sendToBitrise('ANDROID', branchName, versionNumber, params)
      const ios = await sendToBitrise('IOS', branchName, versionNumber, params)
      setBuildStatus(android === 'ok' && ios === 'ok')
    } else {
      const build = await sendToBitrise(buildPlatform, branchName, versionNumber, params)
      setBuildStatus(build === 'ok')
    }
    setSendingBuild(false)
    setVersionNumber('')
    setShowModal(true)
  }

  const getBuildButton = () => {
    if (!isEmpty(branchName) && !isEmpty(workflow) && checkVersionNumber() && buildParams.getBuildParams) {
      return <RoundedButton onClick={onSubmit} width='118px' theme={theme.success} fill textTheme={theme.success} disabled={false}>{formatMessage(messages.build)}</RoundedButton>
    } else {
      return <RoundedButton width='118px' theme={theme.disabled} fill textTheme={theme.disabled} disabled>{formatMessage(messages.build)}</RoundedButton>
    }
  }

  const closeModal = () => {
    setBuildStatus(false)
    setShowModal(false)
  }

  const getModal = () => {
    if (!showModal) {
      return null
    }
    const icon = buildStatus ? 'checkCircle' : 'close'
    const modalTheme = buildStatus ? color.success : color.danger
    const modalFontColor = buildStatus ? 'success' : 'danger'
    const modalText = formatMessage(buildStatus ? messages.success : messages.error)
    const message = formatMessage(buildStatus ? messages.bitrise : messages.oops)
    return (
      <AlertModal
        buttonAction={closeModal}
        icon={icon}
        buttonText={formatMessage(messages.ok)}
        headerText={modalText}
        modalText={message}
        modalTheme={modalTheme}
        modalFontColor={modalFontColor}
      />
    )
  }

  const modal = getModal()
  const header = getHeader()
  const branchNameInput = getBranchNameInput()
  const versionNumberInput = getVersionNumberInput()
  const workflowInput = getWorkflowInput()
  const buildPlatformButtons = getBuildPlatformFields()
  const versionNumberText = enterValidVersionNumber()
  const readyToBuildText = readyToBuild()
  const button = getBuildButton()
  const loadingNotification = sendingBuild && <Notification show message={formatMessage(messages.sendingBuild)} autoClose={0} type='loading' />

  return (
    <Container>
      <CenterContainer>
        {loadingNotification}
        {modal}
        {header}
        <Row>
          <Column>{versionNumberInput}</Column>
          <Column>{branchNameInput}</Column>
          <Column>{workflowInput}</Column>
        </Row>
        <Row><Column>{versionNumberText}</Column></Row>
        <Row>
          <Column>
            <VerticalSeparator>
              {buildPlatformButtons}
            </VerticalSeparator>
          </Column>
        </Row>
        <Row>
          <Column>
            <VerticalSeparator>
              {readyToBuildText}
            </VerticalSeparator>
          </Column>
        </Row>
        <VerticalSeparator>
          <Row classNames={['is-centered']}>
            <ButtonContainer>
              <Column>
                {button}
              </Column>
            </ButtonContainer>
          </Row>
        </VerticalSeparator>
      </CenterContainer>
    </Container>
  )
}

export default withApollo<NewAppBuildProps>(injectIntl(NewAppBuild))
export { NewAppBuild as UnwrappedNewAppBuild }
