// @flow

import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { ApolloClient, gql } from '@apollo/client'
import type { ApolloCache } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'

export const GET_MASTER_APP_INTEGRATION_BY_ID = gql`
query getMasterAppIntegrationById($masterAppIntegration: String!) {
  getMasterAppIntegrationById(masterAppIntegration: $masterAppIntegration) {
    _id
    appName
    iosUriScheme
    appleAppStoreLink
    androidUriScheme
    googlePlayStoreLink
    webUrl
    logoUrl
  }
}
`

type GetMasterAppIntegrationProps = {
  render: Function,
  masterAppIntegration: string,
}

export class GetMasterAppIntegrationByIdComponent extends Component<GetMasterAppIntegrationProps, {}> {
  render () {
    return (
      <Query
        query={GET_MASTER_APP_INTEGRATION_BY_ID}
        variables={{ masterAppIntegration: this.props.masterAppIntegration }}
        notifyOnNetworkStatusChange
        fetchPolicy='cache-and-network'
        displayName='App Integration'
      >
        {({ loading, error, data, refetch, networkStatus, client }) => {
          if (!(data && data.getMasterAppIntegrationById)) return null
          return this.props.render(data.getMasterAppIntegrationById, client)
        }}
      </Query>
    )
  }
}

export const GetMasterAppIntegrationById = async (client: ApolloClient, masterAppIntegration: string) => {
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: GET_MASTER_APP_INTEGRATION_BY_ID,
    variables: { masterAppIntegration }
  })
  return data.getMasterAppIntegrationById
}

export const UpdateCache = (cache: ApolloCache, masterAppIntegration: string, appName: string, iosUriScheme: string, appleAppStoreLink: string, androidUriScheme: string, googlePlayStoreLink: string, webUrl: string, logoUrl: string, updateMasterAppIntegration) => {
  const cachedData = cache.readQuery({
    query: GET_MASTER_APP_INTEGRATION_BY_ID,
    variables: { masterAppIntegration }
  })
  const data = cloneDeep(cachedData)
  if (!data || !data.getMasterAppIntegrationById) {
    return
  }
  data.getMasterAppIntegrationById.appName = appName
  data.getMasterAppIntegrationById.iosUriScheme = iosUriScheme
  data.getMasterAppIntegrationById.appleAppStoreLink = appleAppStoreLink
  data.getMasterAppIntegrationById.androidUriScheme = androidUriScheme
  data.getMasterAppIntegrationById.googlePlayStoreLink = googlePlayStoreLink
  data.getMasterAppIntegrationById.webUrl = webUrl
  data.getMasterAppIntegrationById.logoUrl = updateMasterAppIntegration.logoUrl
  cache.writeQuery({
    query: GET_MASTER_APP_INTEGRATION_BY_ID,
    variables: { masterAppIntegration },
    data
  })
}
