// @flow

import React, { Component } from 'react'

import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import theme from 'michelangelo/dist/Components/styles/theme'
import color from 'michelangelo/dist/Components/styles/color'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import RadioButtonGroup from 'michelangelo/dist/Components/RadioButtonGroup'
import CheckBox from 'michelangelo/dist/Components/CheckBox'
import Notification from 'michelangelo/dist/Components/Notification'
import Input from 'michelangelo/dist/Components/Input'
import { AlertModal } from '../../Components'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import { isEmpty, cloneDeep } from 'lodash'
import type { Match, RouterHistory } from 'react-router-dom'
import type { ApolloClient, ApolloError, MutationFunction, OperationVariables } from '@apollo/client'
import { gql } from '@apollo/client'
import { GetAsyncApplicationByID } from '../../queries/application'

import { Mutation } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'

const SET_APPLICATION_DETAILS = gql`
  mutation setApplicationDetails($application:String!,$deviceName:String!,$subDomain:String!,$distribution:String!,$appleDistribution:String!,$androidBundleId:String!,$iosBundleId:String!) {
    setApplicationDetails(application:$application,deviceName:$deviceName,subDomain:$subDomain,distribution:$distribution,appleDistribution:$appleDistribution,androidBundleId:$androidBundleId,iosBundleId:$iosBundleId) {
      _id
      title
      iosBundleId
      androidBundleId
      type
      versions
      subDomain
      ssoEnabled
      openRegistrationEnabled
      registrationCodeEnabled
      hrisIntegrations {
        hrisId: id
        enabled
      }
      customTexts {
        loginOpenRegPlaceholder
        loginRegCodePlaceholder
        registerRegCodeAccountPlaceholder
        registerRegCodeTooltip
        registerRegCodeEmailPlaceholder
        registerOpenRegEmailPlaceholder
        btnSingleSignOn
        btnOpenReg
        btnNotEmployee
        btnCreateOrLogin
      }
      deviceName
      appleDistribution
      distribution
    }
  }
`

const Container: ReactComponentStyled<{}> = styled.div`
  text-align: center;
  width: 100%;
`
const ButtonContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
`
Container.displayName = 'Container'
const Header: ReactComponentStyled<{}> = styled.div`
  margin-top: 16px;
  h1 {
    font-size: 30px;
  }
`
Header.displayName = 'Header'
const CenterContainer: ReactComponentStyled<{}> = styled.div`
  display: inline-block;
  padding-bottom: 40px;
`
CenterContainer.displayName = 'CenterContainer'

const VerticalSeparator: ReactComponentStyled<{}> = styled.div`
  padding-top: 10px;
`
VerticalSeparator.displayName = 'VerticalSeparator'

const TopBorder: ReactComponentStyled<{}> = styled.div`
  border-top: 1px solid #E0E4E8;
  padding-top: 40px;
`
TopBorder.displayName = 'TopBorder'
const CheckBoxContainer: ReactComponentStyled<{}> = styled.div`
  @media (min-width: 768px) {
    padding-top: 25px;
  }
`
CheckBoxContainer.displayName = 'CheckBoxContainer'
const Validation = {
  UNSET: 'UNSET',
  VALID: 'VALID',
  INVALID: 'INVALID'
}
type ValidationState = 'UNSET' | 'VALID' | 'INVALID'

type ApplicationDetailsProps = {
  match: Match,
  history: RouterHistory,
  client: ApolloClient,
  onTabValidated: (isValid: boolean) => void
} & InjectIntlProvidedProps

type Field = {
  value: string,
  isValid: ValidationState,
  message: string
}
type ApplicationDetailsState = {
  subDomain: Field,
  deviceName: Field,
  googleBundleId: Field,
  appleBundleId: Field,
  selectedGoogleBuild: 'ENTERPRISE' | 'STORE' | 'DIRECT' | 'N/A',
  selectedAppleBuild: 'ENTERPRISE' | 'STORE' | 'DIRECT' | 'N/A',
  sameBundle: boolean,
  showModal: boolean,
  modalMessage: string,
  status: boolean
}
class ApplicationDetails extends Component<ApplicationDetailsProps, ApplicationDetailsState> {
  constructor (props: ApplicationDetailsProps) {
    super(props)
    this.state = {
      deviceName: { value: '', isValid: Validation.UNSET, message: '' },
      subDomain: { value: '', isValid: Validation.UNSET, message: '' },
      googleBundleId: { value: '', isValid: Validation.UNSET, message: '' },
      appleBundleId: { value: '', isValid: Validation.UNSET, message: '' },
      selectedGoogleBuild: 'N/A',
      selectedAppleBuild: 'N/A',
      sameBundle: false,
      showModal: false,
      modalMessage: '',
      status: false
    }
  }

  getSelectedBuild (distribution: string, bundle: string) {
    if (isEmpty(distribution)) {
      return isEmpty(bundle) ? 'N/A' : 'ENTERPRISE'
    } else {
      return distribution
    }
  }

  async componentDidMount () {
    const { client, match: { params } } = this.props
    const applicationId = params.id || ''
    const application = await GetAsyncApplicationByID(client, applicationId)
    this.setState((prevState: ApplicationDetailsState) => {
      prevState.subDomain.value = application.subDomain
      prevState.googleBundleId.value = application.androidBundleId
      prevState.appleBundleId.value = application.iosBundleId
      prevState.deviceName.value = application.deviceName
      prevState.selectedGoogleBuild = this.getSelectedBuild(application.distribution, application.androidBundleId)
      prevState.selectedAppleBuild = this.getSelectedBuild(application.appleDistribution, application.iosBundleId)
      prevState.sameBundle = prevState.selectedAppleBuild === prevState.selectedGoogleBuild && prevState.googleBundleId.value === prevState.appleBundleId.value && prevState.selectedAppleBuild !== 'N/A'
      this.tabValidation(prevState)
      return prevState
    })
  }

  onChange (target: string, value: string) {
    this.setState((prevState: ApplicationDetailsState) => {
      prevState[target].value = value
      prevState[target].isValid = Validation.UNSET
      prevState[target].message = ''
      this.tabValidation(prevState)
      return prevState
    })
  }

  getInputTheme (validation: ValidationState) {
    if (validation === Validation.UNSET) {
      return theme.lightGrey
    } else if (validation === Validation.INVALID) {
      return theme.danger
    }
    return theme.success
  }

  getErrorText (text: string) {
    if (isEmpty(text)) {
      return null
    }
    return <Text textSize='p' fontColor={theme.danger}>{text}</Text>
  }

  getInputField (name: string) {
    const { intl: { formatMessage } } = this.props
    const error = this.getErrorText(this.state[name].message)
    const isValid = this.state[name].isValid
    const value = this.state[name].value
    return (
      <React.Fragment>
        <Input _lr-hide theme={this.getInputTheme(isValid)} type='text' value={value} onChange={(value) => this.onChange(name, value)} label={formatMessage(messages[name])} />
        {error}
      </React.Fragment>
    )
  }

  getAppleBundleId () {
    const { selectedAppleBuild } = this.state
    if (selectedAppleBuild === 'N/A') {
      return null
    }

    const { intl: { formatMessage } } = this.props
    const { sameBundle, appleBundleId, googleBundleId } = this.state
    const value = sameBundle ? googleBundleId.value : appleBundleId.value
    const error = this.getErrorText(sameBundle ? googleBundleId.message : appleBundleId.message)
    const label = formatMessage(messages.appleBundleId)
    const sameAsGoogle = formatMessage(messages.sameAsGoogle)
    const isValid = sameBundle ? googleBundleId.isValid : appleBundleId.isValid
    return (
      <Row>
        <Column>
          <React.Fragment>
            <Input _lr-hide theme={this.getInputTheme(isValid)} type='text' value={value} onChange={(value) => this.onChange('appleBundleId', value)} label={label} disabled={sameBundle} />
            {error}
          </React.Fragment>
        </Column>
        <Column>
          <CheckBoxContainer>
            <CheckBox checked={sameBundle} label={sameAsGoogle} onChange={(checked) => this.onRadioButtonChanged('sameBundle', checked)} />
          </CheckBoxContainer>
        </Column>
      </Row>

    )
  }

  getGoogleBundleId () {
    const { selectedGoogleBuild } = this.state
    if (selectedGoogleBuild === 'N/A') {
      return null
    }
    const googleBundleId = this.getInputField('googleBundleId')
    return (
      <Row>
        <Column>{googleBundleId}</Column>
      </Row>
    )
  }

  handleOnCancelClick = () => {
    const { match: { params }, history } = this.props
    if (params.accountId) {
      history.push(`/all-accounts/view/${params.accountId}/all-applications`)
    } else {
      history.push('/all-applications')
    }
  }

  tabValidation (state: ApplicationDetailsState) {
    const validationState = this.applicationValidation(cloneDeep(state))
    const isValid = this.isValid(validationState)
    this.props.onTabValidated(isValid)
  }

  onRadioButtonChanged (target: string, value: string) {
    this.setState((prevState: ApplicationDetailsState) => {
      prevState[target] = value
      this.tabValidation(prevState)
      return prevState
    })
  }

  getRadioButtonGroup (target: string) {
    const options = [
      { value: 'ENTERPRISE', label: 'MDM' },
      { value: 'STORE', label: 'Play Store' },
      { value: 'DIRECT', label: 'Direct Link' },
      { value: 'N/A', label: 'N/A' }
    ]
    return (
      <RadioButtonGroup
        name={target}
        selectedOption={this.state[target]}
        options={options} onChange={(value: string) => this.onRadioButtonChanged(target, value)}
      />
    )
  }

  isValid (state?: ApplicationDetailsState): boolean {
    const { deviceName, subDomain, googleBundleId, appleBundleId } = state || this.state
    return deviceName.isValid !== Validation.INVALID && subDomain.isValid !== Validation.INVALID && googleBundleId.isValid !== Validation.INVALID && appleBundleId.isValid !== Validation.INVALID
  }

  validateBundle (state: ApplicationDetailsState, selectedBuild: string, field: string): ApplicationDetailsState {
    const { intl: { formatMessage } } = this.props
    if (selectedBuild !== 'N/A') {
      if (isEmpty(state[field].value)) {
        state[field].isValid = Validation.INVALID
        state[field].message = formatMessage(messages.emptyField)
      } else if (!/^\S+\.\S+\.\S+$/.test(state[field].value)) {
        state[field].isValid = Validation.INVALID
        state[field].message = formatMessage(messages.correctBundleId)
      } else {
        state[field].isValid = Validation.VALID
      }
    } else {
      state[field].isValid = Validation.VALID
    }
    return state
  }

  validateNonEmptyField (state: ApplicationDetailsState, field: string): ApplicationDetailsState {
    const { intl: { formatMessage } } = this.props
    if (isEmpty(state[field].value)) {
      state[field].isValid = Validation.INVALID
      state[field].message = formatMessage(messages.emptyField)
    } else {
      state[field].isValid = Validation.VALID
    }
    return state
  }

  applicationValidation (state: ApplicationDetailsState): ApplicationDetailsState {
    const { selectedAppleBuild, selectedGoogleBuild, sameBundle } = state
    state = this.validateNonEmptyField(state, 'deviceName')
    state = this.validateNonEmptyField(state, 'subDomain')
    state = this.validateBundle(state, selectedGoogleBuild, 'googleBundleId')
    if (sameBundle) {
      state.appleBundleId.isValid = Validation.VALID
    } else {
      state = this.validateBundle(state, selectedAppleBuild, 'appleBundleId')
    }
    return state
  }

  getHeader () {
    const { intl: { formatMessage } } = this.props
    const title = formatMessage(messages.applicationDetails)
    return (
      <Row>
        <Column>
          <Header>
            <Text text={title} textSize='h2' align='center' fontColor={color.lightTextDefault} />
          </Header>
        </Column>
      </Row>
    )
  }

  getGoogleRelatedFields () {
    const googleBuild = this.getRadioButtonGroup('selectedGoogleBuild')
    const googleBundleId = this.getGoogleBundleId()
    // text google needs to be i18n
    return (
      <React.Fragment>
        <Row>
          <Column>
            <Text text='Google' textSize='p' fontColor={color.darkestGray} />
          </Column>
        </Row>
        <Row>
          <Column>
            {googleBuild}
          </Column>
        </Row>
        {googleBundleId}
      </React.Fragment>
    )
  }

  getAppleRelatedFields () {
    const appleBuild = this.getRadioButtonGroup('selectedAppleBuild')
    const appleBundleId = this.getAppleBundleId()
    // Text Apple needs to be i18n
    return (
      <React.Fragment>
        <Row>
          <Column>
            <TopBorder>
              <Text text='Apple' textSize='p' fontColor={color.darkestGray} />
            </TopBorder>
          </Column>
        </Row>
        <Row>
          <Column>
            {appleBuild}
          </Column>
        </Row>
        {appleBundleId}
      </React.Fragment>
    )
  }

  getMutation () {
    const isValid = this.isValid()
    const { intl: { formatMessage } } = this.props
    return (
      <Mutation
        mutation={SET_APPLICATION_DETAILS}
        onCompleted={this.handleOnCompleted}
        onError={this.handleOnError}
        // update={(cache, { data }) => this.updateCache(cache, data ? data.createApplication : null)}
      >
        {(setApplicationDetails, { loading, client }) => {
          const notification = this.getLoadingNotification(loading)
          return (
            <React.Fragment>
              {notification}
              <RoundedButton onClick={() => this.save(setApplicationDetails)} width='118px' theme={theme.info} fill disabled={!isValid || loading}>{formatMessage(messages.save)}</RoundedButton>
            </React.Fragment>
          )
        }}
      </Mutation>
    )
  }

  getLoadingNotification (loading: boolean) {
    const { intl: { formatMessage } } = this.props
    const updatingTitle = formatMessage(messages.creatingApplication)
    if (loading) {
      return <Notification show message={updatingTitle} autoClose={0} type='loading' />
    }
    return null
  }

  getAndroidBundleId (state: ApplicationDetailsState) {
    // text needs to be i18n
    return state.selectedGoogleBuild === 'N/A' ? '' : state.googleBundleId.value
  }

  getIosBundleId (state: ApplicationDetailsState, androidBundleId: string) {
    // text needs to be i18n
    if (!state.sameBundle) {
      return state.selectedAppleBuild === 'N/A' ? '' : state.appleBundleId.value
    } else {
      return androidBundleId
    }
  }

  save = (setApplicationDetails: MutationFunction<any, OperationVariables>) => {
    this.setState((prevState: ApplicationDetailsState) => {
      prevState = this.applicationValidation(prevState)
      if (this.isValid(prevState)) {
        const androidBundleId = this.getAndroidBundleId(prevState)
        const iosBundleId = this.getIosBundleId(prevState, androidBundleId)
        setApplicationDetails({
          variables: {
            application: this.props.match.params.id || '',
            deviceName: prevState.deviceName.value,
            subDomain: prevState.subDomain.value,
            distribution: prevState.selectedGoogleBuild,
            appleDistribution: prevState.selectedAppleBuild,
            androidBundleId,
            iosBundleId
          }
        })
      }
      return prevState
    })
  }

  handleOnCompleted = (data: any) => {
    const { intl: { formatMessage } } = this.props
    if (data.setApplicationDetails._id) {
      this.setState({
        showModal: true,
        status: true,
        modalMessage: formatMessage(messages.applicationCreatedSuccesfully)
      })
    } else {
      this.setState({
        showModal: true,
        status: false,
        modalMessage: formatMessage(messages.errorApplicationCreation)
      })
    }
  }

  handleOnError = (error: ApolloError) => {
    const message = this.getServerErrorMessage(error)
    this.setState({
      showModal: true,
      status: false,
      modalMessage: message
    })
  }

  getServerErrorMessage (error: ApolloError): string {
    const { intl: { formatMessage } } = this.props
    if (error.graphQLErrors.length === 0) {
      return formatMessage(messages.errorApplicationCreation)
    } else if (error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
      return this.getErrorFromCode(error.graphQLErrors[0].extensions.exception.details)
    } else {
      return formatMessage(messages.errorApplicationCreation)
    }
  }

  getErrorFromCode (code: string) {
    const { intl: { formatMessage } } = this.props

    if (code.includes('ValidationError')) {
      return formatMessage(messages.validField)
    }
    switch (code) {
      case 'UNAUTHORIZED':
        return formatMessage(messages.unauthorized)
      case 'SUBDOMAIN_EXISTS':
        return formatMessage(messages.applicationSubDomainExists)
      case 'ANDROID_BUNDLE_ID_EXISTS':
        return formatMessage(messages.applicationAndroidBundleIdExists)
      case 'IOS_BUNDLE_ID_EXISTS':
        return formatMessage(messages.applicationIosBundleIdExists)
      default:
        return formatMessage(messages.errorApplicationCreation)
    }
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      status: false,
      modalMessage: ''
    })
  }

  getModal () {
    const { intl: { formatMessage } } = this.props
    const { status, modalMessage, showModal } = this.state
    if (!showModal) {
      return null
    }
    const icon = status ? 'checkCircle' : 'close'
    const modalTheme = status ? color.success : color.danger
    const modalFontColor = status ? 'success' : 'danger'
    const modalText = formatMessage(status ? messages.success : messages.error)
    return (
      <AlertModal
        buttonAction={this.closeModal}
        icon={icon}
        buttonText={formatMessage(messages.ok)}
        headerText={modalText}
        modalText={modalMessage}
        modalTheme={modalTheme}
        modalFontColor={modalFontColor}
      />
    )
  }

  render () {
    const { intl: { formatMessage } } = this.props
    const header = this.getHeader()
    const howWillAppriseBuild = formatMessage(messages.appBuildQuestion)
    const deviceName = this.getInputField('deviceName')
    const subDomain = this.getInputField('subDomain')
    const google = this.getGoogleRelatedFields()
    const apple = this.getAppleRelatedFields()
    const mutation = this.getMutation()
    const modal = this.getModal()
    return (
      <Container>
        <CenterContainer>
          {modal}
          {header}
          <Row>
            <Column>{deviceName}</Column>
            <Column>{subDomain}</Column>
          </Row>
          <Row>
            <Column>
              <VerticalSeparator>
                <Text text={howWillAppriseBuild} textSize='h4' fontColor={color.darkestGray} />
              </VerticalSeparator>
            </Column>
          </Row>
          {google}
          {apple}
          <VerticalSeparator>
            <Row classNames={['is-centered']}>
              <ButtonContainer>
                <Column>
                  <RoundedButton onClick={this.handleOnCancelClick} width='118px' theme={theme.danger} textTheme={theme.danger} disabled={false}>{formatMessage(messages.cancel)}</RoundedButton>
                </Column>
                <Column>
                  {mutation}
                </Column>
              </ButtonContainer>
            </Row>
          </VerticalSeparator>
        </CenterContainer>
      </Container>
    )
  }
}

export default withApollo<ApplicationDetailsProps>(injectIntl(ApplicationDetails))
export { ApplicationDetails as UnwrappedApplicationDetails }
