// @flow

import React, { Component } from 'react'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from './i18n/messages'
import Notification from 'michelangelo/dist/Components/Notification'

type NetworkErrorNotificationProps = {
  show: boolean,
  onClose: () => void
} & InjectIntlProvidedProps

class NetworkErrorNotification extends Component<NetworkErrorNotificationProps, {}> {
  render () {
    const { show, intl: { formatMessage }, onClose } = this.props
    return <Notification show={show} message={formatMessage(messages.networkError)} autoClose={5} type='error' onClose={() => onClose()} />
  }
}

export default injectIntl(NetworkErrorNotification)
export { NetworkErrorNotification as unConnectedNetworkErrorNotification }
