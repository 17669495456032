// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import withRouter from './utils/withRouter'
import { defineMessages, injectIntl } from 'react-intl'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Button from 'michelangelo/dist/Components/Buttons/Button'
import Icon from 'michelangelo/dist/Components/Icon'
import color from 'michelangelo/dist/Components/styles/color'

const PermissionDeniedContainer: ReactComponentStyled<{}> = styled.div`
    background-color: ${color.white};
    width: 100%;
    min-height: calc(100vh - 110px);
    box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 780px){
      min-height: calc(100vh - 135px);
    }
`
PermissionDeniedContainer.displayName = 'PermissionDeniedContainer'

const ContentContainer: ReactComponentStyled<{}> = styled.div`
    padding: 20px;
`

ContentContainer.displayName = 'ContentContainer'

export const PermissionDeniedMessages = defineMessages({
  permissionDenied: {
    id: 'permissionDenied.permissionDenied',
    defaultMessage: 'You don\'t have permission to access this page.'
  },
  goToAllApplications: {
    id: 'permissionDenied.goToAllApplications',
    defaultMessage: 'GO TO ALL APPLICATIONS'
  }
})
type PermissionDeniedProps = {
  navigate: Object
} & InjectIntlProvidedProps

class PermissionDenied extends Component<PermissionDeniedProps, {}> {
  constructor (props: PermissionDeniedProps) {
    super(props)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleOnClick () {
    this.props.navigate('/all-applications')
  }

  render () {
    const { intl: { formatMessage } } = this.props
    return (
      <PermissionDeniedContainer>
        <ContentContainer>
          <Icon color={color.lightestGrey} iconName='lock' size='5x' />
        </ContentContainer>
        <Text text={formatMessage(PermissionDeniedMessages.permissionDenied)} textSize='p' fontColor={color.lightTextDefault} />
        <ContentContainer>
          <Button outline title={formatMessage(PermissionDeniedMessages.goToAllApplications)} onClick={this.handleOnClick} />
        </ContentContainer>
      </PermissionDeniedContainer>
    )
  }
}

export default injectIntl(withRouter(PermissionDenied))
export { PermissionDenied as UnwrappedPermissionDenied }
