// @flow

import * as React from 'react'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import { Modal } from './index'
import Icon from 'michelangelo/dist/Components/Icon'
import color from 'michelangelo/dist/Components/styles/color'
import theme from 'michelangelo/dist/Components/styles/theme'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
    background: rgba(51,51,51,0.65);
`
/* istanbul ignore next */
const ParentContainer = styled.div`
    z-index: 9;
    vertical-align: middle;
    background-color: ${color.white};
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @media (max-width: 600px) {
      width: 350px;
    }
    @media (max-width: 360px) {
      width: 300px;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
`
const TopBox = styled.div`
    width: 550px;
    height: auto;
    padding: 20px 10px;
    @media (max-width: 600px) {
      width: 350px;
    }
    @media (max-width: 360px) {
      width: 300px;
    }
`

const BottomBox = styled.div`
    border-top: 1px solid #D6D6D6;
    background-color: #F9F9FB;
    width: 550px;
    height: auto;
    padding: 20px 10px;
    @media (max-width: 600px) {
      width: 350px;
    }
    @media (max-width: 360px) {
      width: 300px;
    }
`
/* istanbul ignore next */
const TextBox = styled.div`
    color: ${color.default};
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 32px;
    width: 550px;
    text-align: center;
    padding: 0 125px 30px 125px;
    @media (max-width: 600px) {
      width: 330px;
      padding: 0 20px 30px 20px;
    }
    @media (max-width: 360px) {
      width: 280px;
      padding: 0 20px 30px 20px;
    }
`
const ButtonContainer = styled.div`
    width: 100%;
    display: inline-block;
`
const ButtonSpacer = styled.span`
    padding-right: 20px;
`
const TextContainer = styled.div`
    margin-top: 20px;
`

// add for testing purpose
Container.displayName = 'Container'
ParentContainer.displayName = 'ParentContainer'
TopBox.displayName = 'TopBox'
BottomBox.displayName = 'BottomBox'
TextBox.displayName = 'TextBox'
ButtonContainer.displayName = 'ButtonContainer'
ButtonSpacer.displayName = 'ButtonSpacer'
TextContainer.displayName = 'TextContainer'

type ModalProps = {
  modalTheme: string,
  modalFontColor: 'default' | 'info' | 'warning' | 'danger' | 'success',
  modalText: string,
  icon: string,
  headerText: string,
  buttonAction: () => void,
  buttonText: string,
  body?: any
}

type ModalState = {
  active: boolean
}

class AlertModal extends React.Component<ModalProps, ModalState> {
  constructor (props: ModalProps) {
    super(props)
    this.state = {
      active: true
    }
  }

  action (e?: Event) {
    if (e) {
      e.preventDefault()
    }
    const { buttonAction } = this.props
    buttonAction()
  }

  getButtons () {
    const { buttonText } = this.props
    return <RoundedButton onClick={() => this.action()} width='118px' theme={theme.info} fill>{buttonText}</RoundedButton>
  }

  getHeader () {
    return (
      <React.Fragment>
        <Icon iconName={this.props.icon} size='2x' color={this.props.modalTheme} />
        <TextContainer>
          <Text align='center' textSize='h4' fontColor={color[this.props.modalFontColor]} text={this.props.headerText} />
        </TextContainer>
      </React.Fragment>
    )
  }

  render () {
    const modalText = this.props.modalText
    const body = (
      <React.Fragment>
        <TextBox>{modalText}</TextBox>
        {this.props.body}
      </React.Fragment>
    )
    const buttons = this.getButtons()
    const header = this.getHeader()
    return (
      <Modal
        header={header}
        body={body}
        footer={buttons}
      />
    )
  }
}

export default AlertModal
