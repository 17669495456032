// @flow
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { ApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { injectIntl, defineMessages } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import theme from 'michelangelo/dist/Components/styles/theme'
import type { Match, RouterHistory } from 'react-router-dom'
import { GET_APPLICATION_BY_ID, SET_MOBILELOCK_DETAILS } from '../../queries/application'
import { GET_AUTHENTICATED_USER } from '../../queries/getAuthenticatedUser'
import { messages } from '../../i18n/messages'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import { Toggle, ConfirmModal } from '../../Components'
import Notification from 'michelangelo/dist/Components/Notification'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import RadioButtonGroup from 'michelangelo/dist/Components/RadioButtonGroup'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import { alfredUrl } from '../../utils/config'
import { toLower } from 'lodash'
import { useParams } from 'react-router-dom'

const ButtonContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  padding-top: 100px;
  padding-bottom: 80px;
`
ButtonContainer.displayName = 'ButtonContainer'

const HeaderContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    padding: 63px 0px 20px 0px;
    text-align: center;
`
HeaderContainer.displayName = 'HeaderContainer'

const DescriptionContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 20% 22px 20%;
    text-align: center;
    @media (max-width: 900px) {
      padding: 20px 7% 22px 7%;
    }
    @media (max-width: 412px) {
      padding: 20px 5% 18px 5%;
    }
`
DescriptionContainer.displayName = 'DescriptionContainer'

const FieldContainer: ReactComponentStyled<{}> = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 20% 22px 20%;
  text-align: left;
  color: ${color.darkestGray}
  @media (max-width: 900px) {
    padding: 20px 7% 22px 7%;
  }
  @media (max-width: 412px) {
    padding: 20px 5% 18px 5%;
  }
`
FieldContainer.displayName = 'FieldContainer'

const mobileLockMessages = defineMessages({
  securityLock: {
    id: 'mobile.securityLock',
    defaultMessage: 'Security Lock'
  },
  securityLockDescription: {
    id: 'mobile.securityLockDescription',
    defaultMessage: 'Additional security can be enabled to require users to have to enter a passcode every time they access the app. Toggle below to enable/disable the security lock feature.'
  },
  securityLockStatus: {
    id: 'mobile.status',
    defaultMessage: 'Security Lock {statusValue}'
  },
  fourDigits: {
    id: 'mobile.fourDigits',
    defaultMessage: '4-digits'
  },
  sixDigits: {
    id: 'mobile.sixDigits',
    defaultMessage: '6-digits'
  },
  passcodeLengthHeader: {
    id: 'mobile.passcodeLengthHeader',
    defaultMessage: 'Select number of digits required for passcode'
  },
  modalBody: {
    id: 'mobile.modalBody',
    defaultMessage: 'Do you want to {statusValue} Security lock?'
  },
  modalHeader: {
    id: 'mobile.modalHeader',
    defaultMessage: 'Are you sure'
  },
  modalConfirm: {
    id: 'mobile.modalConfirm',
    defaultMessage: 'Yes, {statusValue}'
  },
  enable: {
    id: 'mobile.enable',
    defaultMessage: 'Enable'
  },
  disable: {
    id: 'mobile.disable',
    defaultMessage: 'Disable'
  },
  saving: {
    id: 'mobile.saving',
    defaultMessage: 'Saving...'
  },
  saved: {
    id: 'mobile.saved',
    defaultMessage: 'Success, saved!'
  },
  somethingWentWrong: {
    id: 'mobile.somethingWentWrong',
    defaultMessage: 'Something went wrong. Please try again.'
  },
  error: {
    id: 'mobile.error',
    defaultMessage: 'Error, please contact us'
  }
})
type MobileLockProps = {
  match: Match,
  client: ApolloClient<any>,
  history: RouterHistory
} & InjectIntlProvidedProps

function MobileLock ({ intl: { formatMessage } }: MobileLockProps) {
  const params = useParams()
  const onNotificationClose = () => setNotification({ ...notification, show: false })
  const onError = () => {
    const buttonText = retries < 3 ? undefined : formatMessage(messages.contact)
    const onClick = retries < 3 ? undefined : onContact
    const type = retries < 3 ? 'warning' : 'error'
    const title = formatMessage(retries < 3 ? mobileLockMessages.somethingWentWrong : mobileLockMessages.error)
    setRetries(retries + 1)
    setNotification({ type, autoClose: 5, message: title, show: true, onClose: onNotificationClose, buttonText, onClick })
  }
  const onCompleted = () => {
    setRetries(0)
    setNotification({ type: 'success', autoClose: 5, message: formatMessage(mobileLockMessages.saved), show: true, onClose: onNotificationClose })
  }
  const id = params.id || ''
  const { data: application } = useQuery(GET_APPLICATION_BY_ID, { variables: { _id: id }, skip: !id, fetchPolicy: 'cache-and-network' })
  const [getAuthenticatedUser, { data: user, called: calledUser }] = useLazyQuery(GET_AUTHENTICATED_USER)
  const [setMobileLockDetails, { loading }] = useMutation(SET_MOBILELOCK_DETAILS, { onError, onCompleted })
  const [isSecurityLockMandatory, setIsSecurityLockMandatory] = useState(application && application.getApplicationById ? application.getApplicationById.isSecurityLockMandatory : false)
  const [passcodeLength, setPasscodeLength] = useState(application && application.getApplicationById && application.getApplicationById.passcodeLength !== 0 ? application.getApplicationById.passcodeLength : 4)
  const [showModal, setShowModal] = useState(false)
  const [notification, setNotification] = useState({ type: 'success', autoClose: 5, message: '', show: false, onClose: onNotificationClose })
  const [retries, setRetries] = useState(0)

  useEffect(() => {
    if (!params.accountId) {
      getAuthenticatedUser()
    }
    // eslint-disable-next-line
  }, [])

  const setApplicationData = () => {
    if (!application || !application.getApplicationById) {
      return
    }
    if (application.getApplicationById.isSecurityLockMandatory !== isSecurityLockMandatory) {
      setIsSecurityLockMandatory(application.getApplicationById.isSecurityLockMandatory)
    }
    if (application.getApplicationById.passcodeLength !== passcodeLength) {
      setPasscodeLength(application.getApplicationById.passcodeLength !== 0 ? application.getApplicationById.passcodeLength : 4)
    }
  }
  const useMountEffect = (fun) => useEffect(fun, [])
  useMountEffect(setApplicationData)

  const getRadioButtonGroup = () => {
    if (!isSecurityLockMandatory) {
      return null
    }
    const options = [
      { value: '4', label: formatMessage(mobileLockMessages.fourDigits) },
      { value: '6', label: formatMessage(mobileLockMessages.sixDigits) }
    ]
    return (
      <React.Fragment>
        <FieldContainer>
          <Text fontColor={color.darkestGray} textSize='p' text={formatMessage(mobileLockMessages.passcodeLengthHeader)} />
        </FieldContainer>
        <FieldContainer>
          <Row>
            <Column classNames={['is-mobile, is-6']}>
              <RadioButtonGroup
                name='passcodeLength'
                selectedOption={passcodeLength.toString()}
                options={options}
                onChange={(value) => setPasscodeLength(parseInt(value))}
              />
            </Column>
          </Row>
        </FieldContainer>
      </React.Fragment>
    )
  }
  const getModal = () => {
    if (!showModal) {
      return null
    }
    const statusValue = isSecurityLockMandatory ? formatMessage(mobileLockMessages.enable) : formatMessage(mobileLockMessages.disable)

    return (
      <ConfirmModal
        cancelAction={hideModal}
        buttonAction={save}
        icon='warning'
        buttonText={formatMessage(mobileLockMessages.modalConfirm, { statusValue: toLower(statusValue) })}
        headerText={formatMessage(mobileLockMessages.modalHeader)}
        cancelText={formatMessage(messages.cancel)}
        modalText={formatMessage(mobileLockMessages.modalBody, { statusValue })}
        modalTheme={color.warning}
        modalFontColor='warning'
      />
    )
  }

  const onToggle = () => {
    setIsSecurityLockMandatory(!isSecurityLockMandatory)
  }
  const save = () => {
    const account = params.accountId || (calledUser && user ? user.getAuthenticatedUser.account._id : '')
    hideModal()
    onNotificationClose()
    setMobileLockDetails({
      variables: {
        account, application: id, isSecurityLockMandatory, passcodeLength
      }
    })
  }
  const openModal = () => setShowModal(true)
  const hideModal = () => setShowModal(false)
  const passcodeDigitsComponent = getRadioButtonGroup()
  const modal = getModal()
  const onContact = () => {
    setRetries(0)
    window.open(`${alfredUrl}/customer-care`)
  }

  return (
    <div>
      {modal}
      <Notification show={loading} message={formatMessage(mobileLockMessages.saving)} autoClose={0} type='loading' />
      <Notification {...notification} />
      <HeaderContainer>
        <Text text={formatMessage(mobileLockMessages.securityLock)} textSize='h1' align='center' fontColor={color.lightBlack} />
      </HeaderContainer>
      <DescriptionContainer>
        <Text fontColor={color.darkGray} textSize='p' text={formatMessage(mobileLockMessages.securityLockDescription)} />
      </DescriptionContainer>
      <FieldContainer>
        {isSecurityLockMandatory}
        <Toggle label={formatMessage(mobileLockMessages.securityLockStatus, { statusValue: isSecurityLockMandatory })} isToggled={isSecurityLockMandatory} onToggleChange={onToggle} />
      </FieldContainer>
      {passcodeDigitsComponent}
      <Row classNames={['is-centered']}>
        <ButtonContainer>
          <Column>
            <RoundedButton onClick={setApplicationData} width='118px' theme={theme.disabled} textTheme={theme.disabled} disabled={false}>{formatMessage(messages.cancel)}</RoundedButton>
          </Column>
          <Column>
            <RoundedButton onClick={openModal} width='118px' theme={theme.info} fill>{formatMessage(messages.save)}</RoundedButton>
          </Column>
        </ButtonContainer>
      </Row>
    </div>
  )
}

export default injectIntl(MobileLock)
export { MobileLock as UnwrappedMobileLock }
