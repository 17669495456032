// @flow
import React, { useEffect, useState } from 'react'
import withRouter from '../../utils/withRouter'
import type { RouterHistory, Match } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ApolloClient, useApolloClient, useQuery } from '@apollo/client'
import Tabs from '../../Components/RouterTabs'
import { GET_APPLICATION_BY_ID } from '../../queries/application'
// import DeveloperDetails from './DeveloperDetails' TODO return this when working on developer details
import ApplicationDetails from './ApplicationDetails'
import color from 'michelangelo/dist/Components/styles/color'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
Tabs.displayName = 'Tabs'
type DetailsProps = {
  match: Match,
  history: RouterHistory,
  client: ApolloClient
} & InjectIntlProvidedProps
// type DetailsState = {
//   // ValidDeveloperDetails: boolean, TODO return this when developer details gets done
//   ValidApplicationDetails: boolean
// }

// class Details extends Component<DetailsProps, DetailsState> {
//   constructor (props: DetailsProps) {
//     super(props)
//     this.state = { ValidApplicationDetails: false }
//   }
//
//   onTabValidated (id: string, isValid: boolean) {
//     this.setState({ [id]: isValid })
//   }
//
//   getBody (application: any) {
//     const { intl: { formatMessage }, match: { params } } = this.props
//     const id = params.id || ''
//     const accountId = params.accountId || ''
//     const baseRoute = isEmpty(accountId) ? '/all-applications/application' : '/all-accounts/view/:accountId/all-applications/application'
//     const basePath = isEmpty(accountId) ? '/all-applications/application' : `/all-accounts/view/${accountId}/all-applications/application`
//     const { ValidApplicationDetails } = this.state
//     /* istanbul ignore next */
//     const tabListArray = [ // TODO return this when developer details gets done
//       /* { label: formatMessage(messages.developerDetails),
//         path: `${basePath}/${id}/AppBuild/DeveloperDetails`,
//         component: <Route path={`${baseRoute}/:id/AppBuild/DeveloperDetails`} render={(props) => <DeveloperDetails {...props} application={application} client={this.props.client} onChange={this.onTabValidated} />} />
//         icon: DeveloperDetails ? 'checkCircle' : 'close',
//         color: DeveloperDetails ? color.success : color.danger
//       }, */
//       {
//         label: formatMessage(messages.applicationDetails),
//         path: `${basePath}/${id}/AppBuild/ApplicationDetails`,
//         component: <Route path={`${baseRoute}/:id/AppBuild/ApplicationDetails`} render={(props) => <ApplicationDetails {...props} application={application} client={this.props.client} onTabValidated={(isValid) => this.onTabValidated('ValidApplicationDetails', isValid)} />} />,
//         icon: ValidApplicationDetails ? 'checkCircle' : 'close',
//         color: ValidApplicationDetails ? color.success : color.danger
//       }
//     ]
//     return { tabListArray }
//   }
//
//   getTabs (application: any) {
//     const { history } = this.props
//     const { tabListArray } = this.getBody(application)
//
//     return <Tabs tabsArray={tabListArray} history={history} />
//   }
//
//   render () {
//     const id = this.props.match.params.id || ''
//     return <GetApplicationByID _id={id} children={(application, client) => this.getTabs(application)} />
//   }
// }

const Details = (props: DetailsProps) => {
  const client = useApolloClient()
  const { match: { params }, history, intl: { formatMessage } } = props
  const [validApplicationDetails, setValidApplicationDetails] = useState(false)
  const [application, setApplication] = useState(null)
  const id = params.id || ''
  const accountId = params.accountId || ''
  const { loading, data: app } = useQuery(GET_APPLICATION_BY_ID, {
    variables: {
      _id: id
    },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  useEffect(() => {
    if (app && app.getApplicationById) {
      setApplication(app.getApplicationById)
    }
  }, [app])
  const getBody = (application) => {
    const basePath = isEmpty(accountId) ? '/all-applications/application' : `/all-accounts/view/${accountId}/all-applications/application`
    /* istanbul ignore next */
    const tabListArray = [
      {
        label: formatMessage(messages.applicationDetails),
        path: `${basePath}/${id}/AppBuild/ApplicationDetails`,
        component: <ApplicationDetails {...props} application={application} client={client} onTabValidated={(isValid) => setValidApplicationDetails(isValid)} />,
        icon: validApplicationDetails ? 'checkCircle' : 'close',
        color: validApplicationDetails ? color.success : color.danger
      }
    ]
    return tabListArray
  }
  if (loading && !application) {
    return 'loading'
  }
  const tabListArray = getBody(application)
  return <Tabs tabsArray={tabListArray} history={history} />
}
export default injectIntl(withRouter(Details))
export { Details as UnwrappedDetails }
