// @flow

import React, { useContext } from 'react'
import { homeNavlinkArray } from './NavigationLinks'
import Menu from './Menu'
import type { Match } from 'react-router-dom'
import { AppContext } from '../../contexts/app'
import { useParams } from 'react-router-dom'

type HomeSideBarProps = {
  isToggleSideBarOpen: boolean,
  match: Match
}

const HomeSideBar = (props: HomeSideBarProps) => {
  const params = useParams()
  const { isToggled } = useContext(AppContext)
  const accountId = params.accountId || ''
  const navLinks = homeNavlinkArray(accountId)
  return <Menu navLinks={navLinks} isopen={isToggled} />
}
export default HomeSideBar
