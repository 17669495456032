// @flow
import React, { Component } from 'react'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Button from 'michelangelo/dist/Components/Buttons/Button'
import PreviewBox from 'michelangelo/dist/Components/PreviewBox'
import AlfredLoginButton from 'michelangelo/dist/Components/Buttons/AlfredLoginButton'
import theme from 'michelangelo/dist/Components/styles/theme'
import FixedLabelInput from 'michelangelo/dist/Components/FixedLabelInput'
import { Mutation } from '@apollo/client/react/components'
import { GetCachedApplicationByID, UpdateApplicationToCache } from '../../queries/application'
import { messages } from '../../i18n/messages'
import { defineMessages, injectIntl } from 'react-intl'
import { ApolloClient, gql } from '@apollo/client'
import color from '../../styles/color'
import withRouter from '../../utils/withRouter'
import { cloneDeep } from 'lodash'

const Container: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-rows: auto 50px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    min-height: 500px;
    align-items: center;
    justify-items: center;
    min-width: 352px;
    padding: 12px;

    @media screen and (max-width: 600px){
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-gap: 20px;
    }
`
const ButtonsContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-self: center;
    grid-gap: 16px;

    @media screen and (min-width: 600px){
                grid-column: 1/3;
                grid-gap: 24px;
    }
`
const CustomizeInputContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-rows: 40px auto;
    grid-template-columns: 1fr;
    width: 60%;
    justify-self: start;

    @media screen and (max-width: 600px){
      justify-self: center;
      align-items: center;
      width: 320px;
    }
`

const PreviewContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    width: inherit;
    justify-items: center;

    @media screen and (max-width: 600px){
      max-width: 400px;
    }
`
const PreviewButtonsContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    width: 100%;
    max-width: 320px;
    grid-gap: 10px;
    justify-items: center;
`

const SAVE_BUTTONS_FIELDS = gql`
  mutation setCustomTexts($application:String!, $buttonSSO: String, $buttonOpenRegistration: String, $textOpenRegistration: String ) {
    setCustomTexts(application: $application, btnSingleSignOn: $buttonSSO, btnOpenReg: $buttonOpenRegistration, btnNotEmployee: $textOpenRegistration ) {
     _id
      customTexts {
        loginOpenRegPlaceholder
        loginRegCodePlaceholder
        registerRegCodeAccountPlaceholder
        registerRegCodeTooltip
        registerRegCodeEmailPlaceholder
        registerOpenRegEmailPlaceholder
        btnSingleSignOn
        btnOpenReg
        btnNotEmployee
        btnCreateOrLogin
        }
      }
  }
`

// add for testing purpose
Container.displayName = 'Container'
ButtonsContainer.displayName = 'ButtonsContainer'
CustomizeInputContainer.displayName = 'CustomizeInputContainer'
PreviewContainer.displayName = 'PreviewContainer'
PreviewButtonsContainer.displayName = 'PreviewButtonsContainer'

type ButtonsProps = {
    application: Object,
    params: Object,
    client: ApolloClient
} & InjectIntlProvidedProps

type ButtonsState = {
    ssoInputValue: string,
    openRegistrationInputValue: string,
    notAnEmployee: string,
    placeholder: Object
}

export const ButtonsMessages = defineMessages({
  notAnEmployee: {
    id: 'customButtons.notAnEmployee',
    defaultMessage: 'Not an employee?'
  },
  sso: {
    id: 'customButtons.sso',
    defaultMessage: 'Single Sign-On'
  },
  openRegistration: {
    id: 'customButtons.openRegistration',
    defaultMessage: 'Open Registration'
  }
})

class ButtonsComponent extends Component<ButtonsProps, ButtonsState> {
  constructor (props: ButtonsProps) {
    super(props)

    const { intl: { formatMessage }, application: { customTexts } } = this.props
    const btnSingleSignOn = customTexts ? customTexts.btnSingleSignOn : ''
    const btnOpenReg = customTexts ? customTexts.btnOpenReg : ''
    const btnNotEmployee = customTexts ? customTexts.btnNotEmployee : ''
    this.state = {
      disableSave: true,
      ssoInputValue: '',
      openRegistrationInputValue: '',
      notAnEmployee: '',
      placeholder: {
        ssoInputValue: btnSingleSignOn || formatMessage(ButtonsMessages.sso),
        openRegistrationInputValue: btnOpenReg || formatMessage(ButtonsMessages.openRegistration),
        notAnEmployee: btnNotEmployee || formatMessage(ButtonsMessages.notAnEmployee)
      }
    }
  }

  getPreviewButtons = () => {
    const { application: { openRegistrationEnabled, ssoEnabled } } = this.props
    const { ssoInputValue, openRegistrationInputValue, notAnEmployee, placeholder } = this.state

    let ssoButton
    let openRegistrationButton

    if (ssoEnabled) {
      ssoButton = <AlfredLoginButton outline title={ssoInputValue || placeholder.ssoInputValue} />
    }

    if (openRegistrationEnabled) {
      openRegistrationButton = (
        <div>
          <Text text={notAnEmployee || placeholder.notAnEmployee} fontColor={color.disabled} textSize='p' align='center' />
          <AlfredLoginButton title={openRegistrationInputValue || placeholder.openRegistrationInputValue} />
        </div>
      )
    }

    return (
      <PreviewButtonsContainer>
        {ssoButton}
        {openRegistrationButton}
      </PreviewButtonsContainer>
    )
  }

  getInputs = () => {
    const { application: { openRegistrationEnabled, ssoEnabled } } = this.props
    const { ssoInputValue, openRegistrationInputValue, notAnEmployee, placeholder } = this.state

    let ssoInput
    let openRegistrationInput
    let notAnEmployeeInput

    if (ssoEnabled) {
      ssoInput = <FixedLabelInput value={ssoInputValue} placeholder={placeholder.ssoInputValue} uppercase onChange={this.handleSSO} />
    }

    if (openRegistrationEnabled) {
      notAnEmployeeInput = <FixedLabelInput value={notAnEmployee} placeholder={placeholder.notAnEmployee} onChange={this.handleNotAnEmployee} />
      openRegistrationInput = <FixedLabelInput value={openRegistrationInputValue} placeholder={placeholder.openRegistrationInputValue} uppercase onChange={this.handleOpenRegistration} />
    }

    return (
      <PreviewButtonsContainer>
        {ssoInput}
        {notAnEmployeeInput}
        {openRegistrationInput}
      </PreviewButtonsContainer>
    )
  }

  handleSSO = (value: string) => {
    this.setState({
      ssoInputValue: value,
      disableSave: false
    })
  }

  handleOpenRegistration = (value: string) => {
    this.setState({
      openRegistrationInputValue: value,
      disableSave: false
    })
  }

  handleNotAnEmployee = (value: string) => {
    this.setState({
      notAnEmployee: value,
      disableSave: false
    })
  }

  resetInputValues = () => {
    this.setState({
      ssoInputValue: '',
      openRegistrationInputValue: '',
      notAnEmployee: '',
      disableSave: true
    })
  }

  onMutationCompleted = () => {
    const { customTexts: { btnSingleSignOn, btnOpenReg, btnNotEmployee } } = GetCachedApplicationByID(this.props.client, this.props.params.id || '')

    this.setState({
      disableSave: true,
      placeholder: {
        ...(btnSingleSignOn && { ssoInputValue: btnSingleSignOn }),
        ...(btnOpenReg && { openRegistrationInputValue: btnOpenReg }),
        ...(btnNotEmployee && { notAnEmployee: btnNotEmployee })
      }
    })

    // this.resetInputValues()
  }

  handleOnCancelClick = () => {
    this.resetInputValues()
  }

  SaveButton = () => {
    const { intl: { formatMessage }, application } = this.props
    const { ssoInputValue, openRegistrationInputValue, notAnEmployee, disableSave } = this.state

    const mutationVariables = {
      application: application._id,
      ...(ssoInputValue ? { buttonSSO: ssoInputValue } : {}),
      ...(openRegistrationInputValue ? { buttonOpenRegistration: openRegistrationInputValue } : {}),
      ...(notAnEmployee ? { textOpenRegistration: notAnEmployee } : {})
    }

    return (
      <Mutation
        mutation={SAVE_BUTTONS_FIELDS}
        variables={mutationVariables}
        notifyOnNetworkStatusChange
        onCompleted={() => this.onMutationCompleted()}
        update={(cache, result) => {
          const data = cloneDeep({ ...result.data.setCustomInfo, ...result.data.setCustomTexts })
          if (mutationVariables.textOpenRegistration) data.customTexts.btnNotEmployee = mutationVariables.textOpenRegistration
          if (mutationVariables.buttonOpenRegistration) data.customTexts.btnOpenReg = mutationVariables.buttonOpenRegistration
          if (mutationVariables.buttonSSO) data.customTexts.btnSingleSignOn = mutationVariables.buttonSSO
          if (mutationVariables.customInfo) data.customInfo = { ...data.customInfo, ...mutationVariables.customInfo }
          UpdateApplicationToCache(cache, data)
        }}
      >
        {(setCustomTexts, { data, loading }) => (
          <Button fill title={formatMessage(messages.saveChanges)} disabled={loading || disableSave} onClick={() => setCustomTexts(mutationVariables)} />
        )}
      </Mutation>
    )
  }

  render () {
    const { intl: { formatMessage } } = this.props
    const buttons = this.getPreviewButtons()
    const inputs = this.getInputs()

    return (
      <Container>

        <CustomizeInputContainer>
          <Text text={formatMessage(messages.customizeText)} fontColor={theme.info} textSize='p' />
          {inputs}
        </CustomizeInputContainer>

        <PreviewContainer>
          <PreviewBox>
            {{
              Buttons: buttons
            }}
          </PreviewBox>
        </PreviewContainer>

        <ButtonsContainer>
          <Button outline title={formatMessage(messages.cancel)} onClick={this.handleOnCancelClick} />
          {this.SaveButton()}
        </ButtonsContainer>

      </Container>
    )
  }
}

export default injectIntl(withRouter(ButtonsComponent))
export { ButtonsComponent as UnwrappedButtonsComponent }
