// @flow

import React, { Component } from 'react'
import { gql } from '@apollo/client'
import type { ApolloClient, ApolloCache } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'

export const GET_APPLICATION_BY_ID = gql`
query getApplicationById($_id: String!) {
  getApplicationById(_id: $_id) {
    _id
    title
    iosBundleId
    androidBundleId
    type
    versions
    subDomain
    ssoEnabled
    openRegistrationEnabled
    registrationCodeEnabled
    hrisIntegrations {
      hrisId: id
      enabled
      webUrl
    }
    customTexts {
      loginOpenRegPlaceholder
      loginRegCodePlaceholder
      registerRegCodeAccountPlaceholder
      registerRegCodeTooltip
      registerRegCodeEmailPlaceholder
      registerOpenRegEmailPlaceholder
      btnSingleSignOn
      btnOpenReg
      btnNotEmployee
      btnCreateOrLogin
    }
    customInfo {
      openReg {
        email {
          hasCustomInfo
          customInfoData
        }
        password {
          hasCustomInfo
          customInfoData
        }
        
      }
      regCode {
        email {
          hasCustomInfo
          customInfoData
        }
        password {
          hasCustomInfo
          customInfoData
        }
      }
    
    }
    deviceName
    appleDistribution
    distribution
    isSecurityLockMandatory
    passcodeLength
    bottomNavigationOption
    exploreBannerOption
  }
}
`

export const GET_APPLICATION_BY_IDS = gql`
query getApplicationByIds($ids: [String!]!) {
  getApplicationByIds(ids: $ids) {
    _id
    title
    iosBundleId
    androidBundleId
    type
    versions
    subDomain
    ssoEnabled
    openRegistrationEnabled
    registrationCodeEnabled
    hrisIntegrations {
      hrisId: id
      enabled
      webUrl
    }
    customTexts {
      loginOpenRegPlaceholder
      loginRegCodePlaceholder
      registerRegCodeAccountPlaceholder
      registerRegCodeTooltip
      registerRegCodeEmailPlaceholder
      registerOpenRegEmailPlaceholder
      btnSingleSignOn
      btnOpenReg
      btnNotEmployee
      btnCreateOrLogin
    }
    customInfo {
      openReg {
        email {
          hasCustomInfo
          customInfoData
        }
        password {
          hasCustomInfo
          customInfoData
        }
        
      }
      regCode {
        email {
          hasCustomInfo
          customInfoData
        }
        password {
          hasCustomInfo
          customInfoData
        }
      }
    
    }
    deviceName
    appleDistribution
    distribution
    isSecurityLockMandatory
    passcodeLength
    bottomNavigationOption
    exploreBannerOption
  }
}
`

export const GET_BUILD_PARAMS = gql`
query getBuildParams($_id: String!) {
  getBuildParams(_id: $_id) {
      appName
      environment
      distribution
      accountId
      accountShortCode
      accountColor
      theme
      applicationId
      clientId
      clientSecret,
      grantCode
      identity
      devTeam
      appleId
      provision
      onesignal
      subDomain
      iosBundleId
      androidBundleId
      supportEmailAddress
  }
}
`

export const SET_HRISINTEGRATIONS = gql`
  mutation setHRISIntegrations($application:String!,$hrisIntegrations:[HRISIntegrationInput]!) {
    setHRISIntegrations(application: $application,hrisIntegrations: $hrisIntegrations) {
      _id,
      hrisIntegrations {
        hrisId: id
        enabled
        webUrl
      }
    }
  }
`

export const SET_MOBILELOCK_DETAILS = gql`
mutation setMobileLockDetails($application:String!,$account:String!, $isSecurityLockMandatory: Boolean!, $passcodeLength: Int) {
  setMobileLockDetails(application: $application,account: $account, isSecurityLockMandatory: $isSecurityLockMandatory, passcodeLength: $passcodeLength) {
    _id
    isSecurityLockMandatory
    passcodeLength
  }
}
`

export const SET_BOTTOMNAVIGATIONOPTION = gql`
  mutation setBottomNavigationOption($application:String!,$bottomNavigationOption:String!) {
    setBottomNavigationOption(application: $application,bottomNavigationOption: $bottomNavigationOption) {
      _id,
      bottomNavigationOption
    }
  }
`
export const SET_EXPLORE_BANNER_OPTION = gql`
mutation setExploreBannerOption($application:String!,$account:String!, $exploreBannerOption: String!) {
  setExploreBannerOption(application: $application,account: $account, exploreBannerOption: $exploreBannerOption) {
    _id
    exploreBannerOption
  }
}
`

type GetApplicationProps = {
    children: Function,
    _id: string
  }

export class GetApplicationByID extends Component<GetApplicationProps, {}> {
  render () {
    return (
      <Query
        query={GET_APPLICATION_BY_ID}
        variables={{ _id: this.props._id }}
        notifyOnNetworkStatusChange
        fetchPolicy='cache-and-network'
        displayName='Application'
      >
        {({ loading, error, data, refetch, networkStatus, client }) => {
          if (!(data && data.getApplicationById)) return null

          return this.props.children(data.getApplicationById, client)
        }}
      </Query>
    )
  }
}
export const GetCachedApplicationByID = (client: ApolloClient, id: string) => {
  const { getApplicationById } = client.readQuery({
    query: GET_APPLICATION_BY_ID,
    variables: {
      _id: id
    }
  })

  return getApplicationById
}
export const GetAsyncApplicationByID = async (client: ApolloClient, id: string) => {
  const { data: { getApplicationById } } = await client.query({
    query: GET_APPLICATION_BY_ID,
    fetchPolicy: 'network-only',
    variables: {
      _id: id
    }
  })
  return getApplicationById
}

export const AddApplicationToCache = (cache: ApolloCache, application: any) => {
  cache.writeQuery({
    query: GET_APPLICATION_BY_ID,
    variables: { _id: application._id },
    data: application
  })
}

export const UpdateApplicationToCache = (cache: ApolloCache, application: Object) => {
  const cachedData = cache.readQuery({
    query: GET_APPLICATION_BY_ID,
    variables: {
      _id: application._id
    }
  })

  const data = cloneDeep(cachedData)
  if (!data || !data.getApplicationById) {
    return
  }
  cache.writeQuery({
    query: GET_APPLICATION_BY_ID,
    variables: { _id: application._id },
    data: {
      ...data,
      getApplicationById: {
        ...data.getApplicationById,
        ...application
      }
    }
  })
}
