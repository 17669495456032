/* eslint-disable react/prop-types */
// @flow
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import Checkbox from '../../Components/Checkbox'
import FixedLabelInput from 'michelangelo/dist/Components/FixedLabelInput'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import theme from 'michelangelo/dist/Components/styles/theme'
import { Mutation } from '@apollo/client/react/components'
import { AlertModal } from '../../Components'
import { messages } from '../../i18n/messages'
import color from '../../styles/color'
import { withApollo } from '@apollo/client/react/hoc'
import { useNavigate, useParams } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import gql from 'graphql-tag'
import Notification from 'michelangelo/dist/Components/Notification'
import { useApolloClient } from '@apollo/client'

const UPDATE_SMS_SETTINGS = gql`
  mutation updateTwilioObject($accountId: String!, $smsEnabled: Boolean!, $twilio: TwilioObject!) {
    updateTwilioObject(accountId: $accountId, smsEnabled: $smsEnabled, twilio: $twilio) {
      success
    }
  }
`

const GET_SMS_SETTINGS = gql`
query getTwilioObject($accountId: String!) {
  getTwilioObject(accountId: $accountId) {
    smsEnabled,
    twilio {
      twilioSubaccountSid,
      twilioSubaccountAuthToken,
      twilioPhoneNumber
        {
          alias,
          phoneNumber
        }
    }
  }
}
`

const HeaderContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  text-align: center;
  height: 24rem;
  background-color: white;
  justify-content: space-between;
  position: relative;
`

const FormContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionButtonsContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: -5rem;
`

const ActionButtonItem = styled(RoundedButton)`
  margin: 0 !important;
  cursor: default !important;
`

const CheckboxWrapper: ReactComponentStyled<{}> = styled.div`
  display: flex;
  margin-bottom: 57px;
`

const ValidationWrapper: ReactComponentStyled<{}> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 1rem;
`

const ValidationMessage: ReactComponentStyled<{}> = styled.span`
  color: red;
  font-size: 12px;
  align-self: flex-end;
  position: relative;
  right: 2rem;
`

const RequiredValidationMessage: ReactComponentStyled<{}> = styled.span`
  color: red;
  font-size: 16px;
`

const InputWrapper: ReactComponentStyled<{}> = styled.div`
  display: flex;
  gap: 2rem;
  color: #BABABA;
`
const InputContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`

const LabelsWrapper: ReactComponentStyled<{}> = styled.div`
  display: flex;
  flex-direction: column;
  gap:.5rem;
`

const InputsWrapper: ReactComponentStyled<{}> = styled.div`
  display: flex;
  flex-direction: column;
  width: 344px;
`
const ErrorInputWrapper: ReactComponentStyled<{}> = styled.div`
  border-bottom: ${(props) => props.error && '1px solid red'};
  width: 320px;
`

export const SmsSettings = ({ intl: { formatMessage } }) => {
  const client = useApolloClient()
  const { accountId } = useParams()
  const [smsEnabled, setSmsEnabled] = useState(false)
  const [formValues, setFormValues] = useState({
    twilioSubaccountSid: '',
    twilioSubaccountAuthToken: '',
    twilioPhoneNumber: {
      alias: 'default',
      phoneNumber: ''
    }
  })
  const [phoneValidationError, setPhoneValidationError] = useState(false)
  const [success, setSuccess] = useState(false)
  const fieldsCompleted = smsEnabled && !Object.values(formValues).includes('') && !Object.values(formValues.twilioPhoneNumber).includes('')
  const [saveButtonDisabled, setSaveButtonDisabled] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (!smsEnabled) {
      setSaveButtonDisabled(false)
    } else if (smsEnabled && fieldsCompleted) {
      setSaveButtonDisabled(false)
    } else {
      setSaveButtonDisabled(true)
    }
    // eslint-disable-next-line
  }, [formValues, smsEnabled])

  const getData = async () => {
    const { data } = await client.query({
      query: GET_SMS_SETTINGS,
      variables: { accountId },
      fetchPolicy: 'network-only'

    })

    if (!data) {
      return
    }
    if (data?.getTwilioObject) {
      setFormValues({
        twilioSubaccountSid: data?.getTwilioObject?.twilio?.twilioSubaccountSid || '',
        twilioSubaccountAuthToken: data?.getTwilioObject?.twilio?.twilioSubaccountAuthToken || '',
        twilioPhoneNumber: {
          phoneNumber: (data?.getTwilioObject?.twilio?.twilioPhoneNumber?.length > 0 &&
          data.getTwilioObject.twilio.twilioPhoneNumber[0].phoneNumber) || ''
        }
      })
      setSmsEnabled(!!data?.getTwilioObject.smsEnabled)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [success])

  const checkBoxChangeHandler = (label, value) => {
    setSmsEnabled(value)
  }

  const handleSidInputChange = (value) => {
    setFormValues({
      ...formValues,
      twilioSubaccountSid: value
    })
  }

  const handleSubaccountAuthToken = (value) => {
    setFormValues({
      ...formValues,
      twilioSubaccountAuthToken: value
    })
  }

  const handlePhoneNrInputChange = (value) => {
    setFormValues({
      ...formValues,
      twilioPhoneNumber: {
        phoneNumber: value
      }
    })
  }

  const handleCancel = () => {
    navigate(0)
  }

  const handleSave = (updateTwilioObject) => {
    if (smsEnabled && !formValues.twilioPhoneNumber.phoneNumber.match(/^\+[1-9]\d{10,10}$/)) {
      return setPhoneValidationError(true)
    } else {
      setPhoneValidationError(false)
    }

    const twilio = {
      twilioSubaccountSid: formValues.twilioSubaccountSid,
      twilioSubaccountAuthToken: formValues.twilioSubaccountAuthToken,
      twilioPhoneNumber: [
        {
          alias: 'default',
          twilioPhoneNumber: formValues.twilioPhoneNumber.phoneNumber
        }
      ]
    }

    const payload = {
      twilio,
      smsEnabled,
      accountId
    }
    updateTwilioObject(payload)
  }

  const handleUpdateCompleted = () => {
    setSuccess(true)
  }

  const handleModalClose = () => {
    setSuccess(false)
  }

  const getModal = () => {
    if (!success) {
      return null
    }

    const modalText = messages.smsSettingsUpdateSuccess
    return (
      <AlertModal
        buttonAction={() => handleModalClose()}
        icon='checkCircle'
        buttonText={formatMessage(messages.ok)}
        headerText={formatMessage(messages.smsSettingsUpdateSuccessHeader)}
        modalText={formatMessage(modalText)}
        modalTheme={color.success}
        modalFontColor='success'
      />
    )
  }

  const getServerErrorMessage = (error: any) => {
    if (!error || !error.graphQLErrors) {
      return null
    }
    let message
    if (error.graphQLErrors.length === 0) {
      message = formatMessage(messages.unexpectedError)
    } else if (error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
      message = error.graphQLErrors[0].extensions.exception.details
    } else {
      message = formatMessage(messages.unexpectedError)
    }
    return <Notification show message={message} autoClose={5} type='error' />
  }

  const renderSaveButton = () => {
    const twilio = {
      twilioSubaccountSid: formValues?.twilioSubaccountSid,
      twilioSubaccountAuthToken: formValues?.twilioSubaccountAuthToken,
      twilioPhoneNumber: [
        {
          alias: 'default',
          phoneNumber: formValues?.twilioPhoneNumber?.phoneNumber
        }
      ]
    }

    const mutationVariables = {
      twilio,
      smsEnabled,
      accountId
    }

    return (
      <Mutation
        variables={mutationVariables}
        mutation={UPDATE_SMS_SETTINGS}
        onCompleted={() => handleUpdateCompleted()}
        onError={(e) => getServerErrorMessage(e)}
      >
        {(updateTwilioObject, { data, loading, error }) => (
          getBody(updateTwilioObject, (data) ? data.updateTwilioObject : null, loading, error)
        )}
      </Mutation>
    )
  }

  const getBody = (updateTwilioObject, data: any, loading, error) => {
    return (
      <>
        {getServerErrorMessage(error)}
        <ActionButtonItem testID='SmsSettingsSaveButton' width='130px' disabled={saveButtonDisabled} onClick={() => handleSave(updateTwilioObject)} theme={!saveButtonDisabled ? theme.info : theme.disabled} textTheme={theme.info} fill style={{ margin: '0', cursor: !saveButtonDisabled ? 'pointer' : 'default' }}>{formatMessage(messages.smsSettingsSave)}</ActionButtonItem>
      </>
    )
  }

  return (
    <>
      <HeaderContainer>
        <FormContainer>
          <CheckboxWrapper>
            <Checkbox testID='SmsCheckbox' label={formatMessage(messages.smsSettingsEnableSms)} isChecked={smsEnabled} handleCheckboxChange={checkBoxChangeHandler} />
          </CheckboxWrapper>
          {smsEnabled &&
            <InputContainer>
              <LabelsWrapper>
                <InputWrapper>
                  <div>
                    {formatMessage(messages.twilioSubaccountSid)}<RequiredValidationMessage>*</RequiredValidationMessage>
                  </div>
                </InputWrapper>
                <InputWrapper>
                  <div>
                    {formatMessage(messages.twilioSubaccountAuthToken)}<RequiredValidationMessage>*</RequiredValidationMessage>
                  </div>
                </InputWrapper>
                <InputWrapper>
                  <div>
                    {formatMessage(messages.twilioPhoneNumber)}<RequiredValidationMessage>*</RequiredValidationMessage>
                  </div>
                </InputWrapper>
              </LabelsWrapper>
              <InputsWrapper>
                <FixedLabelInput testID='twilioSubaccountSid' value={formValues?.twilioSubaccountSid} onChange={handleSidInputChange} style={{ width: '100%' }} />
                <FixedLabelInput testID='twilioSubaccountAuthToken' value={formValues?.twilioSubaccountAuthToken} onChange={handleSubaccountAuthToken} style={{ width: '100%' }} />
                <ErrorInputWrapper error={phoneValidationError}>
                  <FixedLabelInput testID='twilioPhoneNumber' value={formValues?.twilioPhoneNumber?.phoneNumber} onChange={handlePhoneNrInputChange} style={{ width: '100%' }} />
                </ErrorInputWrapper>
              </InputsWrapper>
              {phoneValidationError && smsEnabled && <ValidationMessage>{formatMessage(messages.twilioPhoneNumberValidationMessage)}</ValidationMessage>}
            </InputContainer>}
        </FormContainer>
        {smsEnabled &&
          <ValidationWrapper>
            <RequiredValidationMessage>*{formatMessage(messages.smsSettingsRequired)}</RequiredValidationMessage>
          </ValidationWrapper>}
        <ActionButtonsContainer>
          <ActionButtonItem width='130px' onClick={handleCancel} theme={theme.disabled} textTheme={theme.disabled} disabled={false} style={{ margin: '0' }}>Cancel</ActionButtonItem>
          {renderSaveButton()}
        </ActionButtonsContainer>
      </HeaderContainer>
      {getModal()}
    </>
  )
}

export default withApollo<SmsSettingsProps>(injectIntl(SmsSettings))
