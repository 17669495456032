// @flow
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
import type { RouterHistory, Match } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ApolloClient, useApolloClient, useQuery } from '@apollo/client'
import Tabs from '../../Components/RouterTabs'
import { GET_APPLICATION_BY_ID } from '../../queries/application'
import NewAppBuild from './NewAppBuild'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

type NewBuildProps = {
  match: Match,
  history: RouterHistory,
  client: ApolloClient
} & InjectIntlProvidedProps

const NewBuild = (props: NewBuildProps) => {
  const params = useParams()
  const client = useApolloClient()
  const { intl: { formatMessage }, history } = props
  const [application, setApplication] = useState(null)

  const id = params.id || ''
  const accountId = params.accountId || ''

  const { loading, data: app } = useQuery(GET_APPLICATION_BY_ID, {
    variables: {
      _id: id
    },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (app && app.getApplicationById) {
      setApplication(app.getApplicationById)
    }
  }, [app])

  const getBody = (application) => {
    const basePath = isEmpty(accountId) ? '/all-applications/application' : `/all-accounts/view/${accountId}/all-applications/application`
    /* istanbul ignore next */
    const tabListArray = [
      {
        label: formatMessage(messages.newAppBuild),
        path: `${basePath}/${id}/build/NewAppBuild`,
        component: <NewAppBuild {...props} application={app} client={client} />
      }
    ]
    return tabListArray
  }
  /* istanbul ignore next */
  if (loading && !application) {
    return 'loading'
  }
  const tabListArray = getBody(application)
  return <Tabs tabsArray={tabListArray} history={history} />
}
export default injectIntl(withRouter(NewBuild))
export { NewBuild as UnwrappedNewBuild }
