// @flow

import { defineMessages } from 'react-intl'
/**
 * These are global level text!
 * Only text that needs to be shared
 * through the app belongs in here.
 */
export const messages = defineMessages({
  allApplications: {
    id: 'nav.allApplications',
    defaultMessage: 'All Applications'
  },
  allAccounts: {
    id: 'nav.allAccounts',
    defaultMessage: 'All Accounts'
  },
  allReports: {
    id: 'nav.allReports',
    defaultMessage: 'All Reports'
  },
  configureAccount: {
    id: 'nav.configureAccount',
    defaultMessage: 'Configure Account'
  },
  createAccount: {
    id: 'nav.createAccount',
    defaultMessage: 'Account Details'
  },
  home: {
    id: 'nav.home',
    defaultMessage: 'Home'
  },
  applicationSettings: {
    id: 'nav.applicationSettings',
    defaultMessage: 'Application Settings'
  },
  registrationOptions: {
    id: 'nav.registrationOptions',
    defaultMessage: 'Registration Options'
  },
  UltiPro: {
    id: 'nav.ultiPro',
    defaultMessage: 'UKG Pro'
  },
  customization: {
    id: 'nav.customization',
    defaultMessage: 'Customization'
  },
  cancel: {
    id: 'app.cancel',
    defaultMessage: 'Cancel'
  },
  saveChanges: {
    id: 'app.saveChanges',
    defaultMessage: 'Save Changes'
  },
  confirmChanges: {
    id: 'app.confirmChanges',
    defaultMessage: 'Confirm Changes'
  },
  logout: {
    id: 'app.logout',
    defaultMessage: 'Logout'
  },
  hrisIntegrations: {
    id: 'nav.hrisIntegration',
    defaultMessage: 'App Integrations'
  },
  hrisIntegration: {
    id: 'nav.hrisIntegrations',
    defaultMessage: 'App Integration'
  },
  allhrisIntegrations: {
    id: 'nav.allHrisIntegration',
    defaultMessage: 'All App Integrations'
  },
  dashboard: {
    id: 'hris.dashboard',
    defaultMessage: 'Dashboard'
  },
  ADP: {
    id: 'hris.adp',
    defaultMessage: 'ADP'
  },
  Workforce: {
    id: 'hris.WorkForce',
    defaultMessage: 'WorkForce'
  },
  Workday: {
    id: 'hris.WorkDay',
    defaultMessage: 'WorkDay'
  },
  Cigna: {
    id: 'hris.Cigna',
    defaultMessage: 'Cigna'
  },
  Certify: {
    id: 'hris.Certify',
    defaultMessage: 'Certify'
  },
  Kronos: {
    id: 'hris.kronos',
    defaultMessage: 'UKG Workforce Central'
  },
  MicrosoftTeams: {
    id: 'hris.microsoftTeams',
    defaultMessage: 'Microsoft Teams'
  },
  Concur: {
    id: 'hris.concur',
    defaultMessage: 'Concur'
  },
  BambooHR: {
    id: 'hris.bambooHr',
    defaultMessage: 'Bamboo HR'
  },
  Valiant: {
    id: 'hris.valiant',
    defaultMessage: 'Valiant'
  },
  Yammer: {
    id: 'hris.yammer',
    defaultMessage: 'Yammer'
  },
  PeopleSoft: {
    id: 'hris.PeopleSoft',
    defaultMessage: 'PeopleSoft'
  },
  SuccessFactors: {
    id: 'hris.SuccessFactors',
    defaultMessage: 'SuccessFactors'
  },
  hrisDashboard: {
    id: 'hris.hrisDashboard',
    defaultMessage: 'App Integration Dashboard'
  },
  appBuild: {
    id: 'nav.appBuild',
    defaultMessage: 'App Build'
  },
  settingsOne: {
    id: 'nav.settingsOne',
    defaultMessage: 'Settings One'
  },
  anotherSetting: {
    id: 'nav.anotherSetting',
    defaultMessage: 'Another setting'
  },
  shortMenu: {
    id: 'nav.shortMenu',
    defaultMessage: 'Short menu'
  },
  customerCare: {
    id: 'customerCare',
    defaultMessage: 'Customer Care Representative'
  },
  accountAdministratorDetails: {
    id: 'account.AdminDetails',
    defaultMessage: 'Account Administrator Details'
  },
  accountDetails: {
    id: 'account.Details',
    defaultMessage: 'Account Details'
  },
  createNewAccount: {
    id: 'account.CreateAccount',
    defaultMessage: 'Create Account'
  },
  administratorName: {
    id: 'account.administratorName',
    defaultMessage: 'Administrator Name'
  },
  administratorEmail: {
    id: 'account.administratorEmail',
    defaultMessage: 'Administrator Email'
  },
  administratorPassword: {
    id: 'account.administratorPassword',
    defaultMessage: 'Administrator Password'
  },
  accountName: {
    id: 'account.accountName',
    defaultMessage: 'Account Name'
  },
  clientSuccessEmail: {
    id: 'account.clientSuccessEmail',
    defaultMessage: 'Client Success Email'
  },
  saveAccount: {
    id: 'account.saveAccount',
    defaultMessage: 'Create'
  },
  eightChars: {
    id: 'requirementPassword.eightChars',
    defaultMessage: '8 characters'
  },
  upperCase: {
    id: 'requirementPassword.upperCase',
    defaultMessage: '1 upper case letter'
  },
  lowerCase: {
    id: 'requirementPassword.lowerCase',
    defaultMessage: '1 lower case letter'
  },
  number: {
    id: 'requirementPassword.number',
    defaultMessage: '1 number'
  },
  specialChar: {
    id: 'requirementPassword.specialChar',
    defaultMessage: '1 special character'
  },
  alphaCharacter: {
    id: 'alert.alphaCharacter',
    defaultMessage: 'Lower and upper case letter'
  },
  numberCharacter: {
    id: 'alert.numberCharacter',
    defaultMessage: 'Number'
  },
  specialCharacter: {
    id: 'alert.specialCharacter',
    defaultMessage: 'Special characters'
  },
  emptyField: {
    id: 'createAccount.emptyField',
    defaultMessage: 'You must enter a value in this field'
  },
  emailField: {
    id: 'createAccount.emailField',
    defaultMessage: 'You must enter a valid email in this field'
  },
  validField: {
    id: 'createAccount.validField',
    defaultMessage: 'Please enter a valid value for the field'
  },
  accountNameTaken: {
    id: 'createAccount.AccountNameTaken',
    defaultMessage: 'Account name already taken, please try another name'
  },
  createNewAppIntegration: {
    id: 'masterAppIntegrationForm.createNewAppIntegration',
    defaultMessage: 'Create new App'
  },
  configureMasterAppIntegration: {
    id: 'masterAppIntegrationForm.configureMasterAppIntegration',
    defaultMessage: 'Configure App Integration'
  },
  updateMasterAppIntegration: {
    id: 'masterAppIntegrationForm.updateMasterAppIntegration',
    defaultMessage: 'Update App'
  },
  masterAppIntegrationSaveButton: {
    id: 'masterAppIntegrationForm.masterAppIntegrationSaveButton',
    defaultMessage: 'Save'
  },
  masterAppIntegrationNameTaken: {
    id: 'masterAppIntegrationForm.masterAppIntegrationNameTaken',
    defaultMessage: 'Application name already taken, please try another name'
  },
  masterAppIntegrationNameIsRequired: {
    id: 'masterAppIntegrationForm.masterAppIntegrationNameIsRequired',
    defaultMessage: 'Application name cannot be empty'
  },
  masterAppIntegrationMinTwoChars: {
    id: 'masterAppIntegrationForm.masterAppIntegrationMinTwoChars',
    defaultMessage: 'Application name must have at least two characters'
  },
  masterAppIntegrationLogoIsRequired: {
    id: 'masterAppIntegrationForm.masterAppIntegrationLogoIsRequired',
    defaultMessage: 'Logo is required'
  },
  masterAppIntegrationWebUrlRequired: {
    id: 'masterAppIntegrationForm.masterAppIntegrationWebUrlRequired',
    defaultMessage: 'Web url is required'
  },
  masterAppIntegrationLinkUrlRequired: {
    id: 'masterAppIntegrationForm.masterAppIntegrationLinkUrlRequired',
    defaultMessage: 'One of the links is required'
  },
  masterAppIntegrationDetails: {
    id: 'masterAppIntegrationForm.masterAppIntegrationDetails',
    defaultMessage: 'App Details'
  },
  masterAppIntegrationCreating: {
    id: 'masterAppIntegrationForm.masterAppIntegrationCreating',
    defaultMessage: 'Creating App'
  },
  masterAppIntegrationUpdating: {
    id: 'masterAppIntegrationForm.masterAppIntegrationUpdating',
    defaultMessage: 'Updating App'
  },
  masterAppIntegrationLinks: {
    id: 'masterAppIntegrationForm.masterAppIntegrationLinks',
    defaultMessage: 'App Links'
  },
  masterAppIntegrationUpdateSuccess: {
    id: 'masterAppIntegrationForm.masterAppIntegrationUpdateSuccess',
    defaultMessage: 'App Integration updated successfully'
  },
  masterAppIntegrationExists: {
    id: 'masterAppIntegrationForm.masterAppIntegrationExists',
    defaultMessage: 'App Integration with this name already exists.'
  },
  masterAppIntegrationCreateSuccess: {
    id: 'masterAppIntegrationForm.masterAppIntegrationCreateSuccess',
    defaultMessage: 'App Integration created successfully'
  },
  masterAppIntegrationCreateSuccessHeader: {
    id: 'masterAppIntegrationForm.masterAppIntegrationCreateSuccessHeader',
    defaultMessage: 'Success'
  },
  masterAppIntegrationCreateErrorHeader: {
    id: 'masterAppIntegrationForm.masterAppIntegrationCreateErrorHeader',
    defaultMessage: 'Error'
  },
  emailTaken: {
    id: 'createAccount.emailTaken',
    defaultMessage: 'Email already taken, please try another email'
  },
  ok: {
    id: 'createAccount.SuccessOk',
    defaultMessage: 'Ok'
  },
  accountCreateSuccess: {
    id: 'createAccount.success',
    defaultMessage: 'Account Created Successfully'
  },
  accountUpdateSuccess: {
    id: 'createAccount.successUpdate',
    defaultMessage: 'Account Updated Successfully'
  },
  accountMinTwoChars: {
    id: 'createAccount.accountMinTwoChars',
    defaultMessage: 'The account should be at least two characters long'
  },
  applicationMinTwoChars: {
    id: 'createApplication.accountMinTwoChars',
    defaultMessage: 'The application name should be at least two characters long'
  },
  accountCreateSuccessHeader: {
    id: 'createAccount.successHeader',
    defaultMessage: 'Success'
  },
  creatingAccount: {
    id: 'createAccount.creatingAccount',
    defaultMessage: 'Creating New Account...'
  },
  updatingAccount: {
    id: 'createAccount.updatingAccount',
    defaultMessage: 'Updating Account Data...'
  },
  unexpectedError: {
    id: 'createAccount.unexpected',
    defaultMessage: 'An unexpected error has ocurred'
  },
  updateAccount: {
    id: 'createAccount.update',
    defaultMessage: 'Update'
  },
  customizeText: {
    id: 'app.customizeText',
    defaultMessage: 'Customize Text'
  },
  customizeHtmlOrText: {
    id: 'app.customizeHtmlOrText',
    defaultMessage: 'Enter text or HTML'
  },
  fieldsNotCustomizable: {
    id: 'app.fieldsNotCustomizable',
    defaultMessage: 'These fields are not customizable'
  },
  selectLoginType: {
    id: 'app.selectLoginType',
    defaultMessage: 'Select login type'
  },
  selectRegistrationType: {
    id: 'app.selectRegistrationType',
    defaultMessage: 'Select registration type'
  },
  networkError: {
    id: 'networkError',
    defaultMessage: 'Failed to connect to server, please try again!'
  },
  gettingAccountTableData: {
    id: 'accountTable.gettingData',
    defaultMessage: 'Loading Table Data...'
  },
  addNewApplication: {
    id: 'createApplication.addNewApplication',
    defaultMessage: 'Create New Application'
  },
  totalUsers: {
    id: 'accountTable.totalUsers',
    defaultMessage: 'Total Users'
  },
  totalMembers: {
    id: 'accountTable.totalMembers',
    defaultMessage: 'Total Members'
  },
  contentUploaded: {
    id: 'accountTable.contentUploaded',
    defaultMessage: 'Content Uploaded, Last 30 Days'
  },
  actions: {
    id: 'accountTable.actions',
    defaultMessage: 'Actions'
  },
  prev: {
    id: 'paginator.prev',
    defaultMessage: 'PREV'
  },
  next: {
    id: 'paginator.next',
    defaultMessage: 'NEXT'
  },
  search: {
    id: 'app.search',
    defaultMessage: 'Search ...'
  },
  applicationName: {
    id: 'createApplication.applicationName',
    defaultMessage: 'Application Name'
  },
  creatingApplication: {
    id: 'createApplication.creatingApplication',
    defaultMessage: 'Creating New Application...'
  },
  unauthorized: {
    id: 'createApplication.unauthorized',
    defaultMessage: 'You do not have permission to complete this action!'
  },
  applicationExists: {
    id: 'createApplication.ApplicationExists',
    defaultMessage: 'An application with the same name already exists, please try another one'
  },
  appDetails: {
    id: 'nav.appDetails',
    defaultMessage: 'App Details'
  },
  applicationDetails: {
    id: 'application.applicationdetails',
    defaultMessage: 'Application Details'
  },
  bitrise: {
    id: 'application.bitrise',
    defaultMessage: 'Bitrise is building your app!'
  },
  branchName: {
    id: 'application.branchName',
    defaultMessage: 'Branch Name'
  },
  build: {
    id: 'application.build',
    defaultMessage: 'Build'
  },
  oops: {
    id: 'application.oops',
    defaultMessage: 'Oops! Something went wrong.'
  },
  platform: {
    id: 'application.platform',
    defaultMessage: 'Platform'
  },
  readyToBuild: {
    id: 'application.readyToBuild',
    defaultMessage: 'Ready to build your app?'
  },
  sendingBuild: {
    id: 'application.sendingBuild',
    defaultMessage: 'Sending to Bitrise'
  },
  versionNumber: {
    id: 'application.versionNumber',
    defaultMessage: 'Version Number'
  },
  developerDetails: {
    id: 'application.developerdetails',
    defaultMessage: 'Developer Details'
  },
  deviceName: {
    id: 'applicationDetails.deviceName',
    defaultMessage: 'Device name'
  },
  subDomain: {
    id: 'applicationDetails.subDomain',
    defaultMessage: 'Subdomain'
  },
  appBuildQuestion: {
    id: 'applicationDetails.appBuildQuestions',
    defaultMessage: 'How will APPrise Mobile build the app?'
  },
  googleBundleId: {
    id: 'applicationDetails.googleBundleId',
    defaultMessage: 'Google Bundle ID'
  },
  appleBundleId: {
    id: 'applicationDetails.appleBundleId',
    defaultMessage: 'Apple Bundle ID'
  },
  save: {
    id: 'applicationDetails.save',
    defaultMessage: 'Save'
  },
  sameAsGoogle: {
    id: 'applicationDetails.sameAsGoogle',
    defaultMessage: 'Same as Google'
  },
  correctBundleId: {
    id: 'applicationDetails.correctBundleId',
    defaultMessage: 'Please enter correct Bundle ID'
  },
  success: {
    id: 'applicationDetails.success',
    defaultMessage: 'Success!'
  },
  error: {
    id: 'applicationDetails.error',
    defaultMessage: 'Error!'
  },
  applicationCreatedSuccesfully: {
    id: 'applicationDetails.applicationCreatedSuccessfully',
    defaultMessage: 'Application Created Successfully!'
  },
  errorApplicationCreation: {
    id: 'applicationDetails.errorApplicationCreation',
    defaultMessage: 'Error during Application Creation. Please try again!'
  },
  applicationSubDomainExists: {
    id: 'createApplication.applicationSubDomainExists',
    defaultMessage: 'An application with the same subdomain already exists!'
  },
  applicationAndroidBundleIdExists: {
    id: 'createApplication.applicationAndroidBundleIdExists',
    defaultMessage: 'An application with the same Google bundle ID already exists!'
  },
  applicationIosBundleIdExists: {
    id: 'createApplication.applicationIosBundleIdExists',
    defaultMessage: 'An application with the same Apple bundle ID already exists!'
  },
  retry: {
    id: 'app.retry',
    defaultMessage: 'Retry'
  },
  contact: {
    id: 'app.contact',
    defaultMessage: 'Contact'
  },
  mobileSettings: {
    id: 'mobile.settings',
    defaultMessage: 'Mobile Settings'
  },
  mobileLock: {
    id: 'mobile.lock',
    defaultMessage: 'Mobile Lock'
  },
  bottomMenu: {
    id: 'mobile.bottomMenu',
    defaultMessage: 'Bottom Menu'
  },
  bottomNavigationOption: {
    id: 'bottomMenu.bottomNavigationOption',
    defaultMessage: 'Customize Navigation Bar'
  },
  explorerCustomization: {
    id: 'customization.explore',
    defaultMessage: 'Explorer Customization'
  },
  loginCustomization: {
    id: 'customization.login',
    defaultMessage: 'Login'
  },
  registerCustomization: {
    id: 'customization.register',
    defaultMessage: 'Register'
  },
  buttonsCustomization: {
    id: 'customization.buttons',
    defaultMessage: 'Buttons'
  },
  generateUserReport: {
    id: 'reports.generateUserReport',
    defaultMessage: 'Generate Report'
  },
  generateCfpUserReport: {
    id: 'reports.generateCfpUserReport',
    defaultMessage: 'Generate Report'
  },
  newAppBuild: {
    id: 'nav.newAppBuild',
    defaultMessage: 'New App Build'
  },
  createMasterAppIntegration: {
    id: 'nav.createApp',
    defaultMessage: 'Create'
  },
  editAppIntegration: {
    id: 'nav.editApp',
    defaultMessage: 'Edit'
  },
  masterAppIntegration: {
    id: 'integrations.masterAppIntegration',
    defaultMessage: 'App Integration'
  },
  masterAppIntegrations: {
    id: 'integrations.masterAppIntegrations',
    defaultMessage: 'App Integrations'
  },
  appName: {
    id: 'appName',
    defaultMessage: 'App Name'
  },
  iosUriScheme: {
    id: 'iosUriScheme',
    defaultMessage: 'IOS URI Scheme'
  },
  appleAppStoreLink: {
    id: 'appleAppStoreLink',
    defaultMessage: 'Apple App Store Link'
  },
  androidUriScheme: {
    id: 'androidUriScheme',
    defaultMessage: 'Android URI Scheme'
  },
  googlePlayStoreLink: {
    id: 'googlePlayStoreLink',
    defaultMessage: 'Google Play Store Link'
  },
  webUrl: {
    id: 'webUrl',
    defaultMessage: 'Default Web URL'
  },
  smsSettings: {
    id: 'smsSettings',
    defaultMessage: 'SMS Settings'
  },
  smsSettingsUpdateSuccess: {
    id: 'smsSettings.smsSettingsUpdateSUccess',
    defaultMessage: 'SMS Settings updated successfully'
  },
  smsSettingsUpdateSuccessHeader: {
    id: 'smsSettings.smsSettingsUpdateSuccessHeader',
    defaultMessage: 'Success'
  },
  smsSettingsSave: {
    id: 'smsSettings.save',
    defaultMessage: 'Save'
  },
  smsSettingsEnableSms: {
    id: 'smsSettings.smsSettingsEnableSms',
    defaultMessage: 'Enable SMS'
  },
  twilioSubaccountSid: {
    id: 'smsSettings.twilioSubaccountSid',
    defaultMessage: 'Twilio Subaccount SID'
  },
  twilioSubaccountAuthToken: {
    id: 'smsSettings.twilioSubaccountAuthToken',
    defaultMessage: 'Twilio Subaccount Auth Token'
  },
  twilioPhoneNumber: {
    id: 'smsSettings.twilioPhoneNumber',
    defaultMessage: 'Phone Number (SMS Sent From)'
  },
  twilioPhoneNumberValidationMessage: {
    id: 'smsSettings.validation',
    defaultMessage: 'Phone Number not in E.164 format'
  },
  smsSettingsRequired: {
    id: 'smsSettings.required',
    defaultMessage: 'Required fields'
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading'
  }
})
