// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'

type GroupTitleProps = {
  number: string,
  title: string
}

/* istanbul ignore next */
const Container: ReactComponentStyled<{}> = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  font-size: 18px;
  float: left;
  color: ${color.darkestGray};

  margin-top: 30px;
`
Container.displayName = 'Container'

/* istanbul ignore next */
const Number: ReactComponentStyled<{}> = styled.div`
  color: ${color.white};
  display: inline-block;
  text-decoration: none;
  border-radius: 50%;
  background-color: ${color.darkestGray};
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;

`
Number.displayName = 'Number'
const Title: ReactComponentStyled<{}> = styled.div`
  display: inline-block;
  margin-left: 16px;
`
Title.displayName = 'Title'

class GroupTitle extends Component<GroupTitleProps, {}> {
  render () {
    const { number, title } = this.props

    return (
      <Container>
        <Number>{number}</Number>
        <Title>{title}</Title>
      </Container>
    )
  }
}

export default GroupTitle
