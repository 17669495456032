// @flow

import React, { useContext } from 'react'
import AllApplications from './AllApplicationsPage'
import AllAccounts from './AllAccountsPage'
import MasterAppIntegrationForm from './AppIntegrations/MasterAppIntegrationForm'
import AllReports from './AllReportsPage'
import ApplicationPage from './Application'
import PermissionDenied from './PermissionDeniedPage'
import { SideBar } from './Components'
import { Route, Navigate, Routes, useLocation } from 'react-router-dom'
import TestPage from './Account'
import { CreateAccount, SmsSettings, Test2, Test3 } from './Account/Pages'
import AdminRoute from './AdminRoute'
import MasterAppIntegrationsPage from './MasterAppIntegrationsPage'
import { useApolloClient } from '@apollo/client'
import { AppContext } from './contexts/app'

import MobileLock from './Application/MobileSettings/MobileLock'
import BottomMenu from './Application/MobileSettings/BottomMenu'

import HRISIntegrations from './Application/HRISIntegrations'
import Customization from './Application/Customization'
import MobileSettings from './Application/MobileSettings'
import Details from './Application/Details'
import NewBuild from './Application/NewAppBuild'

import LoginComponent from './Application/Customization/LoginComponent'
import RegistrationComponent from './Application/Customization/RegistrationComponent'
import ButtonsComponent from './Application/Customization/ButtonsComponent'
import ExplorerCustomization from './Application/Customization/ExplorerCustomization'
import ApplicationDetails from './Application/Details/ApplicationDetails'
import NewAppBuild from './Application/NewAppBuild/NewAppBuild'

const AppNavigation = () => {
  const client = useApolloClient()
  const { user: userData } = useContext(AppContext)
  const location = useLocation()

  const isPlatformAdmin = (role: string) => {
    return role === 'customercare' || role === 'developer' || role === 'admin'
  }

  const { user, account } = userData
  const role = user.role
  const admin = isPlatformAdmin(role)
  const background = location.state ? location.state.background : location

  return <SideBar user={user} client={client}>
      <Routes location={background || location}>
        <Route exact path='/' element={<Navigate replace to={admin ? '/all-accounts' : '/all-applications'} />} />
        <Route path='/account-settings/account' element={<TestPage />}>
          <Route exact path='test' element={<Test2 />} />
          <Route exact path='test2' element={<Test2 />} />
          <Route exact path='test3' element={<Test3 />} />
        </Route>
        <Route exact path='/all-applications' element={<AllApplications applications={account.applications} create={admin} client={client} />} />
        <Route path='/all-accounts/view/:accountId/all-applications/application/:id' element={<AdminRoute admin={admin}><ApplicationPage account={account} applications={account.applications} /></AdminRoute>} />
        {/* <AdminRoute admin={admin} path='/all-accounts/view/:accountId/all-applications/application/:id' render={(props) => <ApplicationPage {...props} client={client} />} /> */}
        <Route path='/all-applications/application/:id' element={<ApplicationPage />} />

        <Route path='/all-applications/application/:id/app-integrations' element={<HRISIntegrations client={client} />} />

        <Route path='/all-applications/application/:id/customization' element={<Customization client={client} />}>
          <Route path='login' element={<LoginComponent client={client} />} />
          <Route path='registration' element={<RegistrationComponent client={client} />} />
          <Route path='buttons' element={<ButtonsComponent client={client} />} />
          <Route path='explorer' element={<ExplorerCustomization client={client} />} />
        </Route>
        <Route path='/all-accounts/view/:accountId/all-applications/application/:id/customization' element={<Customization client={client} />}>
          <Route path='login' element={<LoginComponent client={client} />} />
          <Route path='registration' element={<RegistrationComponent client={client} />} />
          <Route path='buttons' element={<ButtonsComponent client={client} />} />
          <Route path='explorer' element={<ExplorerCustomization client={client} />} />
        </Route>

        <Route path='/all-applications/application/:id/AppBuild' element={<Details client={client} />}>
          <Route path='ApplicationDetails' element={<ApplicationDetails client={client} />} />
        </Route>
        <Route path='/all-accounts/view/:accountId/all-applications/application/:id/AppBuild' element={<Details client={client} />}>
          <Route path='ApplicationDetails' element={<ApplicationDetails client={client} />} />
        </Route>

        <Route path='/all-applications/application/:id/build' element={<NewBuild client={client} />}>
          <Route path='NewAppBuild' element={<NewAppBuild client={client} />} />
        </Route>
        <Route path='/all-accounts/view/:accountId/all-applications/application/:id/build' element={<NewBuild client={client} />}>
          <Route path='NewAppBuild' element={<NewAppBuild client={client} />} />
        </Route>

        <Route path='/all-applications/application/:id/Mobile' element={<MobileSettings client={client} />}>
          <Route path='bottom-menu' element={<BottomMenu client={client} />} />
          <Route path='security' element={<MobileLock client={client} />} />
        </Route>
        <Route path='/all-accounts/view/:accountId/all-applications/application/:id/Mobile' element={<MobileSettings client={client} />}>
          <Route path='bottom-menu' element={<BottomMenu client={client} />} />
          <Route path='security' element={<MobileLock client={client} />} />
        </Route>

        <Route path='/all-accounts' element={<AdminRoute admin={admin}><AllAccounts /></AdminRoute>} />
        {/* <AdminRoute admin={admin} exact path='/all-accounts' render={(props) => <AllAccounts client={client} />} /> */}
        <Route path='/home' element={<AdminRoute admin={admin}><AllAccounts /></AdminRoute>} />
        {/* <AdminRoute admin={admin} exact path='/home' render={(props) => <AllAccounts client={client} />} /> */}
        <Route path='/all-accounts/create' element={<AdminRoute admin={admin}><CreateAccount client={client} /></AdminRoute>} />
        {/* <AdminRoute admin={admin} path='/all-accounts/create' render={(props) => <CreateAccount {...props} client={client} />} /> */}
        <Route path='/app-integrations/create' element={<AdminRoute admin={admin}><MasterAppIntegrationForm client={client} /></AdminRoute>} />
        {/* <AdminRoute admin={admin} path='/app-integrations/create' render={(props) => <MasterAppIntegrationForm {...props} client={client} />} /> */}
        <Route path='/app-integrations/view/:masterAppIntegrationId/edit' element={<AdminRoute admin={admin}><MasterAppIntegrationForm client={client} /></AdminRoute>} />
        {/* <AdminRoute admin={admin} path='/app-integrations/view/:masterAppIntegrationId/edit' render={(props) => <MasterAppIntegrationForm {...props} client={client} />} /> */}
        <Route path='/all-accounts/view/:accountId/edit' element={<AdminRoute admin={admin}><CreateAccount client={client} /></AdminRoute>} />
        {/* <AdminRoute admin={admin} path='/all-accounts/view/:accountId/edit' render={(props) => <CreateAccount {...props} client={client} />} /> */}
        <Route path='/all-accounts/view/:accountId/sms-settings' element={<AdminRoute admin={admin}><SmsSettings client={client} /></AdminRoute>} />
        {/* <AdminRoute admin={admin} path='/all-accounts/view/:accountId/sms-settings' render={(props) => <SmsSettings {...props} client={client} />} /> */}
        <Route path='/all-accounts/view/:accountId/all-applications' element={<AdminRoute admin={admin}><AllApplications create={admin} client={client} /></AdminRoute>} />
        {/* <AdminRoute exact admin={admin} path='/all-accounts/view/:accountId/all-applications' render={(props) => <AllApplications create={admin} client={client} />} /> */}
        <Route path='/app-integrations' element={<AdminRoute admin={admin}><MasterAppIntegrationsPage create={admin} client={client} /></AdminRoute>} />
        {/* <AdminRoute exact admin={admin} path='/app-integrations' render={(props) => <MasterAppIntegrationsPage {...props} create={admin} client={client} />} /> */}
        <Route path='/app-integrations/create' element={<AdminRoute admin={admin}><MasterAppIntegrationForm client={client} /></AdminRoute>} />
        {/* <AdminRoute exact admin={admin} path='/app-integrations/create' render={(props) => <MasterAppIntegrationForm {...props} client={client} />} /> */}
        <Route path='/app-integrations/:masterAppIntegrationId/edit' element={<AdminRoute admin={admin}><MasterAppIntegrationForm client={client} /></AdminRoute>} />
        {/* <AdminRoute exact admin={admin} path='/app-integrations/:masterAppIntegrationId/edit' render={(props) => <MasterAppIntegrationForm {...props} client={client} />} /> */}
        <Route path='/all-accounts/view/:accountId/all-reports' element={<AdminRoute admin={admin}><AllReports create={admin} client={client} /></AdminRoute>} />
        {/* <AdminRoute exact admin={admin} path='/all-accounts/view/:accountId/all-reports' render={(props) => <AllReports create={admin} client={client} />} /> */}
        <Route exact path='/permission-denied-403' element={<PermissionDenied />} />
      </Routes>
    </SideBar>
}

export default AppNavigation
