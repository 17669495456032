// @flow

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import { ApolloClient, useMutation, useQuery } from '@apollo/client'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { Match, RouterHistory } from 'react-router-dom'
import { GET_APPLICATION_BY_ID, SET_HRISINTEGRATIONS } from '../../queries/application'
import { Toggle, ConfirmModal, AlertModal } from '../../Components'
import { messages } from '../../i18n/messages'
import HRISMessages from './HRISMessages'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import ultiproIcon from 'michelangelo/dist/Components/styles/icons/ukg_pro.png'
import adpIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/adp.png'
import kronosIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/ukg_kronos.png'
import workdayIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/workday.png'
import valiantIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/valiantmobile.png'
import msTeamsIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/msteams.png'
import concurIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/concur.png'
import bambooHrIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/bamboohr.png'
import cignaIcon from '../../../node_modules/michelangelo/src/Components/styles/icons/cigna.png'
import certifyIcon from '../../../node_modules/michelangelo/src/Components/styles/icons/certify.png'
// will be used once both these systems are supported
// import workforceIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/workforce.png'
// import peopleSoftIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/peoplesoft.png'
import yammerIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/yamer.png'
import sfIcon from '../../../node_modules/michelangelo/dist/Components/styles/icons/sap.png'
import ServiceCard from 'michelangelo/dist/Components/ServiceCard'
import Notification from 'michelangelo/dist/Components/Notification'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import theme from 'michelangelo/dist/Components/styles/theme'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import { capitalize, isEqual, isEmpty, startsWith, toLower } from 'lodash'
import { alfredUrl } from '../../utils/config'
import Link from '../../Components/Link'

// KRONOS is now UKG Workforce Central
// ULTI PRO is now UKG Pro

const DescriptionContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 20% 22px 20%;
    text-align: center;
    @media (max-width: 900px) {
      padding: 20px 7% 22px 7%;
    }
    @media (max-width: 412px) {
      padding: 20px 5% 18px 5%;
    }
`
const HRISItems: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
`
const HRISInlineBlock: ReactComponentStyled<{}> = styled.div`
    display: inline-block;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    @media (max-width: 450px) {
      padding-left: 0%;
      padding-right: 0%;
    }
`
const HeaderContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: 100%;
    padding: 63px 0px 20px 0px;
    text-align: center;
`
const ButtonContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  padding-top: 100px;
  padding-bottom: 80px;
`
const LinkContainer: ReactComponentStyled = styled.div`
  display: inline-block;
  width: 87%;
  padding-bottom: 28px;
`

// add for testing purpose
HeaderContainer.displayName = 'HeaderContainer'
Toggle.displayName = 'Toggle'
DescriptionContainer.displayName = 'DescriptionContainer'
HRISItems.displayName = 'HRISItems'
HRISInlineBlock.displayName = 'HRISInlineBlock'
ButtonContainer.displayName = 'ButtonContainer'

type SetHrisIntegrationsProps = {
  match: Match,
  client: ApolloClient<any>,
  history: RouterHistory
} & InjectIntlProvidedProps

function areDefinitionsEqual (prevDefinitions: Array<Object>, currentDefinition: Array<Object>) {
  if ((!prevDefinitions && currentDefinition) || (prevDefinitions && !currentDefinition)) {
    return false
  }
  const prev = prevDefinitions.map<any>(x => ({ id: x.id, enabled: x.enabled }))
  const current = currentDefinition.map<any>(x => ({ id: x.id, enabled: x.enabled }))
  return isEqual(prev, current)
}

function getInvalidURLs (hrisDefinitions) {
  return hrisDefinitions.filter(hris => hris.enabled && !isEmpty(hris.webUrl) && !startsWith(toLower(hris.webUrl), 'http://') && !startsWith(toLower(hris.webUrl), 'https://')).map(hris => hris.webUrl)
}
function HRISIntegrations ({ match, client, intl: { formatMessage }, history }: SetHrisIntegrationsProps) {
  const getHrisDefinition = (id: string, icon: Object, hrisIntegrations: Array<Object>, defaultWebUrl) => {
    const hris = hrisIntegrations.find((x) => x.hrisId === id)
    return {
      id,
      name: formatMessage(messages[id]),
      icon: <img src={icon} alt={formatMessage(messages[id])} />,
      enabled: hris ? hris.enabled : false,
      webUrl: !isEmpty(hris) && !isEmpty(hris.webUrl) ? hris.webUrl : defaultWebUrl
    }
  }

  const getAllHRISDefinitions = (hrisIntegrations: Array<Object>) => {
    return [
      getHrisDefinition('ADP', adpIcon, hrisIntegrations, 'https://www.adp.com/logins.aspx'),
      getHrisDefinition('UltiPro', ultiproIcon, hrisIntegrations, 'https://e41.ultipro.com/'),
      // getHrisDefinition('Workforce', workforceIcon, hrisIntegrations),
      getHrisDefinition('Workday', workdayIcon, hrisIntegrations, 'https://www.workday.com'),
      getHrisDefinition('Kronos', kronosIcon, hrisIntegrations, 'https://www.kronos.com'),
      getHrisDefinition('Yammer', yammerIcon, hrisIntegrations, 'https://www.yammer.com/'),
      // getHrisDefinition('PeopleSoft', peopleSoftIcon, hrisIntegrations),
      getHrisDefinition('SuccessFactors', sfIcon, hrisIntegrations, 'https://performancemanager4.successfactors.com/login#/companyEntry'),
      getHrisDefinition('Valiant', valiantIcon, hrisIntegrations, 'https://live.portal.valiant.com/'),
      getHrisDefinition('MicrosoftTeams', msTeamsIcon, hrisIntegrations, 'https://teams.microsoft.com'),
      getHrisDefinition('Concur', concurIcon, hrisIntegrations, 'https://www.concursolutions.com/'),
      getHrisDefinition('BambooHR', bambooHrIcon, hrisIntegrations, 'https://app.bamboohr.com/login/'),
      getHrisDefinition('Cigna', cignaIcon, hrisIntegrations, 'https://hr.corp.cigna.com/'),
      getHrisDefinition('Certify', certifyIcon, hrisIntegrations, 'https://www.certify.com/Login.aspx')
    ]
  }

  const id = match.params.id || ''
  const { data: application } = useQuery(GET_APPLICATION_BY_ID, { variables: { _id: id }, skip: !id, fetchPolicy: 'cache-and-network' })
  // const { data: buildParams } = useQuery(GET_BUILD_PARAMS, { variables: { _id: id }, fetchPolicy: 'cache-and-network' })

  const [hrisDefinitions, setHrisDefinitions] = useState(getAllHRISDefinitions([]))
  const [notification, setNotification] = useState(undefined)
  const [retries, setRetries] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  const onError = () => {
    const buttonText = formatMessage(retries < 3 ? messages.retry : messages.contact)
    const onClick = retries < 3 ? onRetry : onContact
    const type = retries < 3 ? 'warning' : 'error'
    setRetries(retries + 1)
    setNotification({ type, autoClose: 5, message: formatMessage(HRISMessages.error), show: true, onClose: onNotificationClose, buttonText, onClick })
  }

  const onCompleted = () => {
    setNotification({ type: 'success', autoClose: 5, message: formatMessage(HRISMessages.success), show: true, onClose: onNotificationClose })
  }

  const [setHRISIntegrations, { loading }] = useMutation(SET_HRISINTEGRATIONS, { onCompleted, onError })
  const setApplicationData = () => {
    const integrations = application && application.getApplicationById ? application.getApplicationById.hrisIntegrations : []
    const definitions = getAllHRISDefinitions(integrations)
    if (!areDefinitionsEqual(definitions, hrisDefinitions)) {
      setHrisDefinitions(definitions)
    }
  }
  // eslint-disable-next-line
  useEffect(setApplicationData, [application])

  const onToggle = (id: string, isToggled: boolean) => {
    const definitions = [...hrisDefinitions]
    const index = definitions.findIndex((x) => x.id === id)
    definitions[index].enabled = !isToggled
    setHrisDefinitions(definitions)
  }
  const onWebUrlChange = (id: string, webUrl: string) => {
    const definitions = [...hrisDefinitions]
    const index = definitions.findIndex((x) => x.id === id)
    definitions[index].webUrl = webUrl
    setHrisDefinitions(definitions)
  }
  const handleFormSubmit = (retrying) => {
    if (!retrying) {
      setRetries(0)
    }
    const hrisIntegrations = hrisDefinitions.map((item) => ({ id: item.id, enabled: item.enabled, webUrl: item.webUrl }))
    setHRISIntegrations({
      variables: { application: id, hrisIntegrations },
      optimisticResponse: { _id: id, hrisIntegrations }
    })
    setShowModal(false)
  }

  const onNotificationClose = () => setNotification(undefined)
  const cancelModal = () => setShowModal(false)
  const closeErrorModal = () => setShowErrorModal(false)
  const onRetry = () => handleFormSubmit(true)
  const onContact = () => window.open(`${alfredUrl}/customer-care`)
  const onSaveClick = () => {
    const badLinks = getInvalidURLs(hrisDefinitions)
    if (isEmpty(badLinks)) {
      setShowModal(true)
    } else {
      setShowErrorModal(true)
    }
  }
  const loadingNotification = loading && <Notification show message={formatMessage(HRISMessages.loading)} autoClose={0} type='loading' />
  const notificationComponent = notification ? <Notification {...notification} /> : null
  const getModalBody = (text, links) => (
    <HRISItems>
      <LinkContainer>
        <Text fontColor={color.darkGray} align='center' textSize='p' text={text} />
        {links.map((link, index) => {
          return <Link key={index} link={link} onClick={() => window.open(link)} />
        })}
      </LinkContainer>
    </HRISItems>
  )
  let confirmModal
  if (showModal) {
    const modalBody = getModalBody(formatMessage(HRISMessages.checkWeb), hrisDefinitions.filter(hris => hris.enabled).map(hris => hris.webUrl))
    confirmModal = (
      <ConfirmModal
        cancelAction={cancelModal}
        buttonAction={handleFormSubmit}
        icon='warning'
        buttonText={formatMessage(HRISMessages.yesUpdate)}
        headerText={formatMessage(HRISMessages.areYouSure)}
        cancelText={formatMessage(messages.cancel)}
        modalText={formatMessage(HRISMessages.modalBody)}
        modalTheme={color.warning}
        modalFontColor='warning'
        body={modalBody}
      />
    )
  }

  let errorModal
  if (showErrorModal) {
    const invalidUrls = getInvalidURLs(hrisDefinitions)
    const modalText = formatMessage(HRISMessages.invalidUrlSyntax)
    const body = getModalBody(modalText, invalidUrls)
    errorModal = <AlertModal modalTheme={color.danger} modalFontColor='danger' headerText={formatMessage(messages.error)} buttonText={formatMessage(messages.ok)} buttonAction={closeErrorModal} body={body} icon='x' />
  }
  const items = hrisDefinitions.map<any>((item: Object, index: number) => {
    const toggle = <Toggle isToggled={item.enabled} onToggleChange={(isToggled: boolean) => { onToggle(item.id, isToggled) }} disabled={loading} />
    const onChange = (webUrl: string) => onWebUrlChange(item.id, webUrl)
    const toggleLabel = item.enabled ? formatMessage(HRISMessages.enabled) : formatMessage(HRISMessages.disabled)
    const activeLabel = item.enabled ? formatMessage(HRISMessages.active) : formatMessage(HRISMessages.notActive)
    return (
      <Column key={index} classNames={['is-half-desktop', 'is-gapless']}>
        <ServiceCard icon={item.icon} title={item.name} toggle={toggle} toggleLabel={capitalize(toggleLabel)} active={item.enabled} data={item.webUrl} activeLabel={activeLabel} dataLabel={formatMessage(HRISMessages.webLink)} onChange={onChange} />
      </Column>
    )
  })

  return (
    <div>
      {loadingNotification}
      {notificationComponent}
      {confirmModal}
      {errorModal}
      <HeaderContainer>
        <Text text={formatMessage(messages.hrisDashboard)} textSize='h2' align='center' fontColor={color.lightBlack} />
      </HeaderContainer>
      <DescriptionContainer>
        <Text fontColor={color.darkGray} textSize='p' text={formatMessage(HRISMessages.hrisDashboardDescription)} />
      </DescriptionContainer>
      <HRISItems>
        <HRISInlineBlock>
          <Row classNames={['is-multiline', 'is-marginless']}>
            {items}
          </Row>
        </HRISInlineBlock>
      </HRISItems>
      <Row classNames={['is-centered']}>
        <ButtonContainer>
          <Column>
            <RoundedButton onClick={setApplicationData} width='118px' theme={theme.disabled} textTheme={theme.disabled} disabled={false}>{formatMessage(messages.cancel)}</RoundedButton>
          </Column>
          <Column>
            <RoundedButton onClick={onSaveClick} width='118px' theme={theme.info} fill>{formatMessage(messages.save)}</RoundedButton>
          </Column>
        </ButtonContainer>
      </Row>
    </div>
  )
}

export default injectIntl(HRISIntegrations)
export { HRISIntegrations as UnwrappedHRISIntegrations }
