// @flow
/* eslint-disable */

import React from 'react'
import { Outlet } from 'react-router-dom'
import withRouter from '../utils/withRouter'
import { injectIntl, InjectIntlProvidedProps } from 'react-intl'
import type { RouterHistory, Match } from 'react-router-dom'
import { BoxContainer, Tabs } from '../Components'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import Column from 'michelangelo/dist/Components/Column'
import { withApollo } from '@apollo/client/react/hoc'

type AccountProps = {
  match: Match,
  history: RouterHistory
} & InjectIntlProvidedProps

const Container: ReactComponentStyled<{}> = styled.div`
    padding: 50px 20px 0px 20px;
    width: auto;
`
const List: ReactComponentStyled<{}> = styled.ul`
    display: table-row;
    margin-right: 10px;
`
const BoxParent: ReactComponentStyled<{}> = styled.div`
    margin-bottom: 50px;
`

// add for testing purpose
Container.displayName = 'Container'
List.displayName = 'List'
BoxParent.displayName = 'BoxParent'
Tabs.displayName = 'Tabs'

const Account = (props: AccountProps) => {
  return (
      <Column classNames={['is-paddingless', 'is-full']}>
        <Column classNames={['is-paddingless', 'is-full']}>
          <BoxParent>
            <BoxContainer>
              <Outlet />
            </BoxContainer>
          </BoxParent>
        </Column>
      </Column>
  )
}
export default withApollo(withRouter(injectIntl(Account)))

export { Account as UnwrappedAccount }
