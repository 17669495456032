// @flow

import React from 'react'
import styled from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import Icon from 'michelangelo/dist/Components/Icon'
import color from 'michelangelo/dist/Components/styles/color'
import { NavLink } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
/* istanbul ignore next */
const MasterList: ReactComponentStyled<{}> = styled.div`
  background: ${(props) => props.isopen ? `${props.backgroundColor}` : `${color.darkestGray}`};
  display: flex;
  cursor: default;
  height: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: ${(props) => props.isopen ? '250px' : '60px'};
  text-align: ${(props) => props.isopen ? 'left' : 'center'};
  border-top: 1px solid ${color.darkestGray};
`
MasterList.displayName = 'MasterList'

/* istanbul ignore next */
const IconContainer: ReactComponentStyled<{}> = styled.div`
  display: ${(props) => props.isopen ? 'flex' : ''};
  align-items: ${(props) => props.isopen ? 'center' : ''};
  height: 40px;
  width: ${(props) => props.isopen ? 'auto' : '100%'};
  margin-left: ${(props) => props.isopen ? '10px' : ''};
`
IconContainer.displayName = 'IconContainer'

/* istanbul ignore next */
const SubMenuIconContainer: ReactComponentStyled<{}> = styled.div`
  height: 40px;
  width: ${(props) => props.isopen ? 'auto' : '100%'};
  margin-right: ${(props) => props.isopen ? '10px' : ''};
  cursor: pointer;
`
SubMenuIconContainer.displayName = 'SubMenuIconContainer'

/* istanbul ignore next */
const List = styled.div`
  display: ${(props) => props.isopen || !props.isSubLink ? 'flex' : 'none'};
  cursor: pointer;
  height: 50px;
  width: ${(props) => props.isopen ? '250px' : '60px'};
  text-align: ${(props) => props.isopen ? 'left' : 'center'};
  background: ${(props) => props.isSubLink ? `${color.sideNavSelected}` : 'auto'};
`
List.displayName = 'List'

const Nav = styled(NavLink)`
  display: inherit;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
`
Nav.displayName = 'Nav'

/* istanbul ignore next */
const SecondTextContainer: ReactComponentStyled<{}> = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
  cursor: pointer;
  width: ${(props) => props.isopen ? '250px' : '60px'};
  display: ${(props) => props.isopen ? 'block' : 'none'};
`
SecondTextContainer.displayName = 'SecondTextContainer'

type MenuItemProps = {
    id: string,
    isopen: boolean,
    icon: string,
    isParent: boolean,
    isSubLink: boolean,
    to: string,
    isSubMenuOpened: boolean,
    toggle: (id: string, isSubMenuOpened: boolean) => void,
    parentCanBeActive: boolean
} & InjectIntlProvidedProps

const MenuItem = (props: MenuItemProps) => {
  const { id, isopen, icon, isSubMenuOpened, to, parentCanBeActive, isParent, isSubLink, toggle, intl: { formatMessage } } = props

  const getStyles = (isToggleSideBarOpen: boolean) => {
    let iconClass, textClass, subTextClass
    if (isToggleSideBarOpen) {
      iconClass = 'iconwithtext'
      textClass = 'menutext'
      subTextClass = 'subText'
    } else {
      iconClass = 'customitem'
      textClass = 'display-none'
      subTextClass = 'subText'
    }
    return { iconClass, textClass, subTextClass }
  }

  const subMenuToggled = (id: string) => {
    toggle(id, !isSubMenuOpened)
  }

  const getItem = () => {
    const { textClass } = getStyles(isopen)
    return (
        <React.Fragment>
          <IconContainer isopen={isopen}>
            <Icon color={color.white} iconName={icon} />
          </IconContainer>
          <div className={textClass}>
            <Text align='left' textSize='p' fontColor={color.white} text={formatMessage({ id })} />
          </div>
        </React.Fragment>
    )
  }

  const getNavigation = () => {
    const activeColor = parentCanBeActive ? color.info : color.darkestGray
    const item = getItem()
    return (
        <NavLink to={to}
          style={({ isActive, isPending }) => {
            return {
              backgroundColor: isActive ? activeColor : 'transparent',
              width: '250px',
              display: 'flex',
              alignItems: 'center',
              height: '50px'
            }
          }}>
          {item}
        </NavLink>
    )
  }

  const getMenuWithNoSubLinks = () => {
    const { iconClass } = getStyles(isopen)
    const navigation = getNavigation()
    return (
        <List key={id} className={iconClass} isopen={isopen}>
          {navigation}
        </List>
    )
  }

  const getParentMenu = () => {
    const { iconClass, textClass } = getStyles(isopen)
    const backgroundColor = isSubMenuOpened ? color.sideNavSelected : 'auto'
    return (
        <MasterList
            key={id}
            isopen={isopen}
            backgroundColor={backgroundColor}
            className={iconClass}
        >
          <IconContainer isopen={isopen}>
            <Icon color={color.white} iconName={icon} />
          </IconContainer>
          <div className={textClass}>
            <Text align='left' textSize='p' fontColor={color.white} text={formatMessage({ id })} />
          </div>
          <SubMenuIconContainer isopen={isopen} className={textClass} onClick={() => subMenuToggled(id)}>
            <Icon color={color.white} iconName={isSubMenuOpened ? 'chevronUp' : 'chevronDown'} />
          </SubMenuIconContainer>
        </MasterList>
    )
  }

  const getSubMenu = () => {
    const { iconClass, subTextClass, textClass } = getStyles(isopen)
    return (
        <List key={id} className={iconClass} isopen={isopen} isSubLink>
          <NavLink
              to={to}
              style={({ isActive, isPending }) => {
                return {
                  backgroundColor: isActive ? color.info : 'transparent',
                  width: '250px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '50px'
                }
              }}
            >
            <SecondTextContainer isopen={isopen} className={[subTextClass, textClass]}>
              <Text align='left' textSize='p' fontColor={color.white} text={formatMessage({ id })} />
            </SecondTextContainer>
          </NavLink>
        </List>
    )
  }
  if (isSubLink) {
    return getSubMenu()
  }
  if (isParent) {
    return getParentMenu()
  }
  return getMenuWithNoSubLinks()
}
export default withRouter(injectIntl(MenuItem))
