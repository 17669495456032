// @flow

import React from 'react'
import styled from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import Icon from 'michelangelo/dist/Components/Icon'
import theme from 'michelangelo/dist/Components/styles/theme'
import ListRow from 'michelangelo/dist/WebComponents/ListItem/ListRow'
import ListItem from 'michelangelo/dist/WebComponents/ListItem/ListItem'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import { TooltipPosition } from 'michelangelo/dist/WebComponents/Feedback/Tooltip'

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-x: auto;
  padding-bottom: 1px;
`

const TableHeadCell = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 16px;
  cursor: ${(props) => props.isSortable ? 'pointer' : 'default'};
  box-shadow: inset 0px -1px 0px ${color.grey200};

`

const IconContainer = styled.div`
  position: absolute;
`

const TableColumnHeaderTextContainer = styled.div`
  display: flex;
  color: ${color.darkestGray};
`

const TableHeaderSortingContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-left: auto;
  width: fit-content;
  position: relative;
`

const TableRowLogoContainer = styled.div`
  display: flex;
  align-items:center;
  width:100%;
`

const TableRowLogoItem = styled.img`
  width:50px;
  height:50px;
  padding: 0.25rem;
  object-fit: contain;
`

type TableProps = {
  tableData?: Object,
  onHeaderClick?: Function,
  onActionClick?: Function,
  columnRatio?: Array
}

// class Table extends Component <TableProps, {}> {
//   generateTable () {
//     const { tableData, onHeaderClick, columnRatio } = this.props
//     const dataColumns = tableData.columns
//     const dataRows = tableData.rows
//     // var dataAlignment = tableData.dataAlignment
//     const isSortable = tableData.isSortable
//     const sorted = tableData.sorted
//
//     const tableHeaders = (
//       <ListRow testID={'liiist'} columnRatios={columnRatio ?? [3, 2, 2, 2, 3, 1]} stickyLastColumn>
//         {dataColumns.map(function (column, index) {
//           const sortedColumn = sorted[index]
//           return (
//             <TableHeadCell onClick={() => isSortable[index] ? onHeaderClick(column) : null} key={column} isSortable={isSortable[index]}>
//               <TableColumnHeaderTextContainer>
//                 <Text text={column} textSize='th' fontColor={color.lightBlack} ellipsis numberOfLines={2} />
//               </TableColumnHeaderTextContainer>
//               {isSortable[index] &&
//                 <TableHeaderSortingContainer>
//                   <IconContainer>
//                     <Icon iconName='sortUp' size='sm' color={sortedColumn === 1 || sortedColumn === 0 ? theme.lightGrey : theme.info} />
//                   </IconContainer>
//                   <IconContainer>
//                     <Icon iconName='sortDown' size='sm' color={sortedColumn === -1 || sortedColumn === 0 ? theme.lightGrey : theme.info} />
//                   </IconContainer>
//                 </TableHeaderSortingContainer>}
//             </TableHeadCell>
//           )
//         })}
//       </ListRow>)
//
//     const tableBody = dataRows.map(function (row) {
//       return (
//         <ListRow testID={'liiistf'} key={row._id} columnRatios={columnRatio ?? [3, 2, 2, 2, 3, 1]} stickyLastColumn>
//           {dataColumns.map(function (column, index) {
//             return (
//               index === 0 && row.logoUrl
//                 ? (<TableRowLogoContainer><TableRowLogoItem src={row.logoUrl} alt='logo' /><ListItem key={row._id + index} label={row[column]} {...index === 4 && { tooltipPosition: TooltipPosition.TOP_RIGHT }} /></TableRowLogoContainer>)
//                 : <ListItem key={row._id + index} label={row[column]} {...index === 4 && { tooltipPosition: TooltipPosition.TOP_RIGHT }} />
//             )
//           })}
//         </ListRow>
//       )
//     })
//
//     return (
//       <>
//         {tableHeaders}
//         {tableBody}
//       </>
//     )
//   }
//
//   onActionClick (accountId) {
//     this.props.onActionClick(accountId)
//   }
//
//   render () {
//     const table = this.generateTable()
//     return (
//       <TableContainer>
//         {table}
//       </TableContainer>
//     )
//   }
// }
const Table = (props: TableProps) => {
  const { tableData, onHeaderClick, columnRatio } = props

  const dataColumns = tableData.columns
  const dataRows = tableData.rows
  // var dataAlignment = tableData.dataAlignment
  const isSortable = tableData.isSortable
  const sorted = tableData.sorted

  const tableHeaders = (
      <ListRow testID={'liiist'} columnRatios={columnRatio ?? [3, 2, 2, 2, 3, 1]} stickyLastColumn>
        {dataColumns.map(function (column, index) {
          const sortedColumn = sorted[index]
          return (
              <TableHeadCell onClick={() => isSortable[index] ? onHeaderClick(column) : null} key={column} isSortable={isSortable[index]}>
                <TableColumnHeaderTextContainer>
                  <Text text={column} textSize='th' fontColor={color.lightBlack} ellipsis numberOfLines={2} />
                </TableColumnHeaderTextContainer>
                {isSortable[index] &&
                <TableHeaderSortingContainer>
                  <IconContainer>
                    <Icon iconName='sortUp' size='sm' color={sortedColumn === 1 || sortedColumn === 0 ? theme.lightGrey : theme.info} />
                  </IconContainer>
                  <IconContainer>
                    <Icon iconName='sortDown' size='sm' color={sortedColumn === -1 || sortedColumn === 0 ? theme.lightGrey : theme.info} />
                  </IconContainer>
                </TableHeaderSortingContainer>}
              </TableHeadCell>
          )
        })}
      </ListRow>)

  const tableBody = dataRows.map(function (row) {
    return (
        <ListRow testID={'liiistf'} key={row._id} columnRatios={columnRatio ?? [3, 2, 2, 2, 3, 1]} stickyLastColumn>
          {dataColumns.map(function (column, index) {
            return (
              index === 0 && row.logoUrl
                ? (<TableRowLogoContainer><TableRowLogoItem src={row.logoUrl} alt='logo' /><ListItem key={row._id + index} label={row[column]} {...index === 4 && { tooltipPosition: TooltipPosition.TOP_RIGHT }} /></TableRowLogoContainer>)
                : <ListItem key={row._id + index} label={row[column]} {...index === 4 && { tooltipPosition: TooltipPosition.TOP_RIGHT }} />
            )
          })}
        </ListRow>
    )
  })
  return (
      <TableContainer>
        {tableHeaders}
        {tableBody}
      </TableContainer>
  )
}
export default Table
