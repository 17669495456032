// @flow

import React, { useContext } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import Button from './Button'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import withRouter from '../utils/withRouter'
import type { RouterHistory } from 'react-router-dom'
import { messages } from '../i18n/messages'
import { split } from 'lodash'
import Icon from 'michelangelo/dist/Components/Icon'
import { AppContext } from '../contexts/app'
import { useLocation } from 'react-router-dom'

// export for tests
export const topHeaderMessages = defineMessages({
  logout: {
    id: 'topHeader.logout',
    defaultMessage: 'logout'
  }
})

/* istanbul ignore next */
const TopHeaderContainer: ReactComponentStyled<{}> = styled.div`
    padding: 12px 10px 12px 10px;
    background-color: ${color.white};
    height: 60px;
    display: block;
    @media (max-width: 767px) {
      height: 85px;
    }
`
const ButtonContainer: ReactComponentStyled<{}> = styled.div`
    text-align: right;
    display: inline-block;
    float: right;
    @media (max-width: 767px) {
      display: none;
    }
`
const TextContainer: ReactComponentStyled<{}> = styled.div`
    display: none;
    @media (max-width: 767px) {
      display: inline-block;
      width: calc(100% - 41px);
      text-align: center;
      vertical-align: middle;
    }
`
/* istanbul ignore next */
const IconMobileContainer: ReactComponentStyled<{}> = styled.div`
    display: none;
    /* tablet style */
    @media (max-width: 768px) {
      cursor: pointer;
      display: inline-block;
      font-size: ${(props) => props.isopen ? '22px' : '24px'};
      height: auto;
      width: 100%;
      left: 0;
      text-align: left;
      svg {
        vertical-align: middle;
        color: ${(props) => props.isopen ? `${color.white}` : `${color.default}`};
      }
    /* mobile and tablet style */
    @media (min-width: 415px) and (max-width: 768px) {
      width: 80%;
      }
  }
`
type TopHeaderProps = {
  history: RouterHistory,
  isopen: boolean,
  handleToggleSideBarOpen: (boolean) => void
} & InjectIntlProvidedProps

TopHeaderContainer.displayName = 'TopHeaderContainer'
ButtonContainer.displayName = 'ButtonContainer'
TextContainer.displayName = 'TextContainer'
IconMobileContainer.displayName = 'IconMobileContainerHeader'

const TopHeader = (props: TopHeaderProps) => {
  const { intl: { formatMessage } } = props
  const location = useLocation()
  const { isToggled, toggleSidebar } = useContext(AppContext)
  const pathnameSplit = split(location.pathname, '/')
  const firstPathname = pathnameSplit[1]
  let label = ''
  switch (firstPathname) {
    case 'account-settings':
      label = formatMessage(messages.configureAccount)
      break
    case 'all-applications':
    case '':
      label = formatMessage(messages.applicationSettings)
      break
    case 'access':
      label = formatMessage(messages.applicationSettings)
      break
    default:
      label = 'APPrise Mobile'
  }

  return (
      <TopHeaderContainer>
        <IconMobileContainer isopen={isToggled} onClick={toggleSidebar}>
          <Icon isopen={isToggled} color={color.darkestGray} iconName='bars' />
          <TextContainer>
            <Text fontSize='h3' fontColor={color.lightBlack} align='center' text={label} />
          </TextContainer>
        </IconMobileContainer>
        <ButtonContainer>
          <Button
              disabled={false}
              kind='logout'
              type='button'
          >{formatMessage(topHeaderMessages.logout)}
          </Button>
        </ButtonContainer>
      </TopHeaderContainer>
  )
}
export default withRouter(injectIntl(TopHeader))
