// @flow

import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { ApolloClient, gql } from '@apollo/client'
import type { ApolloCache } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'

export const GET_ACCOUNT_BY_ID = gql`
query getAccountById($account: String!) {
  getAccountById(account: $account) {
    _id
    name
    subDomain
    applications
    userAnalyticsEnabled
    customerCareEmail
  }
}
`
type GetAccountByIdProps = {
    render: Function,
    account: string
  }

export class GetAccountByIdComponent extends Component<GetAccountByIdProps, {}> {
  render () {
    return (
      <Query
        query={GET_ACCOUNT_BY_ID}
        variables={{ account: this.props.account }}
        notifyOnNetworkStatusChange
        fetchPolicy='cache-and-network'
        displayName='Account'
      >
        {({ loading, error, data, refetch, networkStatus, client }) => {
          if (!(data && data.getAccountById)) return null
          return this.props.render(data.getAccountById, client)
        }}
      </Query>
    )
  }
}
export const GetAccountById = async (client: ApolloClient, account: string) => {
  const { data } = await client.query({
    query: GET_ACCOUNT_BY_ID,
    variables: { account }
  })
  return data.getAccountById
}

export const UpdateCache = (cache: ApolloCache, account: string, name: string, clientSuccessEmail: string) => {
  const cachedData = cache.readQuery({
    query: GET_ACCOUNT_BY_ID,
    variables: { account }
  })
  const data = cloneDeep(cachedData)
  if (!data || !data.getAccountById) {
    return
  }
  cache.writeQuery({
    query: GET_ACCOUNT_BY_ID,
    variables: { account },
    data: {
      ...data,
      getAccountById: {
        ...data.getAccountById,
        name,
        customerCareEmail: clientSuccessEmail
      }
    }
  })
}

export const UpdateAccountApplications = (cache: ApolloCache, account: string, application: string) => {
  const cachedData = cache.readQuery({
    query: GET_ACCOUNT_BY_ID,
    variables: { account }
  })
  const data = cloneDeep(cachedData)
  if (!data || !data.getAccountById) {
    return
  }
  data.getAccountById.applications.push(application)
  cache.writeQuery({
    query: GET_ACCOUNT_BY_ID,
    variables: { account },
    data
  })
}
