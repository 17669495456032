// @flow

import React from 'react'
import styled from 'styled-components'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Icon from 'michelangelo/dist/Components/Icon'
import { isEmpty } from 'lodash'

/* istanbul ignore next */
const LogoImage: ReactComponentStyled<{}> = styled.img`
    width: ${(props) => props.width}px;
    align-self: center;
    justify-self: center;
    padding: 10px 0;
    position: absolute;
    top: 33.42%;
    transform: translate(-${(props) => props.width / 2}px, 0px);
`

LogoImage.displayName = 'LogoImage'
const TextContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: auto;
    margin: 0;
    position: absolute;
    top: 70%;
    left: 0%;
`
TextContainer.displayName = 'TextContainer'
const LoadingContainer: ReactComponentStyled<{}> = styled.div`
    width: 100%;
    height: auto;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
LoadingContainer.displayName = 'LoadingContainer'
const LoadingText: ReactComponentStyled<{}> = styled.h2`
    color: ${color.lightTextDefault};
    font-size: 24px;
    font-family: Raleway;
    font-weight: 600;
    letter-spacing: 1.54px;
    text-align: center;
`
LoadingText.displayName = 'LoadingText'

const Container: ReactComponentStyled<{}> = styled.div`
  text-align: center;
`
Container.displayName = 'Container'
type LogoProps = {
  applicationId: string,
  applicationName: string,
  width: number,
  icon?: string,
  logoColor?: string
} & InjectIntlProvidedProps

type LogoState = {
  loaded: boolean
}

class ApplicationLogo extends React.Component<LogoProps, LogoState> {
  constructor (props: LogoProps) {
    super(props)
    if (props.icon) {
      this.state = { loaded: true }
    } else {
      this.state = { loaded: false }
    }
  }

  getLoader () {
    const { applicationName } = this.props
    const { loaded } = this.state
    if (loaded) {
      return null
    }
    return (
      <LoadingContainer>
        <LoadingText>{applicationName}</LoadingText>
      </LoadingContainer>
    )
  }

  getTitle () {
    const { applicationName } = this.props
    const { loaded } = this.state
    if (!loaded) {
      return null
    }
    const logoColor = this.props.logoColor || color.darkestGray
    return (
      <TextContainer>
        <Text text={applicationName} textSize='h3' align='center' fontColor={logoColor} />
      </TextContainer>
    )
  }

  getLogo () {
    const { applicationId, applicationName } = this.props

    if (isEmpty(applicationId)) {
      return null
    }
    const { loaded } = this.state
    const style = loaded ? {} : { display: 'none' }
    const logoS3Url = 'https://cdn-logos.theemployeeapp.com/' + applicationId + '.png'

    return <LogoImage width={this.props.width} data-cy='headerlogoimagecy' style={style} onLoad={() => this.setState({ loaded: true })} src={logoS3Url} alt={applicationName} />
  }

  getIcon () {
    const { icon } = this.props
    if (!icon) {
      return null
    }
    const logoColor = this.props.logoColor || color.darkestGray
    return <Icon color={logoColor} iconName={icon} size='3x' />
  }

  render () {
    const loader = this.getLoader()
    const title = this.getTitle()
    const logo = this.getLogo()
    const icon = this.getIcon()
    return (
      <div>
        <Container>
          <div>
            {loader}
            {logo}
            {icon}
            {title}
          </div>
        </Container>
      </div>
    )
  }
}
export default ApplicationLogo
