// @flow
/* eslint-disable object-shorthand */

import React, { Component } from 'react'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import type { Match } from 'react-router-dom'
import { GetCachedApplicationByID, UpdateApplicationToCache } from '../../queries/application'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Button from 'michelangelo/dist/Components/Buttons/Button'
import PreviewBox from 'michelangelo/dist/Components/PreviewBox'
import Dropdown from 'michelangelo/dist/Components/Dropdown'
import FixedLabelInput from 'michelangelo/dist/Components/FixedLabelInput'
import theme from 'michelangelo/dist/Components/styles/theme'
import color from 'michelangelo/dist/Components/styles/color'
import { camelCase, replace, isEmpty, cloneDeep, isEqual } from 'lodash'
import { Mutation } from '@apollo/client/react/components'
import { messages } from '../../i18n/messages'
import { ApolloClient, gql } from '@apollo/client'
import styled from 'styled-components'
import { defineMessages, injectIntl } from 'react-intl'
import CheckBox from '../../Components/Checkbox'
import InfoModal from 'michelangelo/dist/SharedComponents/InfoModal'
import Steps from 'michelangelo/dist/Components/Steps'
import { Editor } from '@tinymce/tinymce-react'

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'

// Theme
import 'tinymce/themes/silver'
// Toolbar icons
import 'tinymce/icons/default'
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css'

// importing the plugin js.
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/help'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/contextmenu'
import Icon from 'michelangelo/dist/Components/Icon'

const Container: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-rows: max-content auto 50px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    min-height: 500px;
    align-items: center;
    justify-items: center;
    min-width: 352px;
    padding: 12px;

    grid-template-areas: 'dropDown steps'
                         'input preview'
                         'buttons buttons';

    @media screen and (max-width: 600px){
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-gap: 20px;
        grid-template-areas:  'dropDown'
                              'steps'
                              'input'
                              'preview'
                              'buttons';
    }
`
const ButtonsContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-self: center;
    grid-gap: 16px;

    grid-area: buttons;

    @media screen and (min-width: 600px){
                grid-column: 1/3;
                grid-gap: 24px;
    }
`
const DropDownContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-rows: 40px auto;
    grid-template-columns: 1fr;
    width: 60%;
    justify-items: start;
    justify-self: start;
    grid-area: dropDown;

    @media screen and (max-width: 600px){
      justify-items: stretch;
      justify-self: center;
      align-items: center;
      width: 320px;
    }
`
const CustomizeInputContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    grid-template-rows: 40px 60px;
    grid-template-columns: 1fr;
    width: 60%;
    justify-self: start;
    grid-area: input;

    @media screen and (max-width: 600px){
      align-items: center;
      justify-self: center;
      width: 320px;
    }
`
const PreviewContainer: ReactComponentStyled<{}> = styled.div`
    display: grid;
    width: inherit;
    align-self: start;
    justify-items: center;
    grid-area: preview;

    @media screen and (max-width: 600px){
       max-width: 400px;
    }
`
const StepsContainer: ReactComponentStyled<{}> = styled.div`
      grid-area: steps;
      max-width: 550px;
      @media screen and (max-width: 600px){
       max-width: 400px;
    }
`
const EditorContainer: ReactComponentStyled<{}> = styled.div`
  ${(props) => {
    if (props.display) {
      return `
        display: block;
    `
    } else {
      return `
        display: none;
    `
    }
  }}
`
const CountP: ReactComponentStyled<{}> = styled.p`
  float: right;
`
const Center: ReactComponentStyled<{}> = styled.div`
    justify-self: center;
`
const CustomizeHtmlText: ReactComponentStyled<{}> = styled.div`
  margin: 15px 0;`

const SAVE_LOGIN_FIELDS = gql`
mutation setCustoms($application:String!, $loginRegCodePlaceholder:String, $loginOpenRegPlaceholder:String, $customInfo: CustomInfoInput) {
    setCustomTexts(application: $application, loginRegCodePlaceholder: $loginRegCodePlaceholder, loginOpenRegPlaceholder: $loginOpenRegPlaceholder )
    {
      _id
      customTexts {
        loginOpenRegPlaceholder
        loginRegCodePlaceholder
        registerRegCodeAccountPlaceholder
        registerRegCodeTooltip
        registerRegCodeEmailPlaceholder
        registerOpenRegEmailPlaceholder
        btnSingleSignOn
        btnOpenReg
        btnNotEmployee
        btnCreateOrLogin
        }
    }
    setCustomInfo(application: $application, customInfo: $customInfo)
    {
      _id
      customInfo {
        openReg {
          email {
            hasCustomInfo
            customInfoData
          }
          password {
            hasCustomInfo
            customInfoData
          }

        }
        regCode {
          email {
            hasCustomInfo
            customInfoData
          }
          password {
            hasCustomInfo
            customInfoData
          }
        }
      }
    }
  }
`

// add for testing purpose
Container.displayName = 'Container'
ButtonsContainer.displayName = 'ButtonsContainer'
EditorContainer.displayName = 'EditorContainer'
DropDownContainer.displayName = 'DropDownContainer'
CustomizeInputContainer.displayName = 'CustomizeInputContainer'
PreviewContainer.displayName = 'PreviewContainer'

type LoginProps = {
    application: Object,
    match: Match,
    client: ApolloClient
} & InjectIntlProvidedProps

type LoginState = {
    selectedAuthType: string,
    csv: string,
    openRegistration: string,
    placeholder: Object
}

const CSV = 'CSV'
const OPEN_REGISTRATION = 'Open Registration'
export const fieldMapping = {
  CSV: 'regCode',
  'Open Registration': 'openReg'
}

let labelPrefix = ''

export const LoginMessages = defineMessages({
  labelPrefix: {
    id: 'customLogin.labelPrefix',
    defaultMessage: 'Enter'
  },
  Email: {
    id: 'customLogin.Email',
    defaultMessage: 'Email'
  },
  emailOrAccount: {
    id: 'customLogin.emailOrAccount',
    defaultMessage: 'Email or Account ID'
  },
  openRegistration: {
    id: 'customLogin.openRegistration',
    defaultMessage: 'Open Registration'
  },
  csv: {
    id: 'customLogin.csv',
    defaultMessage: 'CSV'
  },
  Password: {
    id: 'customLogin.Password',
    defaultMessage: 'Password'
  },
  AddInfoIcon: {
    id: 'customLogin.AddInfoIcon',
    defaultMessage: ' Add informational icon and text'
  }
})

const htmlInputSizeLimit = 2500
const charCount = (editor) => editor.getContent({ format: 'text' }).length

class LoginComponent extends Component<LoginProps, LoginState> {
  constructor (props: LoginProps) {
    super(props)

    const { selectedAuthType, csv, openRegistration, stepNames, csvInputPlaceholder, openRegistrationInputPlaceholder, customInfoObject, initialCustomInfoObject } = this.getInitialValues()
    this.state = {
      selectedAuthType: selectedAuthType,
      csv: csv,
      openRegistration: openRegistration,
      placeholder: {
        csv: csvInputPlaceholder,
        openRegistration: openRegistrationInputPlaceholder
      },
      stepNames: stepNames,
      currentPreviewIndex: 0,
      currentStepName: '',
      customInfoObject,
      initialCustomInfoObject,
      customInfoChanged: true
    }

    const { hasCustomInfo, customInfoData } = this.getCustomInfo(selectedAuthType, 0)
    this.state = {
      ...this.state,
      htmlInputData: hasCustomInfo && customInfoData ? customInfoData : '',
      htmlInputCount: 0,
      htmlInputDisabled: !hasCustomInfo,
      infoChecked: hasCustomInfo,
      showInfoIcon: hasCustomInfo && customInfoData,
      showModal: false,
      initialHtmlInputData: hasCustomInfo && customInfoData ? customInfoData : ''
    }
  }

  getInitialValues = () => {
    const { intl: { formatMessage }, application: { registrationCodeEnabled, customTexts, customInfo } } = this.props
    const loginRegCodePlaceholder = customTexts ? customTexts.loginRegCodePlaceholder : ''
    const loginOpenRegPlaceholder = customTexts ? customTexts.loginOpenRegPlaceholder : ''
    labelPrefix = formatMessage(LoginMessages.labelPrefix) + ' '

    const registrationCodeInput = this.noPrefix(loginRegCodePlaceholder)
    const openRegistrationInput = this.noPrefix(loginOpenRegPlaceholder)

    const selectedAuthType = registrationCodeEnabled ? CSV : OPEN_REGISTRATION
    const customInfoObject = {}
    if (customInfo && customInfo.openReg) {
      customInfoObject.openReg = {
        email: {
          hasCustomInfo: (customInfo.openReg.email && customInfo.openReg.email.hasCustomInfo) ? customInfo.openReg.email.hasCustomInfo : false,
          customInfoData: (customInfo.openReg.email && customInfo.openReg.email.customInfoData) ? customInfo.openReg.email.customInfoData : ''
        },
        password: {
          hasCustomInfo: customInfo.openReg.password && customInfo.openReg.password.hasCustomInfo ? customInfo.openReg.password.hasCustomInfo : false,
          customInfoData: customInfo.openReg.password && customInfo.openReg.password.customInfoData ? customInfo.openReg.password.customInfoData : ''
        }
      }
    }
    if (customInfo && customInfo.regCode) {
      customInfoObject.regCode = {
        email: {
          hasCustomInfo: customInfo.regCode.email && customInfo.regCode.email.hasCustomInfo ? customInfo.regCode.email.hasCustomInfo : false,
          customInfoData: customInfo.regCode.email && customInfo.regCode.email.customInfoData ? customInfo.regCode.email.customInfoData : ''
        },
        password: {
          hasCustomInfo: customInfo.regCode.password && customInfo.regCode.password.hasCustomInfo ? customInfo.regCode.password.hasCustomInfo : false,
          customInfoData: customInfo.regCode.password && customInfo.regCode.password.customInfoData ? customInfo.regCode.password.customInfoData : ''
        }
      }
    }

    const csvStepNames = [formatMessage(LoginMessages.emailOrAccount), formatMessage(LoginMessages.Password)]
    const openRegistrationStepNames = [formatMessage(LoginMessages.Email), formatMessage(LoginMessages.Password)]

    const stepNames = registrationCodeEnabled ? csvStepNames : openRegistrationStepNames

    const openRegistration = {
      Email: '',
      Password: ''
    }
    const openRegistrationInputPlaceholder = {
      Email: openRegistrationInput || formatMessage(LoginMessages.Email),
      Password: formatMessage(LoginMessages.Password)
    }

    const csv = {
      Email: '',
      Password: ''
    }
    const csvInputPlaceholder = {
      Email: registrationCodeInput || formatMessage(LoginMessages.emailOrAccount),
      Password: formatMessage(LoginMessages.Password)
    }
    return { selectedAuthType, csv, openRegistration, stepNames, csvInputPlaceholder, openRegistrationInputPlaceholder, customInfoObject, initialCustomInfoObject: cloneDeep(customInfoObject) }
  }

  noPrefix = (value: string) => (replace(value, labelPrefix, ''))

  handleInputChange = (inputValue: string) => {
    const { selectedAuthType, [camelCase(selectedAuthType)]: inputValues, currentPreviewIndex } = this.state
    const currentStepName = Object.keys(inputValues)[currentPreviewIndex]
    this.setState(prevState => ({
      customInfoChanged: false,
      [camelCase(selectedAuthType)]: {
        ...prevState[camelCase(selectedAuthType)],
        [currentStepName]: inputValue
      }
    }))
  }

  getInputAndPreview = () => {
    const { selectedAuthType, [camelCase(selectedAuthType)]: inputValues, placeholder: { [camelCase(selectedAuthType)]: placeholderValues }, currentPreviewIndex } = this.state
    const { intl: { formatMessage } } = this.props
    const currentStepName = Object.keys(inputValues)[currentPreviewIndex]
    const inputValue = inputValues[currentStepName]
    const placeholderValue = placeholderValues[currentStepName]

    const inputField = <FixedLabelInput fixedLabel placeholder={placeholderValue} label={labelPrefix} value={inputValue} onChange={this.handleInputChange} />
    let customizeInput
    if (['Password'].includes(currentStepName)) {
      customizeInput = (
        <React.Fragment>
          <Center><Icon iconName='warning' color={theme.danger} /></Center>
          <Text text={formatMessage(messages.fieldsNotCustomizable)} fontColor={theme.danger} textSize='p' align='center' />
        </React.Fragment>
      )
    } else {
      customizeInput = (
        <React.Fragment>
          <Text text={formatMessage(messages.customizeText)} fontColor={theme.info} textSize='p' />
          {inputField}
        </React.Fragment>
      )
    }
    let emailORAccountIDPreview
    let emailPreview
    let previewInput
    // eslint-disable-next-line react/jsx-handler-names
    const passwordPreview = <FixedLabelInput value='' label={placeholderValues.Password} iconName='info' hasIcon={this.state.showInfoIcon} onClickIcon={this.iconClick} />
    if (selectedAuthType === CSV) {
      // eslint-disable-next-line react/jsx-handler-names
      emailORAccountIDPreview = <FixedLabelInput value='' label={labelPrefix + (inputValues.Email || placeholderValues.Email)} iconName='info' hasIcon={this.state.showInfoIcon} onClickIcon={this.iconClick} />
      previewInput = { emailORAccountIDPreview, passwordPreview }
    } else {
      // eslint-disable-next-line react/jsx-handler-names
      emailPreview = <FixedLabelInput value='' label={labelPrefix + (inputValues.Email || placeholderValues.Email)} iconName='info' hasIcon={this.state.showInfoIcon} onClickIcon={this.iconClick} />
      previewInput = { emailPreview, passwordPreview }
    }

    return { customizeInput, previewInput }
  }

  iconClick = () => {
    this.setState({ showModal: true })
  }

  getCustomInfo = (selectedAuthType, currentPreviewIndex, customInfoObj) => {
    const customInfoObject = customInfoObj || this.state.customInfoObject
    let hasCustomInfo = false
    let customInfoData = ''
    if (customInfoObject && customInfoObject[fieldMapping[selectedAuthType]]) {
      const { [camelCase(selectedAuthType)]: inputValues } = this.state
      const inputKeys = Object.keys(inputValues)
      let currentPreviewIndexFinal = this.state.currentPreviewIndex
      if (currentPreviewIndex !== undefined && currentPreviewIndex !== this.state.currentPreviewIndex) { currentPreviewIndexFinal = currentPreviewIndex }
      const selectedField = inputKeys[currentPreviewIndexFinal].toLowerCase()
      const selectedFieldInfo = customInfoObject[fieldMapping[selectedAuthType]][selectedField]
      hasCustomInfo = selectedFieldInfo && selectedFieldInfo.hasCustomInfo ? selectedFieldInfo.hasCustomInfo : false
      customInfoData = selectedFieldInfo && selectedFieldInfo.customInfoData ? selectedFieldInfo.customInfoData : ''
    }
    return { hasCustomInfo, customInfoData }
  }

  handleOnDropDownChange = (selectedValue: string) => {
    const { intl: { formatMessage } } = this.props
    const csv = formatMessage(LoginMessages.csv)

    const openRegistrationStepNames = [formatMessage(LoginMessages.Email), formatMessage(LoginMessages.Password)]
    const csvStepNames = [formatMessage(LoginMessages.emailOrAccount), formatMessage(LoginMessages.Password)]

    const stepNames = selectedValue === csv ? csvStepNames : openRegistrationStepNames
    const selectedAuthType = selectedValue === csv ? CSV : OPEN_REGISTRATION

    const { hasCustomInfo, customInfoData } = this.getCustomInfo(selectedAuthType, 0)
    this.setState({
      selectedAuthType: selectedAuthType,
      currentPreviewIndex: 0,
      stepNames,
      htmlInputData: hasCustomInfo && customInfoData ? customInfoData : '',
      htmlInputDisabled: !hasCustomInfo,
      infoChecked: hasCustomInfo,
      showInfoIcon: hasCustomInfo && customInfoData,
      showModal: false
    })
  }

  checkBoxChangeHandler = (label: string, value) => {
    const { selectedAuthType, [camelCase(selectedAuthType)]: inputValues, customInfoObject, htmlInputData, htmlInputCount, initialCustomInfoObject } = this.state
    const inputKeys = Object.keys(inputValues)
    const selectedField = inputKeys[this.state.currentPreviewIndex || 0].toLowerCase()
    let updatedCustomInfoObject = customInfoObject
    if (!isEmpty(customInfoObject) && updatedCustomInfoObject[fieldMapping[selectedAuthType]] && updatedCustomInfoObject[fieldMapping[selectedAuthType]][selectedField]) {
      updatedCustomInfoObject[fieldMapping[selectedAuthType]][selectedField].hasCustomInfo = value
    } else {
      updatedCustomInfoObject = this.addProps({}, [fieldMapping[selectedAuthType], selectedField, 'hasCustomInfo'], value)
    }
    this.setState({
      customInfoObject: updatedCustomInfoObject,
      htmlInputData: value ? htmlInputData : '', // if you want to don't delete previous html, comment this line
      htmlInputCount: value ? htmlInputCount : 0, // if you want to don't delete previous html, comment this line
      customInfoChanged: isEqual(updatedCustomInfoObject, initialCustomInfoObject),
      htmlInputDisabled: !value,
      showInfoIcon: value,
      infoChecked: value
    })
  }

  handleInit = (value, editor) => {
    this.setState({
      htmlInputCount: charCount(editor)
    })
  }

  addProps = (obj, arr, val) => {
    if (typeof arr === 'string') { arr = arr.split('.') }
    obj[arr[0]] = obj[arr[0]] || {}
    const tmpObj = obj[arr[0]]
    if (arr.length > 1) {
      arr.shift()
      this.addProps(tmpObj, arr, val)
    } else { obj[arr[0]] = val }
    return obj
  }

  handleUpdate = (value, editor) => {
    const cCount = charCount(editor)
    if (cCount <= htmlInputSizeLimit) {
      // update customInfoObject
      const { selectedAuthType, [camelCase(selectedAuthType)]: inputValues, customInfoObject, initialCustomInfoObject } = this.state
      const inputKeys = Object.keys(inputValues)
      const selectedField = inputKeys[this.state.currentPreviewIndex || 0].toLowerCase()
      let updatedCustomInfoObject = cloneDeep(customInfoObject)
      if (!isEmpty(customInfoObject)) {
        if (updatedCustomInfoObject[fieldMapping[selectedAuthType]] && updatedCustomInfoObject[fieldMapping[selectedAuthType]][selectedField]) {
          updatedCustomInfoObject[fieldMapping[selectedAuthType]][selectedField].customInfoData = value
        } else {
          updatedCustomInfoObject[fieldMapping[selectedAuthType]] = {}
          updatedCustomInfoObject[fieldMapping[selectedAuthType]][selectedField] = {}
          updatedCustomInfoObject[fieldMapping[selectedAuthType]][selectedField].customInfoData = value
          updatedCustomInfoObject[fieldMapping[selectedAuthType]][selectedField].hasCustomInfo = true
        }
      } else {
        updatedCustomInfoObject = this.addProps(customInfoObject, [fieldMapping[selectedAuthType], selectedField, 'customInfoData'], value)
        updatedCustomInfoObject = this.addProps(customInfoObject, [fieldMapping[selectedAuthType], selectedField, 'hasCustomInfo'], true)
      }
      this.setState({
        customInfoObject: updatedCustomInfoObject,
        htmlInputData: value,
        htmlInputCount: cCount,
        customInfoChanged: isEqual(updatedCustomInfoObject, initialCustomInfoObject)
      })
    }
  }

  handleBeforeAddUndo = (evt, editor) => {
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (charCount(editor) > htmlInputSizeLimit) {
      evt.preventDefault()
    }
  }

  resetInputValues = () => {
    const { csv, openRegistration, currentPreviewIndex, initialCustomInfoObject } = this.state
    this.setState({ customInfoObject: cloneDeep(initialCustomInfoObject) })
    const { hasCustomInfo, customInfoData } = this.getCustomInfo(this.state.selectedAuthType, currentPreviewIndex, initialCustomInfoObject)
    this.setState({
      csv: {
        ...csv,
        Email: '',
        Password: ''
      },
      openRegistration: {
        ...openRegistration,
        Email: '',
        Password: ''
      },
      showModal: false,
      customInfoChanged: true,
      hasCustomInfo,
      customInfoData,
      infoChecked: hasCustomInfo,
      htmlInputDisabled: !hasCustomInfo
    })
    // tinymce.execCommand("mceRepaint");
    tinymce.execCommand('mceSetContent', false, customInfoData)
  }

  onMutationCompleted = () => {
    const { placeholder, customInfoObject } = this.state
    const { customTexts } = GetCachedApplicationByID(this.props.client, this.props.match.params.id || '')
    const registrationCodeInput = customTexts && customTexts.loginRegCodePlaceholder ? this.noPrefix(customTexts.loginRegCodePlaceholder) : null
    const openRegistrationInput = customTexts && customTexts.loginOpenRegPlaceholder ? this.noPrefix(customTexts.loginOpenRegPlaceholder) : null

    this.setState({
      placeholder: {
        csv: {
          ...placeholder.csv,
          ...(customTexts && customTexts.loginRegCodePlaceholder ? { Email: registrationCodeInput } : {})

        },
        openRegistration: {
          ...placeholder.openRegistration,
          ...(customTexts && customTexts.loginOpenRegPlaceholder ? { Email: openRegistrationInput } : {})
        }

      },
      // initialCustomInfoObject: {...customInfoObject}
      initialCustomInfoObject: cloneDeep(customInfoObject),
      customInfoChanged: true
    })
    // this.resetInputValues()
  }

  handleOnCancelClick = () => {
    this.resetInputValues()
  }

  SaveButton = () => {
    const { intl: { formatMessage }, application } = this.props
    const { csv, openRegistration, customInfoObject, customInfoChanged } = this.state

    const registrationCodeLabel = labelPrefix + csv.Email
    const openRegistrationLabel = labelPrefix + openRegistration.Email

    const mutationVariables = {
      application: application._id,
      ...(csv.Email ? { loginRegCodePlaceholder: registrationCodeLabel } : {}),
      ...(openRegistration.Email ? { loginOpenRegPlaceholder: openRegistrationLabel } : {}),
      customInfo: customInfoObject || {}
    }
    return (
      <Mutation
        mutation={SAVE_LOGIN_FIELDS}
        variables={mutationVariables}
        notifyOnNetworkStatusChange
        onCompleted={() => this.onMutationCompleted()}
        update={(cache, result) => {
          const data = cloneDeep({ ...result.data.setCustomInfo, ...result.data.setCustomTexts })
          if (mutationVariables.loginRegCodePlaceholder) data.customTexts.loginRegCodePlaceholder = mutationVariables.loginRegCodePlaceholder
          if (mutationVariables.loginOpenRegPlaceholder) data.customTexts.loginOpenRegPlaceholder = mutationVariables.loginOpenRegPlaceholder
          if (mutationVariables.customInfo) data.customInfo = { ...data.customInfo, ...mutationVariables.customInfo }
          UpdateApplicationToCache(cache, data)
        }}
      >
        {(setCustomData, { data, loading }) => (
          <Button fill title={formatMessage(messages.saveChanges)} disabled={loading || customInfoChanged} onClick={() => setCustomData(mutationVariables)} />
        )}
      </Mutation>
    )
  }

  handleOnPreviewBoxChange = (currentPreviewIndex: number) => {
    this.setState({ currentPreviewIndex })
    const { hasCustomInfo, customInfoData } = this.getCustomInfo(this.state.selectedAuthType, currentPreviewIndex)
    this.setState({
      currentPreviewIndex,
      htmlInputData: hasCustomInfo && customInfoData ? customInfoData : '',
      htmlInputDisabled: !hasCustomInfo,
      infoChecked: hasCustomInfo,
      showInfoIcon: hasCustomInfo && customInfoData,
      showModal: false
    })
  }

  onCancel = () => {
    this.setState({ showModal: false })
  }

  render () {
    const { intl: { formatMessage }, application: { openRegistrationEnabled, registrationCodeEnabled } } = this.props
    const { customizeInput, previewInput } = this.getInputAndPreview()
    const { stepNames, currentPreviewIndex } = this.state
    const csv = formatMessage(LoginMessages.csv)
    const openRegistration = formatMessage(LoginMessages.openRegistration)
    const dropDownList = [...(registrationCodeEnabled ? [csv] : []), ...(openRegistrationEnabled ? [openRegistration] : [])]
    return (
      <Container>
        <DropDownContainer>
          <Text text={formatMessage(messages.selectLoginType)} fontColor={color.textDefault} textSize='p' />
          <Dropdown list={dropDownList} onListItemClick={this.handleOnDropDownChange} />
        </DropDownContainer>
        <CustomizeInputContainer>
          {customizeInput}
          <CheckBox label={formatMessage(LoginMessages.AddInfoIcon)} isChecked={this.state.infoChecked} handleCheckboxChange={this.checkBoxChangeHandler} />
          <EditorContainer display={!this.state.htmlInputDisabled}>
            <CustomizeHtmlText>
              <Text text={formatMessage(messages.customizeHtmlOrText)} fontColor={theme.info} textSize='p' />
            </CustomizeHtmlText>
            <Editor
              apiKey='474j8a3ki0c0g4xpejempyqhbjatnb2hr8pg8eclkwi1endo'
              init={{
                contextmenu: 'copy powerpaste link',
                skin: false,
                content_css: false,
                max_height: 250,
                default_link_target: '_blank',
                menubar: false,
                branding: false,
                link_title: false,
                plugins: [
                  'autolink link lists autoresize paste contextmenu'
                ],
                toolbar: ' bold italic underline | link | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | forecolor backcolor',
                toolbar_mode: 'sliding',
                font_formats: 'Arial=arial;Book Antiqua=book antiqua,palatino;Courier New=courier new,courier;Georgia=georgia,palatino;Terminal=terminal,monaco;Times New Roman=times new roman,times; Helvetica',
                fontsize_formats: '9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt'
              }}
              disabled={this.state.htmlInputDisabled}
              onInit={this.handleInit}
              onEditorChange={this.handleUpdate}
              onBeforeAddUndo={this.handleBeforeAddUndo}
              value={this.state.htmlInputData}
            />
            <CountP>{this.state.htmlInputCount} / {htmlInputSizeLimit}</CountP>
          </EditorContainer>

        </CustomizeInputContainer>
        <StepsContainer>
          <Steps stepNames={stepNames} currentIndex={currentPreviewIndex} />
        </StepsContainer>
        <PreviewContainer>
          <PreviewBox currentIndex={currentPreviewIndex} onChange={this.handleOnPreviewBoxChange}>
            {previewInput}
          </PreviewBox>
          <InfoModal
            body={this.state.htmlInputData.toString()}
            cancelAction={this.onCancel}
            cancelText={formatMessage(messages.cancel)}
            show={this.state.showModal}
          />
        </PreviewContainer>

        <ButtonsContainer>

          <Button outline title={formatMessage(messages.cancel)} onClick={this.handleOnCancelClick} />
          {this.SaveButton()}

        </ButtonsContainer>

      </Container>
    )
  }
}

export default injectIntl(LoginComponent)
export { LoginComponent as UnwrappedLoginComponent }
