// @flow

import { defineMessages } from 'react-intl'

const explorerMessages = defineMessages({
  header: {
    id: 'explorer.header',
    defaultMessage: 'Explorer Page Header'
  },
  description: {
    id: 'explorer.description',
    defaultMessage: 'Select which option you would like the Explore page to have in your Mobile App.'
  },
  saving: {
    id: 'explorer.saving',
    defaultMessage: 'Saving...'
  },
  saved: {
    id: 'explorer.saved',
    defaultMessage: 'Success, saved!'
  },
  somethingWentWrong: {
    id: 'explorer.somethingWentWrong',
    defaultMessage: 'Something went wrong. Please try again.'
  },
  error: {
    id: 'explorer.error',
    defaultMessage: 'Error, please contact us'
  },
  mostPopular: {
    id: 'explorer.mostPopular',
    defaultMessage: 'Most Popular'
  },
  carousel: {
    id: 'explorer.carousel',
    defaultMessage: 'Carousel'
  },
  none: {
    id: 'explorer.none',
    defaultMessage: 'None'
  }
})

export default explorerMessages
