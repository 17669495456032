// @flow

import React, { Component } from 'react'
import withRouter from '../utils/withRouter'
import type { InjectIntlProvidedProps } from 'react-intl'
import styled from 'styled-components'
import { BoxContainer, AlertModal } from '../Components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import theme from 'michelangelo/dist/Components/styles/theme'
import color from 'michelangelo/dist/Components/styles/color'
import Input from 'michelangelo/dist/Components/Input'
import RoundedButton from 'michelangelo/dist/Components/Buttons/RoundedButton'
import Notification from 'michelangelo/dist/Components/Notification'
import GroupTitle from './GroupTitle'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import { isEmpty } from 'lodash'
import { GetMasterAppIntegrationById, UpdateCache } from '../queries/masterAppIntegration'
import type { MutationFunction, OperationVariables } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Mutation } from '@apollo/client/react/components'
import { ApolloClient, gql } from '@apollo/client'

export const CREATE_MASTER_APP_INTEGRATION = gql`
  mutation createMasterAppIntegration($appName:String!,$iosUriScheme:String,$appleAppStoreLink:String,$androidUriScheme:String,$googlePlayStoreLink:String,$webUrl:String,$logoFile:Upload!) {
    createMasterAppIntegration(appName:$appName,iosUriScheme:$iosUriScheme,appleAppStoreLink:$appleAppStoreLink,androidUriScheme:$androidUriScheme,googlePlayStoreLink:$googlePlayStoreLink,webUrl:$webUrl,logoFile:$logoFile) {
      _id
      appName
      createMasterAppIntegrationMessage {
        status
        masterAppIntegrationExists
      }
    }
  }
`
export const UPDATE_MASTER_APP_INTEGRATION = gql`
  mutation updateMasterAppIntegration($masterAppIntegrationId:String!,$appName:String!,$iosUriScheme:String!,$appleAppStoreLink:String!,$androidUriScheme:String!,$googlePlayStoreLink:String!,$webUrl:String!,$logoFile:Upload) {
    updateMasterAppIntegration(masterAppIntegrationId:$masterAppIntegrationId,appName:$appName,iosUriScheme:$iosUriScheme,appleAppStoreLink:$appleAppStoreLink,androidUriScheme:$androidUriScheme,googlePlayStoreLink:$googlePlayStoreLink,webUrl:$webUrl,logoFile:$logoFile) {
      _id
      appName
      iosUriScheme
      androidUriScheme
      appleAppStoreLink
      googlePlayStoreLink
      webUrl
      logoUrl
      updateMasterAppIntegrationMessage {
        status
        masterAppIntegrationExists
      }
    }
  }
`
const Validation = {
  UNSET: 'UNSET',
  VALID: 'VALID',
  INVALID: 'INVALID'
}
type ValidationState = 'UNSET' | 'VALID' | 'INVALID'

type CreateMasterAppIntegrationProps = {
  params: Object,
  navigate: Object,
  client: ApolloClient
} & InjectIntlProvidedProps

type CreateMasterAppIntegrationState = {
  editing: boolean,
  errors: Object,
  masterAppIntegrationId: string,
  appName: string,
  iosUriScheme: string,
  androidUriScheme: string,
  appleAppStoreLink: string,
  googlePlayStoreLink: string,
  webUrl: string,
  logoUrl: string,
  logoFile: File,
  logoFileUrl: null
}

const Title = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  font-size: 30px;

  color: #1A2732;

  display: inline-block;
  margin-top: 66px;
`

const Container = styled.div`
  width: 100%;
  text-align: center;
`
const CenterContainer = styled.div`
  display: inline-block;
`

const LogoContainer = styled.div`
  @media (min-width: 640px) {
    display: grid;
    align-items: center;
    border-top-width: 1px;
    border-color: #E5E7EB;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
  }
`

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

// add for testing purpose
Title.displayName = 'Title'
Container.displayName = 'Container'
CenterContainer.displayName = 'CenterContainer'

class CreateMasterAppIntegration extends Component<CreateMasterAppIntegrationProps, CreateMasterAppIntegrationState> {
  /* istanbul ignore next */
  constructor (props: CreateMasterAppIntegrationProps) {
    super(props)
    this.logoFileInput = React.createRef()
    let masterAppIntegrationId = ''
    let editing = false
    if (props.params.masterAppIntegrationId) {
      editing = true
      masterAppIntegrationId = props.params.masterAppIntegrationId
    }
    this.state = {
      editing,
      errors: {
        appName: {},
        iosUriScheme: {},
        appleAppStoreLink: {},
        androidUriScheme: {},
        googlePlayStoreLink: {},
        iosLinks: {},
        androidLinks: {},
        links: {},
        logoFile: {},
        webUrl: {}
      },
      masterAppIntegrationId,
      appName: '',
      iosUriScheme: '',
      appleAppStoreLink: '',
      androidUriScheme: '',
      googlePlayStoreLink: '',
      webUrl: '',
      logoUrl: '',
      logoFile: null
    }
  }

  /* istanbul ignore next */
  async componentDidMount () {
    const { client } = this.props
    const { editing, masterAppIntegrationId } = this.state
    if (!editing) {
      return
    }
    const masterAppIntegration = await GetMasterAppIntegrationById(client, masterAppIntegrationId)
    this.setState((prevState: CreateMasterAppIntegrationState) => {
      prevState.appName = masterAppIntegration.appName
      prevState.iosUriScheme = masterAppIntegration.iosUriScheme
      prevState.appleAppStoreLink = masterAppIntegration.appleAppStoreLink
      prevState.androidUriScheme = masterAppIntegration.androidUriScheme
      prevState.googlePlayStoreLink = masterAppIntegration.googlePlayStoreLink
      prevState.webUrl = masterAppIntegration.webUrl
      prevState.logoUrl = masterAppIntegration.logoUrl
      return prevState
    })
  }

  onChange (target: string, value: string) {
    this.setState((prevState: CreateMasterAppIntegrationState) => {
      prevState[target] = value
      const otherTargets = []

      const links = ['iosUriScheme', 'appleAppStoreLink', 'androidUriScheme', 'googlePlayStoreLink', 'webUrl']

      if (links.includes(target)) {
        otherTargets.push('iosUriScheme')
        otherTargets.push('appleAppStoreLink')
        otherTargets.push('androidUriScheme')
        otherTargets.push('googlePlayStoreLink')
        otherTargets.push('webUrl')
        otherTargets.push('iosLinks')
        otherTargets.push('androidLinks')
        otherTargets.push('links')
      }

      otherTargets.forEach((ot) => {
        prevState.errors[ot] = {
          isValid: Validation.UNSET,
          messages: ''
        }
      })

      prevState.errors[target] = {
        isValid: Validation.UNSET,
        messages: ''
      }
      return prevState
    })
  }

  handleLogoInputChange (file: File) {
    this.setState((prevState: CreateMasterAppIntegrationState) => {
      prevState.logoFile = file
      prevState.logoFileUrl = URL.createObjectURL(file)
      prevState.errors.logoFile = {
        isValid: Validation.UNSET,
        messages: ''
      }
      return prevState
    })
  }

  goToMasterAppIntegrations () {
    this.props.navigate('/app-integrations')
  }

  createMutationVariables (state: CreateMasterAppIntegrationState) {
    const { masterAppIntegrationId, appName, iosUriScheme, appleAppStoreLink, androidUriScheme, googlePlayStoreLink, webUrl, logoFile } = state
    return {
      variables: {
        masterAppIntegrationId,
        appName,
        iosUriScheme,
        androidUriScheme,
        appleAppStoreLink,
        googlePlayStoreLink,
        webUrl,
        logoFile
      }
    }
  }

  masterAppIntegrationValidation (state: CreateMasterAppIntegrationState): CreateMasterAppIntegrationState {
    const { intl: { formatMessage } } = this.props

    const { appName, iosUriScheme, appleAppStoreLink, androidUriScheme, googlePlayStoreLink, webUrl, logoFile, logoUrl } = state

    const hasEmptyIosLinks = isEmpty(iosUriScheme) && isEmpty(appleAppStoreLink)
    const hasEmptyAndroidLinks = isEmpty(androidUriScheme) && isEmpty(googlePlayStoreLink)

    const hasIosUriSchemeAndNotAppleAppStoreLink = !isEmpty(iosUriScheme) && isEmpty(appleAppStoreLink)
    const hasAppleAppStoreLinkAndNotIosUriScheme = !isEmpty(appleAppStoreLink) && isEmpty(iosUriScheme)

    const hasAndroidUriSchemeAndNotGooglePlayStoreLink = !isEmpty(androidUriScheme) && isEmpty(googlePlayStoreLink)
    const hasGooglePlayStoreLinkAndNotAndroidUriScheme = !isEmpty(googlePlayStoreLink) && isEmpty(androidUriScheme)

    const iosLinksValidationFail = hasIosUriSchemeAndNotAppleAppStoreLink || hasAppleAppStoreLinkAndNotIosUriScheme
    const androidLinksValidationFail = hasAndroidUriSchemeAndNotGooglePlayStoreLink || hasGooglePlayStoreLinkAndNotAndroidUriScheme

    if (iosLinksValidationFail) {
      state.errors.iosLinks.isValid = Validation.INVALID
      state.errors.iosLinks.message = formatMessage(messages.emptyField)
      if (hasIosUriSchemeAndNotAppleAppStoreLink) {
        state.errors.appleAppStoreLink.isValid = Validation.INVALID
        state.errors.appleAppStoreLink.message = formatMessage(messages.emptyField)
      }
      if (hasAppleAppStoreLinkAndNotIosUriScheme) {
        state.errors.iosUriScheme.isValid = Validation.INVALID
        state.errors.iosUriScheme.message = formatMessage(messages.emptyField)
      }
    } else {
      state.errors.iosUriScheme.isValid = Validation.VALID
      state.errors.appleAppStoreLink.isValid = Validation.VALID
      state.errors.iosLinks.isValid = Validation.VALID
    }

    if (androidLinksValidationFail) {
      state.errors.androidLinks.isValid = Validation.INVALID
      state.errors.androidLinks.message = formatMessage(messages.emptyField)
      if (hasAndroidUriSchemeAndNotGooglePlayStoreLink) {
        state.errors.googlePlayStoreLink.isValid = Validation.INVALID
        state.errors.googlePlayStoreLink.message = formatMessage(messages.emptyField)
      }
      if (hasGooglePlayStoreLinkAndNotAndroidUriScheme) {
        state.errors.androidUriScheme.isValid = Validation.INVALID
        state.errors.androidUriScheme.message = formatMessage(messages.emptyField)
      }
    } else {
      state.errors.androidUriScheme.isValid = Validation.VALID
      state.errors.googlePlayStoreLink.isValid = Validation.VALID
      state.errors.androidLinks.isValid = Validation.VALID
    }

    if (isEmpty(appName)) {
      state.errors.appName.isValid = Validation.INVALID
      state.errors.appName.message = formatMessage(messages.masterAppIntegrationNameIsRequired)
    } else if (appName.length < 2) {
      state.errors.appName.isValid = Validation.INVALID
      state.errors.appName.message = formatMessage(messages.masterAppIntegrationMinTwoChars)
    } else {
      state.errors.appName.isValid = Validation.VALID
    }

    if (!logoFile && !logoUrl) {
      state.errors.logoFile.isValid = Validation.INVALID
      state.errors.logoFile.message = formatMessage(messages.masterAppIntegrationLogoIsRequired)
    }

    if (isEmpty(webUrl) && hasEmptyIosLinks && hasEmptyAndroidLinks) {
      state.errors.links.isValid = Validation.INVALID
      state.errors.iosUriScheme.isValid = Validation.INVALID
      state.errors.androidUriScheme.isValid = Validation.INVALID
      state.errors.webUrl.isValid = Validation.INVALID
      state.errors.links.message = formatMessage(messages.masterAppIntegrationLinkUrlRequired)
    } else {
      state.errors.links.isValid = Validation.VALID
    }

    return state
  }

  save (saveMasterAppIntegration: MutationFunction<any, OperationVariables>) {
    this.setState((prevState: CreateMasterAppIntegrationState, props: CreateMasterAppIntegrationProps) => {
      prevState = this.masterAppIntegrationValidation(prevState)
      if (this.isValid(prevState)) {
        const variables = this.createMutationVariables(prevState)
        saveMasterAppIntegration(variables)
      }
      return prevState
    })
  }

  isValid (state?: CreateMasterAppIntegrationState): boolean {
    const { errors } = state || this.state
    return errors.appName.isValid !== Validation.INVALID &&
        (errors.webUrl.isValid !== Validation.INVALID &&
            errors.iosLinks.isValid !== Validation.INVALID &&
            errors.androidLinks.isValid !== Validation.INVALID) &&
        errors.logoFile.isValid !== Validation.INVALID
  }

  getInputTheme (validation: ValidationState) {
    if (validation === Validation.UNSET) {
      return theme.lightGrey
    } else if (validation === Validation.INVALID) {
      return theme.danger
    }
    return theme.success
  }

  getErrorText (text: string) {
    if (isEmpty(text)) {
      return null
    }
    return <Text textSize='p' fontColor={theme.danger} text={text} />
  }

  getInputField (name: string, errorName?: string, showError?: boolean, serverError?: string) {
    const { intl: { formatMessage } } = this.props
    const error = isEmpty(serverError) ? this.getErrorText(this.state.errors[errorName || name].message) : this.getErrorText(serverError)
    const isValid = isEmpty(serverError) ? this.state.errors[errorName || name].isValid : Validation.INVALID
    const value = this.state[name]
    return (
      <React.Fragment>
        <Input _lr-hide theme={this.getInputTheme(isValid)} type='text' value={value} onChange={(value) => this.onChange(name, value)} label={formatMessage(messages[name])} />
        {showError && error}
      </React.Fragment>
    )
  }

  getMasterAppIntegrationNameField (data) {
    const { intl: { formatMessage } } = this.props
    let serverError = null
    if (data && data.masterAppIntegrationExists) {
      serverError = formatMessage(messages.masterAppIntegrationExists)
    }
    return this.getInputField('appName', 'appName', true, serverError)
  }

  getIosUriSchemeField () {
    return this.getInputField('iosUriScheme', 'iosUriScheme', true)
  }

  getAndroidUriSchemeField () {
    return this.getInputField('androidUriScheme', 'androidUriScheme', true)
  }

  getAppleAppStoreField () {
    return this.getInputField('appleAppStoreLink', 'appleAppStoreLink', true)
  }

  getGooglePlayStoreField () {
    return this.getInputField('googlePlayStoreLink', 'googlePlayStoreLink', true)
  }

  getWebUrlField () {
    return this.getInputField('webUrl', 'webUrl', true)
  }

  getLogoFileField () {
    const error = this.getErrorText(this.state.errors.logoFile.message)
    const { logoUrl, logoFileUrl } = this.state
    return (
      <div>
        <LogoContainer>
          <div style={{ marginTop: '0.25rem', gridColumn: 'span 2 / span 2' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RoundedButton onClick={() => this.logoFileInput.current.click()} fill theme={theme.info}>UPLOAD LOGO</RoundedButton>
              {(logoFileUrl || logoUrl) && (
                <span style={{ overflow: 'hidden', backgroundColor: '#F3F4F6' }}>
                  <img src={logoFileUrl || logoUrl} alt='Logo' style={{ border: 'solid 1px black', width: '90px', height: '90px', objectFit: 'contain' }} />
                </span>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {error}
            </div>
          </div>
        </LogoContainer>

        <input id='file-upload' name='file-upload' style={{ display: 'none' }} type='file' ref={this.logoFileInput} onChange={(e) => this.handleLogoInputChange(e.target.files[0])} />
      </div>
    )
  }

  getModal (status: boolean, id: string) {
    if (!status) {
      return null
    }

    const { intl: { formatMessage } } = this.props
    const { editing } = this.state
    const modalText = formatMessage(editing ? messages.masterAppIntegrationUpdateSuccess : messages.masterAppIntegrationCreateSuccess)
    return (
      <AlertModal
        buttonAction={() => this.goToMasterAppIntegrations()}
        icon='checkCircle'
        buttonText={formatMessage(messages.ok)}
        headerText={formatMessage(messages.masterAppIntegrationCreateSuccessHeader)}
        modalText={modalText}
        modalTheme={color.success}
        modalFontColor='success'
      />
    )
  }

  getMasterAppIntegrationRow (data: any) {
    const appName = this.getMasterAppIntegrationNameField(data)
    const iosUriScheme = this.getIosUriSchemeField(data)
    const appleAppStoreField = this.getAppleAppStoreField(data)
    const androidUriScheme = this.getAndroidUriSchemeField(data)
    const googlePlayStoreField = this.getGooglePlayStoreField(data)
    const webUrl = this.getWebUrlField(data)
    const logoFile = this.getLogoFileField(data)
    const { intl: { formatMessage } } = this.props

    const linksError = this.getErrorText(this.state.errors.links.message)

    return (
      <React.Fragment>
        <Row>
          <Column>
            <GroupTitle number='1' title={formatMessage(messages.masterAppIntegrationDetails)} />
          </Column>
        </Row>
        <Row>
          <Column>{appName}</Column>
          <Column>{logoFile}</Column>
        </Row>
        <Row>
          <Column>
            <GroupTitle number='2' title={formatMessage(messages.masterAppIntegrationLinks)} />
          </Column>
        </Row>
        <Row>
          <Column>
            {iosUriScheme}
            <br />
            {appleAppStoreField}
          </Column>
          <Column>
            {androidUriScheme}
            <br />
            {googlePlayStoreField}
          </Column>
          <Column>
            <div className='text-center center'>
              {webUrl}
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <FlexCenter>{linksError}</FlexCenter>
          </Column>
        </Row>
      </React.Fragment>
    )
  }

  getServerErrorMessage (error: any) {
    const { intl: { formatMessage } } = this.props
    if (!error || !error.graphQLErrors) {
      return null
    }
    let message
    if (error.graphQLErrors.length === 0) {
      message = formatMessage(messages.unexpectedError)
    } else if (error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
      message = error.graphQLErrors[0].extensions.exception.details
    } else {
      message = formatMessage(messages.unexpectedError)
    }
    return <Notification show message={message} autoClose={5} type='error' />
  }

  getLoadingNotification (loading: boolean) {
    const { intl: { formatMessage } } = this.props
    const { editing } = this.state
    const updatingTitle = formatMessage(editing ? messages.masterAppIntegrationUpdating : messages.masterAppIntegrationCreating)
    if (loading) {
      return <Notification show message={updatingTitle} autoClose={0} type='loading' />
    }
    return null
  }

  getBody (saveMasterAppIntegration: MutationFunction<any, OperationVariables>, masterAppIntegration: any, loading: boolean, error: any) {
    const isValid = this.isValid()
    const { intl: { formatMessage } } = this.props
    const { editing } = this.state
    let data = null
    let status = false
    let id = ''
    if (masterAppIntegration) {
      console.info({ masterAppIntegration })
      data = editing ? masterAppIntegration.updateMasterAppIntegrationMessage : masterAppIntegration.createMasterAppIntegrationMessage
      status = editing ? masterAppIntegration.updateMasterAppIntegrationMessage.status : masterAppIntegration.createMasterAppIntegrationMessage.status
      id = masterAppIntegration._id
    }
    const masterAppIntegrationRow = this.getMasterAppIntegrationRow(data)
    const modal = this.getModal(status, id)

    const title = formatMessage(messages.configureMasterAppIntegration)
    const save = formatMessage(messages.masterAppIntegrationSaveButton)
    const notification = this.getServerErrorMessage(error)
    const loadingNotification = this.getLoadingNotification(loading)

    return (
      <BoxContainer>
        {modal}
        {notification}
        {loadingNotification}
        <Container>
          <CenterContainer>
            <Row>
              <Column><Title>{title}</Title></Column>
            </Row>
            {masterAppIntegrationRow}
            <Row classNames={['is-mobile']}>
              <Column>
                <RoundedButton onClick={() => this.goToMasterAppIntegrations()} width='118px' theme={theme.danger} textTheme={theme.danger} disabled={loading}>{formatMessage(messages.cancel)}</RoundedButton>
              </Column>
              <Column>
                <RoundedButton onClick={() => this.save(saveMasterAppIntegration)} width='118px' theme={isValid ? theme.success : theme.lightGrey} fill disabled={!isValid || loading}>{save}</RoundedButton>
              </Column>
            </Row>
          </CenterContainer>
        </Container>
      </BoxContainer>
    )
  }

  getNewMasterAppIntegrationMutation () {
    return (
      <Mutation
        mutation={CREATE_MASTER_APP_INTEGRATION}
      >
        {(createMasterAppIntegration, { loading, error, data, client }) => {
          return this.getBody(createMasterAppIntegration, (data) ? data.createMasterAppIntegration : null, loading, error)
        }}
      </Mutation>
    )
  }

  getEditMasterAppIntegrationMutation () {
    const { masterAppIntegrationId, appName, iosUriScheme, appleAppStoreLink, androidUriScheme, googlePlayStoreLink, webUrl, logoUrl } = this.state
    return (
      <Mutation
        mutation={UPDATE_MASTER_APP_INTEGRATION}
        update={(cache, { data: { updateMasterAppIntegration } }) => UpdateCache(cache, masterAppIntegrationId, appName, iosUriScheme, appleAppStoreLink, androidUriScheme, googlePlayStoreLink, webUrl, logoUrl, updateMasterAppIntegration)}
      >
        {(updateMasterAppIntegration, { loading, error, data, client }) => {
          return this.getBody(updateMasterAppIntegration, (data) ? data.updateMasterAppIntegration : null, loading, error)
        }}
      </Mutation>
    )
  }

  render () {
    return this.state.editing ? this.getEditMasterAppIntegrationMutation(this.props.params.id) : this.getNewMasterAppIntegrationMutation()
  }
}

export default withApollo<CreateMasterAppIntegrationProps>(withRouter(injectIntl(CreateMasterAppIntegration)))
export { CreateMasterAppIntegration as unConnectedCreateMasterAppIntegration }
