// @flow

import React, { Component } from 'react'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { Modal } from '../Components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import Input from 'michelangelo/dist/Components/Input'
import theme from 'michelangelo/dist/Components/styles/theme'
import Notification from 'michelangelo/dist/Components/Notification'
import { messages } from '../i18n/messages'
// $FlowFixMe
import type { ReactComponentStyled } from 'styled-components'
import styled from 'styled-components'

import type { Match, RouterHistory } from 'react-router-dom'
import withRouter from '../utils/withRouter'
import { isEmpty } from 'lodash'
import { GetAuthenticatedUser, GET_AUTHENTICATED_USER } from '../queries/getAuthenticatedUser'
import { UpdateAccountApplications } from '../queries/account'
import { gql } from '@apollo/client'
import type { ApolloClient, ApolloCache, ApolloError, MutationFunction, OperationVariables } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
const TextContainer: ReactComponentStyled<{}> = styled.div`
    margin-top: 20px;
`
TextContainer.displayName = 'TextContainer'
/* istanbul ignore next */
const BodyContainer: ReactComponentStyled<{}> = styled.div`
  text-align: center;
  padding: 0 75px 60px 75px;
  @media (max-width: 600px) {
    width: calc(100% - 100px);
    padding: 0 50px 60px 50px;
  }
  @media (max-width: 360px) {
    width: calc(100% - 70px);
    padding: 0 35px 60px 35px;
  }

  input {
    width: 100%;
  }
`
BodyContainer.displayName = 'BodyContainer'
const Error: ReactComponentStyled<{}> = styled.div`
  width: 100%;
  max-width: 400px;
`
export const CREATE_APPLICATION = gql`
  mutation createApplication($applicationName:String!,$account:String!) {
    createApplication(applicationName:$applicationName,account:$account) {
      _id
      title
      iosBundleId
      androidBundleId
      type
      versions
      subDomain
      ssoEnabled
      openRegistrationEnabled
      registrationCodeEnabled
      hrisIntegrations {
        hrisId: id
        enabled
      }
    }
  }
`

const Validation = {
  UNSET: 'UNSET',
  VALID: 'VALID',
  INVALID: 'INVALID'
}
type ValidationState = 'UNSET' | 'VALID' | 'INVALID'

type CreateProps = {
  history?: RouterHistory,
  match?: Match,
  onCancel: () => void,
  client: ApolloClient<any>
} & InjectIntlProvidedProps

type Field = {
  value: string,
  isValid: ValidationState,
  message: string
}

type CreateState = {
  applicationName: Field,
  accountId: string
}
class Create extends Component<CreateProps, CreateState> {
  constructor (props: CreateProps) {
    super(props)
    this.state = {
      applicationName: {
        value: '',
        isValid: Validation.UNSET,
        message: ''
      },
      accountId: ''
    }
  }

  /* istanbul ignore next */
  async componentDidMount () {
    const { client, match: { params } } = this.props
    let accountId
    if (isEmpty(params.accountId)) {
      const result = await GetAuthenticatedUser(client)
      accountId = result.account._id
    } else {
      accountId = params.accountId
    }
    if (accountId) {
      this.setState({ accountId })
    }
  }

  getHeader () {
    const { intl: { formatMessage } } = this.props
    return (
      <TextContainer>
        <Text text={formatMessage(messages.addNewApplication)} textSize='h1' align='center' fontColor={color.darkestGray} />
      </TextContainer>
    )
  }

  getBody () {
    const { intl: { formatMessage } } = this.props
    const { applicationName: { value, isValid } } = this.state
    const message = this.getErrorText()
    return (
      <BodyContainer>
        <Input
          _lr-hide
          theme={this.getInputTheme(isValid)}
          type='text'
          value={value}
          onChange={(value) => this.onChange(value)}
          label={formatMessage(messages.applicationName)}
        />
        {message}
      </BodyContainer>
    )
  }

  getInputTheme (validation: ValidationState) {
    if (validation === Validation.UNSET) {
      return theme.lightGrey
    } else if (validation === Validation.INVALID) {
      return theme.danger
    }
    return theme.success
  }

  onChange (value: string) {
    this.setState({
      applicationName: {
        value,
        isValid: Validation.UNSET,
        message: ''
      }
    })
  }

  getServerErrorMessage (error: ApolloError): string {
    const { intl: { formatMessage } } = this.props
    if (error && error.graphQLErrors && error.graphQLErrors.length === 0) {
      return formatMessage(messages.unexpectedError)
    } else if (error && error.graphQLErrors && error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
      return this.getErrorFromCode(error.graphQLErrors[0].extensions.exception.stacktrace[0])
    } else {
      return formatMessage(messages.unexpectedError)
    }
  }

  getErrorFromCode (code) {
    const { intl: { formatMessage } } = this.props

    if (code.includes('ValidationError')) {
      return formatMessage(messages.validField)
    }
    if (code.includes('UNAUTHORIZED')) {
      return formatMessage(messages.unauthorized)
    }
    if (code.includes('APPLICATION_EXISTS')) {
      return formatMessage(messages.applicationExists)
    }
    if (code.includes('UNAUTHORIZED')) {
      return formatMessage(messages.unauthorized)
    }
    return formatMessage(messages.unexpectedError)
    // switch (code) {
    //   case 'UNAUTHORIZED':
    //     return formatMessage(messages.unauthorized)
    //   case 'APPLICATION_EXISTS':
    //     return formatMessage(messages.applicationExists)
    //   default:
    //     return formatMessage(messages.unexpectedError)
    // }
  }

  getErrorText () {
    const { applicationName: { message } } = this.state
    if (isEmpty(message)) {
      return null
    }
    return <Error><Text textSize='p' fontColor={theme.danger} text={message} /></Error>
  }

  getLoadingNotification (loading: boolean) {
    const { intl: { formatMessage } } = this.props
    const updatingTitle = formatMessage(messages.creatingApplication)
    if (loading) {
      return <Notification show message={updatingTitle} autoClose={0} type='loading' />
    }
    return null
  }

  getModal (createApplication: MutationFunction<any, OperationVariables>, loading: boolean) {
    const { intl: { formatMessage }, onCancel } = this.props
    const { applicationName: { isValid } } = this.state
    const header = this.getHeader()
    const body = this.getBody()
    const notification = this.getLoadingNotification(loading)
    return (
      <React.Fragment>
        {notification}
        <Modal
          header={header}
          body={body}
          buttonText={formatMessage(messages.saveAccount)}
          buttonAction={() => this.save(createApplication)}
          cancelAction={() => onCancel()}
          cancelText={formatMessage(messages.cancel)}
          disabled={loading || isValid === Validation.INVALID}
        />
      </React.Fragment>
    )
  }

  save (createApplication: MutationFunction<any, OperationVariables>) {
    this.setState((prevState: CreateState, props: CreateProps) => {
      prevState = this.applicationValidation(prevState)

      if (prevState.applicationName.isValid === Validation.VALID) {
        createApplication({
          variables: {
            account: prevState.accountId,
            applicationName: prevState.applicationName.value
          }
        })
      }

      return prevState
    })
  }

  applicationValidation (state: CreateState): CreateState {
    const { intl: { formatMessage } } = this.props
    const { applicationName } = state

    if (isEmpty(applicationName.value)) {
      state.applicationName.isValid = Validation.INVALID
      state.applicationName.message = formatMessage(messages.emptyField)
    } else if (applicationName.value.length < 2) {
      state.applicationName.isValid = Validation.INVALID
      state.applicationName.message = formatMessage(messages.applicationMinTwoChars)
    } else {
      state.applicationName.isValid = Validation.VALID
    }
    return state
  }

  onCompleted (data: any) {
    const { intl: { formatMessage }, history, location: { pathname: url } } = this.props
    if (data.createApplication._id) {
      history.push(`${url}/application/${data.createApplication._id}/AppBuild/ApplicationDetails`)
    } else {
      this.setState(prevState => {
        prevState.applicationName.isValid = Validation.INVALID
        prevState.applicationName.message = formatMessage(messages.unexpectedError)
        return prevState
      })
    }
  }

  onError (error: ApolloError) {
    const message = this.getServerErrorMessage(error)
    this.setState(state => {
      state.applicationName.isValid = Validation.INVALID
      state.applicationName.message = message
      return state
    })
  }

  updateCache (cache: ApolloCache, application: any) {
    if (!application) {
      return
    }
    const { accountId } = this.state
    UpdateAccountApplications(cache, accountId, application._id)
  }

  render () {
    return (
      <Mutation
        mutation={CREATE_APPLICATION}
        onCompleted={(data) => this.onCompleted(data)}
        onError={(error: ApolloError) => this.onError(error)}
        update={(cache, { data }) => this.updateCache(cache, data ? data.createApplication : null)}
        refetchQueries={[{ query: GET_AUTHENTICATED_USER }]}
      >
        {(createApplication, { loading, client }) => {
          return this.getModal(createApplication, loading)
        }}
      </Mutation>
    )
  }
}

export default withApollo<CreateProps>(withRouter(injectIntl(Create)))
export { Create as UnconnectedCreate }
